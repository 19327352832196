export const ExampleNFT = `
[
  {
    "url" : "https://image...",
    "name": "Cool NFT 1",
    "description": "Very cool NFT, one of a kind!",
    "attributes": {
        "rarity": "2",
        "color": "blue"
    }
  },
  {
    "url" : "https://image...",
    "name": "Cool NFT 2",
    "description": "Very cool NFT, one of a kind!",
    "attributes": {
        "rarity": "7",
        "color": "purple"
    }
  }
]
`;
