import React, { useState, useEffect } from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getLaunchpad } from "utils/api";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "components/utils/TitleProvider";
import LaunchpadImageAndMint from "components/layouts/launchpad/details/LaunchpadImageAndMint";
import LaunchpadInfo from "components/layouts/launchpad/details/LaunchpadInfo";
import LaunchpadRoadmap from "components/layouts/launchpad/details/LaunchpadRoadmap";
import LaunchpadTeam from "components/layouts/launchpad/details/LaunchpadTeam";
import LaunchpadFAQ from "components/layouts/launchpad/details/LaunchpadFAQ";
import { buyLaunchpadNFT, getUserObjects, getSaleData } from "web3/sui";
import ToastPopup from "components/utils/ToastPopup";
import styled from "styled-components";
import LoadingButton from "components/button/LoadingButton";
import Countdown from "react-countdown";

const RefreshTimerText = styled.span`
  color: var(--primary-color9);
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MintpadTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const LaunchpadDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { setTitle } = useTitle();
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const [launchpad, setLaunchpad] = useState(false);
  const [inventories, setInventories] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [buyLoading, setBuyLoading] = useState(false);
  const [saleDataLoading, setSaleDataLoading] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(1);

  const [saleIndex, setSaleIndex] = useState(null);

  useEffect(() => {
    const firstPublicIndex = launchpad?.sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted != true
    );
    const firstPrivateIndex = launchpad?.sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted == true
    );
    const defaultIndex = firstPublicIndex > -1 ? firstPublicIndex : firstPrivateIndex;
    setSaleIndex(Math.max(defaultIndex, 0));
  }, [launchpad.sales]);

  const handleBuyNFT = async (numberToMint) => {
    let tokens = false;
    if (launchpad.sales[saleIndex]?.whitelisted) {
      tokens = tickets[saleIndex];
    }
    setBuyLoading(true);
    buyLaunchpadNFT(launchpad, saleIndex, tokens, numberToMint)
      .then((result) => {
        if (result.status === "success") {
          ToastPopup(`${numberToMint} item(s) successfully minted!`);
          inventories[saleIndex].current = inventories[saleIndex].current - numberToMint;
          setInventories(inventories);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastPopup(e.message, "error");
      })
      .finally(() => {
        setBuyLoading(false);
      });
  };
  const { roadmap, team, faq, ...launchpadInfo } = launchpad;
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchSaleData(inventories);
  }, [launchpad, saleIndex]);

  const resetTimer = () => {
    setRefreshTimer(Date.now() + 9000);
    setSaleDataLoading(false);
  };

  const fetchSaleData = (invs) => {
    setSaleDataLoading(true);
    getSaleData(launchpad, invs, saleIndex, true)
      .then((res) => {
        setInventories(res);
      })
      .catch((e) => {
        console.log("error", e);
      });
    setTimeout(() => resetTimer(), 500);
  };

  const RefreshButton = ({ loading, refreshHandler }) => {
    return (
      <LoadingButton
        loading={loading}
        className="refresh-button small-refresh"
        onClick={refreshHandler}
      >
        <i className="fas fa-redo-alt" />
      </LoadingButton>
    );
  };

  const AutoRefreshTimer = () => {
    const renderer = ({ seconds, completed }) => {
      if (saleDataLoading) return <RefreshTimerText>...</RefreshTimerText>;
      if (completed) {
        return <RefreshTimerText>...</RefreshTimerText>;
      } else {
        return <RefreshTimerText>{seconds}s</RefreshTimerText>;
      }
    };

    return (
      <Countdown
        date={refreshTimer}
        renderer={renderer}
        onComplete={() => fetchSaleData([])}
      />
    );
  };

  const getTickets = async (listing_id) => {
    // if there are any whitelists
    if (launchpad.sales.find((sale) => sale.whitelisted)) {
      let page = await getUserObjects({
        filter: {
          StructType: `${settings.packages.v1_launchpad}::market_whitelist::Certificate`,
        },
      });
      let data = page.data.filter((ticket) => ticket.data.listing_id == listing_id);
      while (page.hasNextPage && page.nextCursor && data.length < 50) {
        page = await getUserObjects({
          filter: {
            StructType: `${settings.packages.v1_launchpad}::market_whitelist::Certificate`,
          },
          cursor: page.nextCursor,
        });
        data = data.concat(
          page.data.filter((ticket) => ticket.data.listing_id == listing_id)
        );
      }
      launchpad.sales.forEach((sale, index) => {
        if (sale.whitelisted) {
          let saleTickets = data.filter(
            (ticket) => ticket.data?.venue_id == sale.venue_id
          );
          if (tickets[index]) {
            tickets[index] = tickets[index].concat(saleTickets);
          } else {
            tickets[index] = saleTickets;
          }
        }
      });
      setTickets(tickets);
    }
  };

  useEffect(() => {
    if (
      launchpad.object_id &&
      settings.ready &&
      user.account_address &&
      tickets.length == 0
    ) {
      getTickets(launchpad.object_id);
    }
  }, [launchpad, user, settings]);

  const renderAutoRefreshTimer = () => (
    <div className="meta-item">
      <RefreshWrapper>
        <AutoRefreshTimer />
        <RefreshButton
          loading={saleDataLoading}
          refreshHandler={() => fetchSaleData([])}
        />
      </RefreshWrapper>
    </div>
  );

  const tabPanels = [
    <LaunchpadInfo
      data={launchpadInfo}
      saleIndex={saleIndex}
      inventories={inventories}
      setSaleIndex={setSaleIndex}
      tickets={tickets}
      renderAutoRefreshTimer={renderAutoRefreshTimer}
    />,
    <LaunchpadRoadmap data={roadmap} />,
    <LaunchpadTeam data={team} />,
    <LaunchpadFAQ data={faq} />,
  ];

  const ActiveTab = ({ tabIndex }) => {
    return tabPanels[tabIndex];
  };

  const onLoad = async () => {
    if (params.id) {
      try {
        const res = await getLaunchpad(params.id);
        const { launchpad } = res.data;

        setLaunchpad(launchpad);
        setTitle(launchpad.launchpad_collection.name + " - Mintpad");
      } catch (e) {
        console.log(e);
        navigate("/mintpad", "replace");
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div>
      <Header />
      <PageHeader title={`Mints`}>
        <p>
          {user.role_id == 3 && (
            <Link to={`/edit-mintpad/${launchpad?._id}`}>Edit Mintpad</Link>
          )}
        </p>
      </PageHeader>
      <div className="tf-section launchpad-section">
        <div className="themesflat-container">
          <MintpadTabsWrapper className="row">
            <div className="flat-tabs themesflat-tabs launchpad-tabs fullWidth">
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList style={{ marginBottom: "3rem" }}>
                  <Tab>Info</Tab>
                  <Tab>Roadmap</Tab>
                  <Tab>Team</Tab>
                  <Tab>FAQ</Tab>
                </TabList>
                <div className="flex justify-content-between launchpad-tab">
                  <LaunchpadImageAndMint
                    launchpad={launchpad}
                    inventories={inventories}
                    saleIndex={saleIndex}
                    tickets={tickets}
                    buyLoading={buyLoading}
                    handleBuyNFT={handleBuyNFT}
                    renderAutoRefreshTimer={renderAutoRefreshTimer}
                  />
                  {launchpad ? (
                    <ActiveTab tabIndex={tabIndex} />
                  ) : (
                    <LoadingSpinner size="xxlarge" centered />
                  )}
                </div>
              </Tabs>
            </div>
          </MintpadTabsWrapper>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LaunchpadDetails;
