import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { Field, FieldArray, reduxForm, SubmissionError } from "redux-form";
import "react-tabs/style/react-tabs.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addOriginByeSupport,
  addKioskSupport,
  // createLaunchpad,
  // getLaunchpadsForCollection,
  getMyCollection,
  searchCollections,
  updateCollection,
  uploadCollectionImage,
  updateCollectionVersion,
} from "utils/api";
import {
  renderForm,
  renderFormV2,
  renderSearchFields,
  renderSetTagsV2,
} from "utils/form";
import { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FileInput } from "components/layouts/FileUpload";
import { useEffect } from "react";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { initFormVals } from "redux/state/initialValues";
import ToastPopup from "components/utils/ToastPopup";
import RoyaltiesButtonGroup from "components/layouts/RoyaltiesButtonGroup";
import LoadingButton from "components/button/LoadingButton";
import {
  addToAllowlist,
  collectionType,
  enableOriginByte,
  enableKiosk,
  isInAllowlist,
  upgrade,
  normalizeSuiAddress,
  createBurnRule,
  getTransferPolicies,
  getObjectsInfo,
} from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import { Table } from "react-bootstrap";
import { ethos } from "ethos-connect";

const formName = "edit-collection";

const validate = (values /*, dispatch */) => {
  const errors = {};

  const nameList = [];
  errors.fields = [];
  if (values.fields?.length > 1) {
    values.fields.forEach((element, index) => {
      if (nameList.includes(element.name)) {
        errors.fields[index] = { name: "Duplicate field name", values: [] };
      } else {
        nameList.push(element.name);
      }
      if (values.fields[index].values?.length > 1) {
        const subNameList = [];
        values.fields[index].values.forEach((subElement, subIndex) => {
          if (!errors.fields[index]) {
            errors.fields[index] = { values: [] };
          }
          if (subNameList.includes(subElement)) {
            errors.fields[index].values[subIndex] = "Duplicate value";
          } else {
            subNameList.push(subElement);
          }
        });
      }
    });
  }
  return errors;
};

const EditCollection = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting, error, initialValues, initialized } = props;
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const form = useSelector((state) => state.form[formName]);
  const values = form?.values;
  const [loadingCollection, setLoadingCollection] = useState(true);
  const [collection, setCollection] = useState(false);
  const [hasAuction, setHasAuction] = useState(true);
  const [hasLending, setHasLending] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const wallet = ethos.useWallet();

  const { id } = params;
  if (!id) navigate("/explore");

  useEffect(() => {
    getMyCollection(id).then((res) => {
      dispatch(initFormVals(formName, res.data));
      setCollection(res.data);
      setLoadingCollection(false);
    });
    return () => {
      dispatch(initFormVals(formName));
    };
  }, []);

  useEffect(() => {
    if (collection?.ob_enabled && collection.publisher_object_id) {
      if (settings.market?.auction_allowlist && !collection.features?.auction) {
        isInAllowlist(settings.market.auction_allowlist, collectionType(collection)).then(
          (res) => {
            if (res) {
              updateCollection(id, {
                features: {
                  ...collection.features,
                  ...{ auction: settings.market.auction_allowlist },
                },
              });
            }
            setHasAuction(res);
          }
        );
      } else if (collection.features?.auction) {
        setHasAuction(true);
      }
      if (settings.lending?.ob_allowlist && !collection.features?.lending) {
        isInAllowlist(settings.lending.ob_allowlist, collectionType(collection)).then(
          (res) => {
            if (res) {
              updateCollection(id, {
                features: {
                  ...collection.features,
                  ...{ lending: settings.lending.ob_allowlist },
                },
              });
            }
            setHasLending(res);
          }
        );
      } else if (collection.features?.lending) {
        setHasLending(true);
      }
    }
  }, [collection?.ob_enabled, settings]);

  const upgradeContract = async () => {
    setButtonLoading(true);
    try {
      let package_id = normalizeSuiAddress(collection.object_id);
      let upgradeInfo = wallet.wallet.contents.objects.find(
        (a) =>
          a.type.includes(`0x2::package::UpgradeCap`) &&
          normalizeSuiAddress(a.fields.package) == package_id
      );
      await upgrade(upgradeInfo);
      await updateCollectionVersion(collection._id);
    } catch (e) {
      console.log(e);
      setButtonLoading(false);
    }
  };

  const enableOriginByteSupport = async () => {
    let tx = await enableOriginByte(collection);
    if (tx.status === "success") {
      await sleep();
      await tryAgain(
        () =>
          addOriginByeSupport(id, {
            txDigest: tx.effects.transactionDigest,
          }),
        5,
        8000
      );
      setCollection({ ...collection, ...{ ob_enabled: true } });
    } else {
      ToastPopup("An error occurred enabling OriginByte Support.", "error");
    }
  };

  const enableKioskSupport = async () => {
    let tx = await enableKiosk(collection);
    if (tx.status === "success") {
      await sleep();
      await tryAgain(
        () =>
          addKioskSupport(id, {
            txDigest: tx.effects.transactionDigest,
          }),
        5,
        8000
      );
      setCollection({ ...collection, ...{ kiosk_enabled: true } });
    } else {
      ToastPopup("An error occurred enabling Kiosk Support.", "error");
    }
  };

  const enableAuctionSupport = async () => {
    let res = await addToAllowlist(
      settings.market.auction_allowlist,
      collectionType(collection),
      collection.publisher_object_id
    );
    if (res.status === "success") {
      setHasAuction(true);
      updateCollection(id, {
        features: {
          ...collection.features,
          ...{ auction: settings.market.auction_allowlist },
        },
      });
    }
  };

  const enableLendingSupport = async () => {
    let res = await addToAllowlist(
      settings.lending.ob_allowlist,
      collectionType(collection),
      collection.publisher_object_id
    );
    if (res.status === "success") {
      setHasLending(true);
      updateCollection(id, {
        features: {
          ...collection.features,
          ...{ lending: settings.lending.ob_allowlist },
        },
      });
    }
  };

  const enableBurningSupport = async () => {
    // TODO: check if burn rule exists?
    let policy = false;
    if (collection.kiosk_enabled) {
      const policies = await getTransferPolicies(collection.full_type);
      policy = policies.find((policy) => policy.rules.find((a) => a.includes("burn")));
    } else {
      // todo: find OB policies
    }

    let burn = true;
    if (!policy) {
      let res = await createBurnRule(collection);
      if (res.status === "success") {
        policy = true;
      }
      if (collection.ob_enabled) {
        const created = res.effects.created.map((item) => item.reference.objectId);
        let known = await getObjectsInfo(created, { showType: true, showContent: true });
        burn = known.find((a) => a.type.includes("::request::Policy<"))?.id;
      }
    }

    if (policy) {
      await updateCollection(id, {
        features: {
          ...collection.features,
          ...{ burn },
        },
      });
      setCollection({
        ...collection,
        ...{ features: { ...collection.features, ...{ burn: true } } },
      });
    }
  };

  const handleGoToCollection = () => {
    navigate(`/collection-details/${collection?._id}`);
  };

  const handleGoToBags = () => {
    navigate(`/edit-bags/${collection?._id}`);
  };

  const submit = async (values) => {
    const nameTaken = await searchCollections({ name: values.name }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken && nameTaken._id !== id) {
      throw new SubmissionError({
        name: "Name taken",
        _error: "A collection with that name already exists",
      });
    }

    const updatedCollection = await updateCollection(id, values);
    if (updatedCollection.data) {
      const token = updatedCollection.data.accessToken;
      const images = {};
      if (values.featured_img) {
        await uploadCollectionImage(token, id, values.featured_img, "featured")
          .then((res) => (images.featured_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Collection Banner.", "error");
          });
      }
      if (values.logo_img) {
        await uploadCollectionImage(token, id, values.logo_img, "logo")
          .then((res) => (images.logo_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Collection Logo.", "error");
          });
      }
      if (images) await updateCollection(id, images);
      // TODO: show "success" popup?
      ToastPopup("Collection successfully updated.", "success");
    }
  };

  if (!collection || !form) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <div className="flat-tabs tab-list-item">
                  <button onClick={handleGoToCollection}>View Collection</button>
                  <button onClick={handleGoToBags}>Edit Bags</button>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Keepsake Contract Version</th>
                        <th>{collection.contract_version || 1}</th>
                        <th>
                          {(collection.contract_version || 1) <
                            settings.nft_contract.version && (
                            <button onClick={upgradeContract}>Upgrade Contract</button>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th>Standard:</th>
                        <th
                          colSpan={
                            !collection.ob_enabled && !collection.kiosk_enabled ? 1 : 2
                          }
                        >
                          {collection.ob_enabled && "OriginByte"}
                          {collection.kiosk_enabled && "Mysten Kiosk"}
                          {!collection.kiosk_enabled &&
                            !collection.ob_enabled &&
                            "Mysten Kiosk"}
                        </th>
                        {!collection.ob_enabled && !collection.kiosk_enabled && (
                          <th>
                            <button onClick={enableOriginByteSupport}>
                              Enable OriginByte Support
                            </button>
                            <button onClick={enableKioskSupport}>
                              Enable Kiosk Support
                            </button>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {collection.ob_enabled && (
                        <>
                          <tr>
                            <td>Auctions</td>
                            <td>{hasAuction ? "Enabled" : "Not Enabled"}</td>
                            <td>
                              {!hasAuction && (
                                <button onClick={enableAuctionSupport}>
                                  Enable Auction Support
                                </button>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Lending</td>
                            <td>{hasLending ? "Enabled" : "Not Enabled"}</td>
                            <td>
                              {!hasLending && (
                                <button onClick={enableLendingSupport}>
                                  Enable Lending Support
                                </button>
                              )}
                            </td>
                          </tr>
                          {collection.keepsake && (
                            <tr>
                              <td>Burning</td>
                              <td>
                                {collection.features?.burn ? "Enabled" : "Not Enabled"}
                              </td>
                              <td>
                                {!collection.features?.burn && (
                                  <button onClick={enableBurningSupport}>
                                    Enable Burning Support
                                  </button>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      {collection.kiosk_enabled && (
                        <>
                          {collection.keepsake && (
                            <tr>
                              <td>Burning</td>
                              <td>
                                {collection.features?.burn ? "Enabled" : "Not Enabled"}
                              </td>
                              <td>
                                {!collection.features?.burn && (
                                  <button onClick={enableBurningSupport}>
                                    Enable Burning Support
                                  </button>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      {!collection.ob_enabled && !collection.kiosk_enabled && (
                        <>
                          <tr>
                            <td>Auctions</td>
                            <td colSpan={2}>Enabled</td>
                          </tr>
                          <tr>
                            <td>Lending</td>
                            <td colSpan={2}>Enabled</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>

                  <form onSubmit={handleSubmit(submit)}>
                    {renderForm(form, "logo_img", {
                      type: "file",
                      className: "hideInput",
                      labelClassName: "square",
                      component: FileInput,
                      title: "Collection Logo",
                      featuredImage: values?.logo_img || collection.logo_image,
                      accept: "image/*",
                    })}
                    {renderForm(form, "featured_img", {
                      type: "file",
                      className: "hideInput",
                      component: FileInput,
                      title: "Collection Banner",
                      featuredImage: values?.featured_img || collection.featured_image,
                      accept: "image/*",
                    })}
                    <Field
                      name="name"
                      title="Collection Name"
                      type="text"
                      placeholder="Collection Name"
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="description"
                      type="textarea"
                      placeholder='e.g. "This is my collection!"'
                      component={renderFormV2}
                      required
                    />
                    {/* <Field
                      type="textarea"
                      name="project_details"
                      props={{
                        placeholder:
                          "Tell us about your project, it may help speed up the approval process.",
                      }}
                      required
                      component={renderFormV2}
                    /> */}
                    <Field
                      name="royalties"
                      label="Royalties"
                      component={RoyaltiesButtonGroup}
                    />
                    <h2 className="mg-bt-24 mg-t-40">Socials</h2>
                    <Field
                      name="twitter"
                      type="text"
                      placeholder="https://twitter.com/"
                      component={renderFormV2}
                    />
                    <Field
                      name="discord"
                      type="text"
                      placeholder="https://discord.gg/"
                      component={renderFormV2}
                    />
                    <Field
                      name="instagram"
                      type="text"
                      placeholder="https://instagram.com/"
                      component={renderFormV2}
                    />
                    <Field name="whitepaper" type="text" component={renderFormV2} />
                    <Field name="website" type="text" component={renderFormV2} />
                    <FieldArray
                      name="tags"
                      title="Tags"
                      component={renderSetTagsV2}
                      formName={formName}
                    />
                    <FieldArray
                      name="fields"
                      title="Metadata Information"
                      component={renderSearchFields}
                      rerenderOnEveryChange
                    />
                    <LoadingButton
                      type="submit"
                      loading={loadingCollection}
                      disabled={pristine || submitting || loadingCollection}
                    >
                      Update Collection
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(EditCollection));
