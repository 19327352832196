import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { reduxForm } from "redux-form";
import {
  buyNftListing,
  createOBKiosk,
  getObjectByType,
  getObjectsOwnedByObject,
  getProvider,
  getTxObjects,
} from "web3/sui";
import { getCollection, updateListing } from "utils/api";
import { ipfsConvert, mystToSui } from "utils/formats";
import ToastPopup from "components/utils/ToastPopup";
import RequireAmountButton from "components/button/RequireAmountButton";
import { getObjectInfo } from "web3/sui";
import { useEffect } from "react";
import { sleep } from "utils/time";
import LoadingButton from "components/button/LoadingButton";
import AddFundsTransak from "components/AddFundsTransak";
import { getKiosks } from "redux/state/sui";
import Royalties from "../collections/Royalties";
import EnableAlerts from "components/EnableAlerts";
import { fetchKiosk } from "@mysten/kiosk";

const formName = "buyModal";

const BuyModal = ({ item, onHide, onBuy, setModalShow, beforeSetListing }) => {
  let dispatch = useDispatch();
  const ob_kiosks = useSelector((state) => state.sui.ob_kiosks);
  const kiosks = useSelector((state) => state.sui.kiosks);
  let user = useSelector((state) => state.user);
  const [total, setTotal] = useState(0);
  const [buyLoading, setBuyLoading] = useState(false);
  const [nftCollection, setNftCollection] = useState({});

  const alertList = [
    { title: "Auction Alerts", fieldname: "auction-alerts-toggle" },
    { title: "Additional Alerts", fieldname: "additional-alerts-toggle" },
  ];

  useEffect(() => {
    let totalPrice = item.sale_price;
    if (item.seller_kiosk) {
      if (nftCollection.ob_enabled) {
        nftCollection.ob_royalties?.forEach((royalty) => {
          totalPrice += Math.ceil(Math.ceil(royalty.amount * item.sale_price) / 10000);
        });
      } else {
        totalPrice += Math.ceil(
          Math.ceil(nftCollection.royaltyFee * item.sale_price) / 10000
        );
      }
    } else {
      nftCollection.royalties?.forEach((royalty) => {
        totalPrice += Math.ceil(Math.ceil(royalty.amount * item.sale_price) / 10000);
      });
    }
    setTotal(Math.ceil(totalPrice * 10000) / 10000);
  }, [nftCollection]);

  const onLoad = async () => {
    if (item && !buyLoading) {
      let nft_collection = item.nft_collection;

      if (typeof item.nft_collection !== "object") {
        setBuyLoading(true);
        const collectionData = await getCollection(item.nft_collection);
        nft_collection = collectionData.data.collection;
        setBuyLoading(false);
      }
      setNftCollection(nft_collection);

      let itemExistsRes = true;

      if (item.seller_kiosk && item.listing_object_id.includes("::")) {
        let tx = false;
        if (nft_collection.kiosk_enabled) {
          tx = buyNftListing({ ...item, nft_collection }, item.sale_price, kiosks, {
            dry_run: true,
          });
        } else {
          tx = buyNftListing(
            { ...item, nft_collection },
            item.sale_price,
            ob_kiosks?.[0]?.id,
            { dry_run: true }
          );
        }
        const res = await tx;
        itemExistsRes = !res.error?.includes('Identifier("orderbook")');
      } else {
        itemExistsRes = await getObjectInfo(item.listing_object_id);
      }

      if (itemExistsRes) {
        if (item.sale_type == "auction") {
          const currentPrice = parseInt(itemExistsRes.data.bid);
          if (currentPrice > item.sale_price) {
            await updateListing(item._id);
            beforeSetListing({ ...item, ...{ sale_price: currentPrice } });
            if (setModalShow) {
              setModalShow({ ...item, ...{ sale_price: currentPrice } });
            }
          }
        }
      } else {
        ToastPopup("Item has already been purchased.", "error");
        if (beforeSetListing) {
          beforeSetListing({ ...item, ...{ active: false } });
        }
        updateListing(item._id);
        setBuyLoading(false);
        onHide();
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  if (!item) return null;

  const salePrice = parseInt(item.sale_price);
  const buyText = "Buy";
  const disabled = buyLoading || !item.active;

  const handleOnClick = async () => {
    try {
      setBuyLoading(true);
      const itemExistsRes = item.seller_kiosk
        ? true
        : await getObjectInfo(item.listing_object_id);
      // check if listing exists on blockchain, then if it doesn't, request listing update.
      if (itemExistsRes) {
        try {
          let tx = false;
          if (nftCollection.kiosk_enabled) {
            tx = buyNftListing(
              { ...item, nft_collection: nftCollection },
              item.sale_price,
              kiosks
            );
          } else {
            tx = buyNftListing(
              { ...item, nft_collection: nftCollection },
              item.sale_price,
              ob_kiosks?.[0]?.id
            );
          }
          const res = await tx;
          await sleep();
          if (res?.status === "success") {
            await updateListing(item._id);
            ToastPopup("Item bought successfully!");
            if (
              (nftCollection.ob_enabled && !ob_kiosks?.[0]?.id) ||
              (nftCollection.kiosk_enabled && !kiosks?.[0]?.id)
            ) {
              await sleep(8000);
              dispatch(getKiosks(user.account_address));
              return;
            }
            if (onHide) {
              onHide();
            }
            if (onBuy) {
              onBuy(item);
            }
          } else {
            ToastPopup("Something went wrong; Transaction failed.", "error");
          }
          setBuyLoading(false);
        } catch (e) {
          console.log(e);
          ToastPopup("Something went wrong; Transaction failed.", "error");
          setBuyLoading(false);
        }
      } else if (item.active) {
        ToastPopup("Item has already been purchased.", "error");
        if (beforeSetListing) {
          beforeSetListing({ ...item, ...{ active: false } });
        }
        updateListing(item._id);
        setBuyLoading(false);
        onHide();
      } else {
        ToastPopup("Item has already been purchased.", "error");
        if (beforeSetListing) {
          beforeSetListing({ ...item, ...{ active: false } });
        }
        setBuyLoading(false);
        onHide();
        return;
      }
    } catch (e) {
      console.log(e);
      ToastPopup(
        "An error occurred while trying to buy this NFT, please try again later.",
        "error"
      );
      setBuyLoading(false);
    }
  };

  return (
    <Modal size="sm" show={!!item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-10 pd-30">
        <h3>Buy {item.nft?.name}</h3>
        <div className="flex justify-content-center">
          <img src={ipfsConvert(item.nft.image)} style={{ width: "50%" }} />
        </div>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p>Current Price</p>
          <p className="text-right price color-popup">{mystToSui(salePrice)} SUI</p>
        </div>

        {/*        <div className="d-flex justify-content-between">
          <p> Fees ({100 * itemFees}%):</p>
          <p className="text-right price color-popup"> {mystToSui(feePrice)} SUI </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Royalties (10%):</p>
          <p className="text-right price color-popup"> {itemRoyalties} SUI </p>
        </div>
        */}
        <Royalties price={mystToSui(salePrice)} collection={nftCollection} item={item} />
        <AddFundsTransak />
        <EnableAlerts alertList={alertList} />
        <RequireAmountButton
          amount={Math.max(total, salePrice)}
          text={buyText}
          className="btn btn-primary"
          disabled={disabled}
        >
          <LoadingButton
            onClick={() => handleOnClick()}
            disabled={disabled}
            loading={buyLoading}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {buyText}
          </LoadingButton>
        </RequireAmountButton>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: formName })(BuyModal);
