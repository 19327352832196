import React, { useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import Companion from "assets/images/rewards/reward_companion1.png";
import Companion2 from "assets/images/rewards/reward_companion2.png";
import Companion3 from "assets/images/rewards/reward_companion3.png";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { NFTSelectBox } from "components/layouts/modal/NFTSelectModal";

const StatsContainer = styled.div`
  border-radius: 2rem;
  width: 100%;
  gap: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StatsInner = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 1rem;
  padding: 2rem;
  gap: 2rem;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

const rewardsList = [
  { id: 1, level: "1", fees: "Base Fees", companion: Companion, locked: true },
  { id: 2, level: "2", fees: "~5% lower Fees", companion: Companion, locked: true },
  { id: 3, level: "3", fees: "~15% lower Fees", companion: Companion2, locked: true },
  { id: 4, level: "4", fees: "~25% lower Fees", companion: Companion2, locked: true },
  { id: 5, level: "5", fees: "~45% lower Fees", companion: Companion3, locked: true },
];

const RewardsProgress = (props) => {
  return (
    <div className="rewards-bar-wrapper">
      {rewardsList.map((reward) => (
        <RewardsInfoBox reward={reward} key={reward.id} />
      ))}
      <div
        className="progress progress-bar-vertical"
        style={{ height: "80%", position: "absolute" }}
      >
        <div
          className="progress-bar bg-mint"
          role="progressbar"
          style={{ height: "0%" }}
        />
      </div>
    </div>
  );
};

const LockedTier = () => {
  return (
    <>
      <ReactTooltip
        id="lockedTier"
        place="top"
        type="dark"
        effect="solid"
        className="rewards-tooltip locked"
        delayShow={150}
      >
        <span>
          This tier is currently locked. You need 5000 points to unlock this tier
        </span>
      </ReactTooltip>
      <a data-tip data-for="lockedTier" className="rewards-level-progress-icon locked">
        <i className="fas fa-lock" />
      </a>
    </>
  );
};

const UnlockedTier = () => {
  return (
    <>
      <ReactTooltip
        id="unlockedTier"
        place="top"
        type="dark"
        effect="solid"
        className="rewards-tooltip locked"
        delayShow={150}
      >
        <span>You have unlocked this tier!</span>
      </ReactTooltip>
      <a
        data-tip
        data-for="unlockedTier"
        className="rewards-level-progress-icon unlocked"
      >
        <i className="fas fa-star" />
      </a>
    </>
  );
};

const RewardsInfoBox = ({ reward }) => {
  const { level, fees, companion, locked } = reward;
  return (
    <div className="rewards-level-interval">
      <hr className={`rewards-hr ${locked ? "locked" : "unlocked"}`} />
      <div className={`rewards-level-image ${locked ? "locked" : "unlocked"}`}>
        <img src={companion} />
      </div>
      {locked ? <LockedTier /> : <UnlockedTier />}
      <div className={`rewards-level-textbox ${locked ? "locked" : "unlocked"}`}>
        <div className="rewards-level-textbox-inner">
          <h4>Level {level}</h4>
          <ul>
            <li>Level {level} Rewards</li>
            <li>{fees}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const RewardsStatsProgressBar = () => {
  return (
    <ProgressBarWrapper>
      <div
        className="rewards-level-progress-icon unlocked"
        style={{ position: "absolute", left: 0 }}
      >
        <i className="fas fa-star" />
      </div>

      <div
        className="progress progress-bar-striped"
        style={{ position: "relative", width: "95%", height: "100%" }}
      >
        <div
          className="progress-bar bg-mint"
          role="progressbar"
          style={{ width: "0%", height: "100%" }}
        />
      </div>

      <div
        className="rewards-level-progress-icon locked"
        style={{ position: "absolute", right: 0 }}
      >
        <i className="fas fa-lock" />
      </div>
    </ProgressBarWrapper>
  );
};

const RewardsStats = () => {
  const [eggNft, setEggNft] = useState(null);
  const [incubatorNft, setIncubatorNft] = useState(null);

  return (
    <StatsContainer>
      <StatsInner>
        <h2>LEVEL 1</h2>
        <RewardsStatsProgressBar />
        <h6>0/1000</h6>
        <div className="inline-field" style={{ gap: "4rem" }}>
          <NFTSelectBox
            title="Select an Egg"
            nft={eggNft}
            setNft={setEggNft}
            structType={`0x6a4e6879a684d535b214ea60aeeafffbb3fd9ab876017d3d0756d4550544d4cd::keepsake_nft::KEEPSAKE`}
            iconClass="fal fa-egg"
          />
          <NFTSelectBox
            title="Select an Incubator"
            nft={incubatorNft}
            setNft={setIncubatorNft}
            structType={`0x7f839f7bd593513114e905e5a0937e159e62a1bb6f2c451603f839963f74a192::ethos_squad::EthosSquad<0x7f839f7bd593513114e905e5a0937e159e62a1bb6f2c451603f839963f74a192::ethos_squad::EthosSquad13>`}
            iconClass="fal fa-dryer"
          />
        </div>
      </StatsInner>
      <StatsInner>
        <h2>PLACEHOLDER</h2>
      </StatsInner>
    </StatsContainer>
  );
};

const LinkedWallets = () => {
  return (
    <div
      className="flex flex-column"
      style={{
        border: "1px solid #707070",
        borderRadius: "1rem",
        padding: "1rem",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <div
        className="flex justify-content-between fullWidth"
        style={{ alignItems: "center" }}
      >
        <h5>Linked Wallets</h5>
        <button
          style={{
            height: "24px",
            borderRadius: "0.5rem",
            lineHeight: 1,
            padding: "1px 6px",
          }}
        >
          Connect Wallet
        </button>
      </div>

      <input
        type="text"
        disabled
        style={{ borderRadius: "30px", height: "24px", backgroundColor: "#222" }}
        placeholder="Wallet Address"
      />
      <input
        type="text"
        disabled
        style={{ borderRadius: "30px", height: "24px", backgroundColor: "#222" }}
        placeholder="Wallet Address"
      />
      <input
        type="text"
        disabled
        style={{ borderRadius: "30px", height: "24px", backgroundColor: "#222" }}
        placeholder="Wallet Address"
      />
    </div>
  );
};

const Rewards = () => {
  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div
          className="themesflat-container flex justify-content-center"
          style={{ gap: "2rem" }}
        >
          <div className="flex fullWidth gap-1">
            <RewardsProgress />
            <RewardsStats />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Rewards;
