import { Fragment, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Field } from "redux-form";
import { renderFormV2 } from "utils/form";
import RoyaltiesButtonGroup from "components/layouts/RoyaltiesButtonGroup";
import { useSelector } from "react-redux";

const OpenStandardPanel = () => {
  let user = useSelector((state) => state.user);
  return (
    <>
      <Field name="royalties" label="Royalties" component={RoyaltiesButtonGroup} />
      <Field
        name="publisher_object_id"
        title={`Publisher object`}
        placeholder="e.g. 0x7bdf78643....8d"
        type="text"
        component={renderFormV2}
      />
      {user.role_id == 3 && (
        <Field
          name="owner"
          title="Owner Address"
          type="text"
          placeholder="e.g. 0x8c7b25f7..."
          component={renderFormV2}
          required
        />
      )}
    </>
  );
};

const MystenStandardPanel = () => {
  let user = useSelector((state) => state.user);
  return (
    <>
      <Field name="royalties" label="Royalties" component={RoyaltiesButtonGroup} />
      {user.role_id == 3 && (
        <Field
          name="owner"
          title="Owner Address"
          type="text"
          placeholder="e.g. 0x8c7b25f7..."
          component={renderFormV2}
          required
        />
      )}
    </>
  );
};

const OriginByteStandardPanel = () => {
  return (
    <>
      <Field
        name="bps_royalty_strategy"
        title="BpsRoyaltyStrategy"
        placeholder="e.g. 0x7bdf78643....8d"
        type="text"
        component={renderFormV2}
      />
      <Field
        name="allowlist"
        placeholder="e.g. 0x7bdf78643....8d"
        type="text"
        component={renderFormV2}
      />
      <Field
        name="orderbook"
        placeholder="e.g. 0x7bdf78643....8d"
        type="text"
        component={renderFormV2}
      />
    </>
  );
};

const NFTStandardTabs = ({ selectedTab, setSelectedTab }) => {
  const dataTabs = [
    {
      title: "Unsure",
      value: "open",
      panel: <OpenStandardPanel />,
    },
    {
      title: "Mysten Kiosk",
      value: "mysten",
      panel: <MystenStandardPanel />,
    },
    {
      title: "OriginByte Kiosk",
      value: "origin_byte",
      panel: <OriginByteStandardPanel />,
    },
  ];

  const tabSelectHandler = (index) => {
    setSelectedTab(dataTabs[index].value);
  };

  return (
    <Fragment>
      <h4 className="title-list-item">NFT Standard</h4>
      <div className="form-tabs">
        <Tabs onSelect={tabSelectHandler}>
          <TabList>
            {dataTabs.map(({ value, title, disabled }) => (
              <Tab key={value} disabled={disabled}>
                {title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ value, panel }) => (
            <TabPanel key={value}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </Fragment>
  );
};

export default NFTStandardTabs;
