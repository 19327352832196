import { useNavigate } from "react-router-dom";
import RequireAmountButton from "components/button/RequireAmountButton";
import { mystToSui } from "utils/formats";
import LoadingButton from "components/button/LoadingButton";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { escapeRegExp } from "utils/form";
import TimeButton from "components/button/TimeButton";

const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #4d3d5c;
  background: #120c18;
  border-radius: var(--border-radius-md);
  outline: none;
  box-sizing: border-box;
  width: 130px !important;
  height: 52px !important;
`;

const QuantitySelectorButton = styled.button`
  padding: 1rem;
  margin: 0;
  border: none;
  background: none;
  width: 40px;
  :hover,
  :focus {
    background: transparent;
    border: none;
  }
  :disabled {
    background: transparent;
    border: none;
    cursor: not-allowed !important;
  }
`;

const QuantitySelectorInput = styled.input`
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

const FlexRow = styled.div`
  display: flex;
`;

const MintButtons = ({
  launchpad,
  sale,
  tickets,
  inventory,
  handleBuyNFT,
  buyLoading,
  isSoldOut,
  renderAutoRefreshTimer,
}) => {
  // const navigate = useNavigate();
  let [disabled, setDisabled] = useState(false);
  let [buyText, setBuyText] = useState("Buy");
  let [max, setMax] = useState(1);
  const now = new Date();
  //!inventory?.is_live;
  useEffect(() => {
    if (inventory?.current == 0 || isSoldOut) {
      if (inventory?.max == undefined) {
        setBuyText("Buy");
      } else {
        setBuyText("Sold Out");
        setDisabled(true);
      }
      //} else if (!launchpad?.launchpad_collection?.active) {
      // setBuyText("Collection is undergoing approval");
      // setDisabled(true);
    } else if (sale?.end_date && now.toISOString() > sale?.end_date) {
      setBuyText("Ended");
      setDisabled(true);
    } else if (sale?.limit && inventory?.user_limit > sale?.limit) {
      setBuyText(`Limit (${sale?.limit}) reached`);
      setDisabled(true);
    } else if (inventory?.is_whitelisted) {
      if (tickets?.length == 0) {
        setBuyText("No mint tickets available");
        setDisabled(true);
      } else {
        setBuyText(`Buy (${tickets?.length} tickets remaining)`);
        setDisabled(false);
      }
    } else {
      setBuyText("Buy");
      setDisabled(false);
    }
    setMax(Math.min(sale?.limit, inventory?.current));
  }, [inventory, sale, tickets]);

  const [numberToMint, setNumberToMint] = useState(1);

  // const handleGoToCollection = () => {
  //   navigate(`/collection-details/${collectionID}`);
  // };

  const parseOnBlur = (value) => {
    if (!value) {
      setNumberToMint(1);
    }
  };

  const parseNumberToMint = (value) => {
    const inputRegex = RegExp(`^[1-9]\\d*$`);
    if (!value) setNumberToMint("");
    if (inputRegex.test(escapeRegExp(value.toString()))) {
      if (parseInt(value) > max) {
        setNumberToMint(parseInt(max));
      } else {
        setNumberToMint(parseInt(value));
      }
    }
  };

  return (
    <div className="mint-buttons-container">
      <div className="flex justify-content-between mb-3">
        <p style={{ fontSize: "22px" }}>
          Price: <b>{`${mystToSui(sale?.price)} SUI`}</b>
        </p>
        <p style={{ fontSize: "22px" }}>
          Per Wallet:{" "}
          <b>
            {inventory?.user_limit !== undefined ? `${inventory.user_limit}/` : ""}
            {sale?.limit < 1000000 ? sale?.limit : "UNLIMITED"}
          </b>
        </p>
      </div>
      <div className="flex justify-content-between mb-3 mint-buttons-wrapper">
        <TimeButton
          start_date={inventory?.start_date}
          end_date={inventory?.end_date}
          className="mint-button"
        >
          <RequireAmountButton
            amount={sale?.price * numberToMint}
            text="Buy"
            className="mint-button"
            disabled={disabled}
          >
            <LoadingButton
              onClick={() => handleBuyNFT(numberToMint)}
              disabled={disabled}
              loading={buyLoading}
              className="mint-button"
              data-toggle="modal"
              data-target="#popup_bid_success"
              data-dismiss="modal"
              aria-label="Close"
            >
              {buyText}
            </LoadingButton>
          </RequireAmountButton>
        </TimeButton>
        <FlexRow>
          {renderAutoRefreshTimer()}
          <QuantitySelector>
            <QuantitySelectorButton
              type="button"
              onClick={() => setNumberToMint(numberToMint - 1)}
              disabled={numberToMint <= 1 || disabled}
            >
              -
            </QuantitySelectorButton>
            <QuantitySelectorInput
              disabled={disabled}
              value={numberToMint}
              onBlur={() => {
                parseOnBlur(numberToMint);
              }}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseNumberToMint(event.target.value);
                }
              }}
            />
            <QuantitySelectorButton
              type="button"
              onClick={() => setNumberToMint(numberToMint + 1)}
              disabled={numberToMint >= max || disabled}
            >
              +
            </QuantitySelectorButton>
          </QuantitySelector>
        </FlexRow>
      </div>
    </div>
  );
};

export default MintButtons;
