import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import LazyLoadImage from "components/layouts/LazyLoadImage";
import EllipsisDropdown from "components/layouts/EllipsisDropdown";

const GameCard = ({ item: game, link, onClick, owned }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { _id, name, creator, banner_image, logo_image } = game;
  const to = `${link || "/games"}/${_id}`;
  const linkProps = onClick
    ? {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
        to,
      }
    : { to };
  const isCreator = useMemo(() => {
    return user && creator ? creator._id === user._id : false;
  }, [user, creator]);

  const handleEditGame = () => {
    navigate(`/edit-game/${_id}`);
  };

  const optionsDropdownList = [
    {
      label: "Edit Game",
      onClick: handleEditGame,
      icon: <i className="fas fa-pencil" />,
    },
  ];

  return (
    <div className="sc-card-collection collection-card">
      {/* <WishlistButton className="wishlist" game={game} /> */}
      <Link {...linkProps}>
        <LazyLoadImage
          height="200px"
          className="collection-img-featured"
          src={banner_image}
          key={`featured-${_id}`}
        />
      </Link>
      <div className="card-bottom" style={{ height: "120px", padding: "1.5rem" }}>
        <div className="author game-details">
          <div className="flex flex-column align-items-center fullWidth">
            <h5 className="mb-3 capitalize">
              <Link {...linkProps}>{name ?? "Unnamed Game"}</Link>
            </h5>
            <div className="divider" />
            {isCreator && owned && (
              <EllipsisDropdown list={optionsDropdownList} item={game} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
