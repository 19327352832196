import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { searchAuctions } from "redux/state/search";
import { useDispatch, useSelector } from "react-redux";
import NFTCard from "./NFTCard";
import BidModal from "./modal/BidModal";
import { updateListing } from "utils/api";
import UnlistModal from "./modal/UnlistModal";
import { ListGrid } from "utils/styled";

const LiveAuction = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.search.auctions.results);
  const user = useSelector((state) => state.user);
  const [buyLoading, setBuyLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [visible, setVisible] = useState(12);
  const isOwner = user._id === modalShow.seller;

  const refreshAuctions = () =>
    dispatch(searchAuctions({ sortParams: { sale_price: -1 } }));

  useEffect(() => {
    refreshAuctions();
  }, []);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 6);
  };

  const onBid = (item) => {
    updateListing(item._id);
  };

  const handleHideModal = () => {
    setModalShow(false);
  };

  return (
    <section className="tf-section live-auctions">
      <div className="themesflat-container">
        <div className="heading-live-auctions">
          <h2 className="tf-title pb-22 text-left">Live Auctions</h2>
          <Link to="/explore/auctions">
            <div className="border-button">
              <span className="exp">VIEW ALL</span>
            </div>
          </Link>
        </div>
        <ListGrid>
          {data.slice(0, visible).map((item) => (
            <NFTCard key={item._id} item={item} setModalShow={setModalShow} />
          ))}
        </ListGrid>
        {visible < data.length && (
          <div className="col-md-12 wrap-inner load-more text-center">
            <Link
              to="#"
              id="load-more"
              className="sc-button loadmore fl-button pri-3"
              onClick={showMoreItems}
            >
              <span>Load More</span>
            </Link>
          </div>
        )}
      </div>
      {isOwner ? (
        <UnlistModal
          item={modalShow}
          onHide={() => handleHideModal()}
          customFetch={refreshAuctions}
        />
      ) : (
        <BidModal
          onBid={onBid}
          item={modalShow}
          setModalShow={setModalShow}
          beforeSetListing={refreshAuctions}
          onHide={() => {
            handleHideModal();
          }}
        />
      )}
    </section>
  );
};

export default LiveAuction;
