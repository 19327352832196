import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";

const Contact = () => {
  return (
    <div>
      <Header />
      <PageHeader />
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-12">
                  Drop Us A Message
                </h2>
                <h5 className="sub-title ct style-1 pad-0-15">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
                  obcaecati dignissimos quae quo ad iste ipsum officiis deleniti
                  asperiores sit.
                </h5>
                <div className="form-inner">
                  <form id="contactform" noValidate="novalidate" className="form-submit">
                    <input
                      id="name"
                      name="name"
                      tabIndex="1"
                      aria-required="true"
                      required
                      type="text"
                      placeholder="Your Full Name"
                    />
                    <input
                      id="email"
                      name="email"
                      tabIndex="2"
                      aria-required="true"
                      required
                      type="email"
                      placeholder="Your Email Address"
                    />
                    <div className="row-form style-2">
                      <select id="subject">
                        <option value="1">Select subject</option>
                        <option value="2">Select subject</option>
                        <option value="3">Select subject</option>
                      </select>
                      <i className="icon-fl-down"></i>
                    </div>
                    <textarea
                      id="message"
                      name="message"
                      tabIndex="3"
                      aria-required="true"
                      required
                      placeholder="Message"
                    ></textarea>
                    <button className="submit">Send message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
