import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, history } from "./redux";
import ScrollToTop from "./ScrollToTop";
import { Chain, EthosConnectProvider } from "ethos-connect";
import { connect, getKiosks, setLoading } from "redux/state/sui";
import { useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import throttle from "lodash.throttle";
import "react-toastify/dist/ReactToastify.css";
import { IS_PROD, IS_TESTNET } from "utils/environments";
import { fetchSettings } from "redux/state/settings";
import Routing from "pages/Routing";
import { TitleProvider } from "components/utils/TitleProvider";
import { SkeletonTheme } from "react-loading-skeleton";
import useAppDataFetcher from "utils/useAppDataFetcher";
import { getBearerToken } from "utils/api";
import FullScreenLoading from "components/utils/FullScreenLoading";
import { network } from "web3/sui";
import EthosSync from "components/EthosSync";
import { BannerProvider } from "components/utils/BannerProvider";

const ethosConfiguration = {
  apiKey: "2a8c4e45-4f4a-49ce-88a4-08ed9983463b",
  walletAppUrl: IS_PROD
    ? "https://ethoswallet.xyz"
    : "https://sui-wallet-staging.onrender.com",
  network: "https://keepsake.mainnet.sui.rpcpool.com/",
  chain: Chain.SUI_MAINNET, // IS_TESTNET ? Chain.SUI_TESTNET : Chain.SUI_DEVNET,
};

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sui = useSelector((state) => state.sui);
  const settings = useSelector((state) => state.settings);
  useAppDataFetcher();

  const attemptConnect = useCallback((provider, signer) => {
    dispatch(connect(provider, signer));
    dispatch(setLoading(false));
  });

  useEffect(() => {
    if (settings?.object_ids?.ob_kiosk && sui.account) {
      dispatch(getKiosks(sui.account));
    }
  }, [settings?.object_ids?.ob_kiosk, sui.account]);

  const onWalletConnected = (provider, signer) => {
    if (!signer || sui.connected || sui.connecting) return false;
    attemptConnect(provider, signer);
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  return (
    <EthosConnectProvider
      ethosConfiguration={ethosConfiguration}
      onWalletConnected={({ provider, signer }) => onWalletConnected(provider, signer)}
    >
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer theme="colored" />
        <TitleProvider>
          <BannerProvider>
            <SkeletonTheme
              baseColor="var(--primary-color11)"
              highlightColor="var(--primary-color11d)"
            >
              <Router location={history.location} navigator={history}>
                <ScrollToTop />
                {!user._id && getBearerToken() ? null : <Routing />}
              </Router>
              <EthosSync />
              <FullScreenLoading />
            </SkeletonTheme>
          </BannerProvider>
        </TitleProvider>
      </PersistGate>
    </EthosConnectProvider>
  );
}

export default App;
