import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { searchLoading } from "redux/state/search";
import { paramsToObject } from "utils/formats";
import { useDidUpdateEffect, usePrevious } from "utils/hooks";

const SearchContext = React.createContext();

export function useRefreshSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const dispatch = useDispatch();
  const [searchFunction, setSearchFunction] = useState(null);
  const [timer, setTimer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortParams, setSortParams] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [customParams, setCustomParams] = useState({});
  const prevPage = usePrevious(currentPage, false);
  const prevSortParams = usePrevious(sortParams, false);
  const prevSearchParams = usePrevious(searchParams, false);
  const params = useParams();

  const searchAndSortParams = {
    ...params,
    ...sortParams,
    ...paramsToObject(searchParams),
    page: currentPage,
    ...customParams,
  };

  useEffect(() => {
    refreshSearch();
  }, [searchFunction]);

  //usePrevious is needed here to prevent useEffect maximum depth when switch from one explore page to another
  useDidUpdateEffect(() => {
    if (
      JSON.stringify(sortParams) !== JSON.stringify(prevSortParams) ||
      currentPage !== prevPage ||
      searchParams !== prevSearchParams
    ) {
      refreshSearch();
    }
  }, [sortParams, currentPage, searchParams]);

  const refreshSearch = () => {
    if (timer) {
      clearTimeout(timer);
    }
    if (typeof searchFunction !== "function") return;
    dispatch(searchLoading(true));

    setTimer(
      setTimeout(async () => {
        await dispatch(searchFunction(searchAndSortParams))
          .then(() => {
            dispatch(searchLoading(false));
          })
          .catch((error) => console.log(error));
      }, 300)
    );

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <SearchContext.Provider
      value={{
        refreshSearch,
        setSearchFunction,
        setCurrentPage,
        setSortParams,
        setSearchParams,
        setCustomParams,
        currentPage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
