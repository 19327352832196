import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Form, reduxForm, change } from "redux-form";
import { Accordion } from "react-bootstrap-accordion";
import { renderFormV2, renderSearchFields, renderSetTagsV2 } from "utils/form";
import { collectionType, getEvents, setupKeepsakeRoyalties } from "web3/sui";
import { addKeepsakeSupport } from "utils/api";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { initFormVals } from "redux/state/initialValues";
import { useEffect } from "react";

export const formName = "mintpad-review";

const ReviewLaunchpadModal = ({ onSubmit, launchpad, onHide, ...props }) => {
  if (!launchpad) {
    return false;
  }

  const { launchpad_collection: collection } = launchpad;
  const dispatch = useDispatch();
  const { pristine, submitting, handleSubmit, reset, error } = props;
  const settings = useSelector((state) => state.settings);
  const form = useSelector((state) => state.form[formName]);
  const statuses = ["awaiting review", "under review", "denied", "approved"];

  useEffect(() => {
    dispatch(initFormVals(formName, launchpad));
  }, []);

  useEffect(() => {
    dispatch(initFormVals(formName, launchpad));
  }, []);

  const enable_royalties = async () => {
    let events = await getEvents({
      MoveEventType: `${
        settings.packages.keepsake
      }::keepsake_royalties::StrategyCreated<${collectionType(collection)}>`,
    });

    let tx = false;
    if (events.data.length > 0) {
      tx = events.data[0].id.txDigest;
    } else {
      let setupTx = await setupKeepsakeRoyalties(collection);
      tx = setupTx.effects.transactionDigest;
      await sleep();
    }
    let res = await addKeepsakeSupport(collection._id, tx);
    if (res.status == "200") {
      ToastPopup("Royalties added");
    } else {
      ToastPopup("Failed to add royalties", "error");
    }
  };

  return (
    <Modal
      show={collection}
      onHide={() => {
        reset();
        onHide();
      }}
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      {/*If collection is mysten, there is no transfer_policy, make one*/}

      <div className="modal-body space-y-20 pd-40">
        <div className="tf-list-item tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <div className="flat-form flat-form-wide">
                  <div className="flat-tabs tab-list-item">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Field
                        type="file"
                        name="featured_img"
                        title="Banner Image"
                        className="hideInput"
                        labelClassName="banner"
                        imgClassName="inheritHeight"
                        featuredImage={
                          form?.values?.featured_img || collection.featured_image
                        }
                        component={renderFormV2}
                        accept=".png,.jpg,.jpeg,.gif"
                        containername="required"
                      />
                      <Field
                        type="file"
                        name="logo_img"
                        className="hideInput"
                        labelClassName="square"
                        featuredImage={form?.values?.logo_img || collection.logo_image}
                        component={renderFormV2}
                        accept=".png,.jpg,.jpeg,.gif"
                      />
                      <Field
                        type="select"
                        name="review_status"
                        component={renderFormV2}
                        onChange={(_, value) => {
                          dispatch(
                            change(formName, "active", statuses.slice(3).includes(value))
                          );
                        }}
                      >
                        {statuses.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </Field>
                      {/* <Field
                        type="checkbox"
                        name="active"
                        checked={form?.values?.active}
                        onClick={() =>
                          dispatch(change(formName, "active", !form?.values?.active))
                        }
                        component={renderFormV2}
                      /> */}

                      <Field type="checkbox" name="active" component={renderFormV2} />
                      <Field type="checkbox" name="featured" component={renderFormV2} />
                      <Field
                        type="textarea"
                        name="review_notes"
                        component={renderFormV2}
                      />
                      <p className="error">{error}</p>
                      <button type="submit" disabled={pristine || submitting}>
                        Update Collection
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true })(ReviewLaunchpadModal));
