import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getMyCollections,
  myGame,
  searchGames,
  updateGame,
  uploadGameImage,
} from "utils/api";
import { renderFormV2 } from "utils/form";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { initFormVals } from "redux/state/initialValues";
import ToastPopup from "components/utils/ToastPopup";
import LoadingButton from "components/button/LoadingButton";
import MultiSelectAccordion from "components/layouts/MultiSelectAccordion";
import Tooltip from "components/utils/Tooltip";

const formName = "edit-game";

const validate = (values /*, dispatch */) => {
  const errors = {};
  return errors;
};

const EditGame = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting } = props;
  const user = useSelector((state) => state.user);
  const form = useSelector((state) => state.form[formName]);
  const [loadingGame, setLoadingGame] = useState(true);
  const [collections, setCollections] = useState([]);
  const [game, setGame] = useState(false);
  const [logoImage, setLogoImage] = useState(game.logo_image || null);
  const [bannerImage, setBannerImage] = useState(game.banner_image || null);
  const tags = useSelector((state) => state.settings?.tags);
  const genres = ["action", "adventure", "puzzle"];
  const platforms = ["desktop", "mobile", "web"];
  const playInfo = ["singleplayer", "multiplayer", "cooperative"];

  const collectionsData = {
    title: "Link Collections",
    name: "linked_collections",
    show: true,
    values: collections,
    Tooltip: () => (
      <Tooltip>
        <>
          Select all collections that are related to or interact with your game in some
          way. These collections will appear on the Game Details page and will be used to
          calculate game statistics such as total volume sold.
        </>
      </Tooltip>
    ),
  };

  const tagsData = {
    title: "Tags",
    name: "tags",
    show: true,
    values: tags,
  };

  const genresData = {
    title: "Genres",
    name: "genres",
    show: true,
    values: genres,
  };

  const platformsData = {
    title: "Platforms",
    name: "platforms",
    show: true,
    values: platforms,
  };

  const playInfoData = {
    title: "Play Info",
    name: "play_info",
    show: true,
    values: playInfo,
  };

  const { id } = params;
  if (!id) navigate("/explore");

  useEffect(() => {
    myGame(id).then((res) => {
      dispatch(initFormVals(formName, res.data));
      setGame(res.data);
      setLoadingGame(false);
      getMyCollections().then((req) => {
        setCollections(req.data.results);
      });
    });
    return () => {
      dispatch(initFormVals(formName));
    };
  }, []);

  const handleGoToGame = () => {
    navigate(`/games/${game?._id}`);
  };

  const submit = async (values) => {
    const nameTaken = await searchGames({ name: values.name }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken && nameTaken._id !== id) {
      throw new SubmissionError({
        name: "Name taken",
        _error: "A game with that name already exists",
      });
    }

    const updatedGame = await updateGame(id, values);
    if (updatedGame.data) {
      const token = updatedGame.data.accessToken;
      const images = {};
      if (values.banner_img) {
        await uploadGameImage(token, id, values.banner_img, "featured")
          .then((res) => (images.banner_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Game Banner.", "error");
          });
      }
      if (values.logo_img) {
        await uploadGameImage(token, id, values.logo_img, "logo")
          .then((res) => (images.logo_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Game Logo.", "error");
          });
      }
      if (images) await updateGame(id, images);
      // TODO: show "success" popup?
      ToastPopup("Game successfully updated.", "success");
    }
  };

  if (!game || !form) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <div className="flat-tabs tab-list-item">
                  <button onClick={handleGoToGame}>View Game</button>
                  <form onSubmit={handleSubmit(submit)}>
                    <Field
                      type="file"
                      name="banner_img"
                      title="Banner Image"
                      className="hideInput"
                      labelClassName="banner"
                      imgClassName="inheritHeight"
                      featuredImage={bannerImage}
                      component={renderFormV2}
                      containername="required"
                      accept=".png,.jpg,.jpeg,.gif,.mp4"
                      onChange={(e) => {
                        if (e) {
                          setBannerImage(e);
                        }
                      }}
                    />
                    <Field
                      type="file"
                      name="logo_img"
                      title="Logo Image"
                      className="hideInput"
                      labelClassName="square"
                      imgClassName="inheritHeight"
                      featuredImage={logoImage}
                      component={renderFormV2}
                      containername="required"
                      accept=".png,.jpg,.jpeg,.gif"
                      onChange={(e) => {
                        if (e) {
                          setLogoImage(e);
                        }
                      }}
                    />
                    <MultiSelectAccordion
                      item={collectionsData}
                      formName={formName}
                      image={true}
                      fullwidth={true}
                    />
                    <Field
                      name="media_url"
                      title="Featured Media URL"
                      type="text"
                      placeholder="This can be a Youtube Video URL, an mp4, or an image."
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="name"
                      title="Game Title"
                      type="text"
                      placeholder="e.g. Sui 2048"
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="description"
                      type="textarea"
                      placeholder='e.g. "This is my game!"'
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="developer"
                      type="text"
                      placeholder='e.g. "Bamboo Games"'
                      component={renderFormV2}
                      required
                    />
                    <MultiSelectAccordion item={platformsData} formName={formName} />
                    <MultiSelectAccordion item={genresData} formName={formName} />
                    <MultiSelectAccordion item={playInfoData} formName={formName} />
                    <Field
                      name="twitter"
                      type="text"
                      placeholder="https://twitter.com/"
                      component={renderFormV2}
                    />
                    <Field
                      name="discord"
                      type="text"
                      placeholder="https://discord.gg/"
                      component={renderFormV2}
                    />
                    <Field name="website" type="text" component={renderFormV2} />
                    <Field name="game_url" type="text" component={renderFormV2} />
                    <MultiSelectAccordion item={tagsData} formName={formName} />
                    <LoadingButton
                      type="submit"
                      loading={loadingGame}
                      disabled={pristine || submitting || loadingGame}
                    >
                      Update Game
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(EditGame));
