import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { getObjectsByStructType } from "redux/state/sui";
import { ipfsConvert } from "utils/formats";

const StatsInner = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 1rem;
  padding: 2rem;
  gap: 2rem;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const NFTContainerWrapper = styled(StatsInner)`
  padding: 0;
  overflow: hidden;
  width: ${({ width }) => (typeof width == "number" ? `${width}px` : width)};
  height: ${({ height }) => (typeof height == "number" ? `${height}px` : height)};
`;

const NFTContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  background: var(--bg-section3);
  transition: all 0.3s;
  position: relative;

  i {
    font-size: 196px;
    font-weight: 100;
    color: var(--primary-color7);
    transition: all 0.3s;
  }

  &:hover {
    background-color: var(--primary-color7);

    span {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    i {
      color: var(--bg-section3);
    }
  }
`;

const NFTSelectBoxTextBanner = styled.span`
  font-size: 28px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  z-index: 1;
  transition: 0.2s all;
`;

const NFTSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`;

const NFTOptionContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.3s all;
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.4);

  img,
  video {
    border-radius: 0px;
    height: 250px;
    width: 250px;
  }

  span {
    font-size: 28px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    z-index: 1;
    transition: 0.2s all;
  }

  ::after {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.2s all;
  }

  &:hover {
    ::after {
      box-shadow: inset 0px 0px 100px 40px rgba(0, 0, 0, 0.7);
    }

    span {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

export const NFTSelectBox = ({
  nft,
  setNft,
  title,
  titlePosition = "bottom",
  structType,
  iconClass,
  account_address,
  height = 250,
  width = 250,
}) => {
  const [nfts, setNfts] = useState([]);
  const [nftSelectModalShow, setNftSelectModalShow] = useState(false);
  const nftImage =
    ipfsConvert(nft?.display?.data?.image_url) ||
    ipfsConvert(nft?.display?.data?.thumb_url);

  useEffect(() => {
    getObjectsByStructType(structType, account_address).then((res) => {
      setNfts(res);
    });
  }, []);

  return (
    <div className="flex flex-column gap-1">
      {titlePosition == "top" && <h3>{title}</h3>}
      <NFTContainerWrapper height={height} width={width}>
        <NFTContainer onClick={() => setNftSelectModalShow(true)}>
          {nft && (
            <NFTSelectBoxTextBanner className="text-center">
              {nft.data?.name}
            </NFTSelectBoxTextBanner>
          )}
          {nft ? <img src={nftImage} /> : <i className={iconClass} />}
        </NFTContainer>
      </NFTContainerWrapper>
      {titlePosition == "bottom" && <h3>{title}</h3>}
      <NFTSelectModal
        itemsList={nfts}
        modalShow={nftSelectModalShow}
        setOption={setNft}
        onHide={() => setNftSelectModalShow(false)}
        title={title}
      />
    </div>
  );
};

const NFTSelectModal = ({ itemsList, modalShow, setOption, onHide, title }) => {
  if (!modalShow) {
    return false;
  }

  const NFTOption = ({ nft }) => {
    const nftImage =
      ipfsConvert(nft.display?.data?.image_url) ||
      ipfsConvert(nft.display?.data?.thumb_url);

    return (
      <NFTOptionContainer
        onClick={() => {
          setOption(nft);
          onHide();
        }}
      >
        <span className="text-center">{nft.data?.name}</span>
        {nftImage && nftImage?.split(".").pop() === "mp4" ? (
          <video style={{ zIndex: 1 }} autoPlay muted loop>
            <source src={nftImage} type="video/mp4" />
          </video>
        ) : (
          <img src={nftImage} />
        )}
      </NFTOptionContainer>
    );
  };
  return (
    <Modal show={modalShow} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h2>{title}</h2>
        <div className="list-item">
          <NFTSelectWrapper>
            {itemsList.map((nft) => (
              <NFTOption nft={nft} key={nft?.id} />
            ))}
          </NFTSelectWrapper>
        </div>
      </div>
    </Modal>
  );
};

export default NFTSelectModal;
