import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import LiveAuction from "components/layouts/LiveAuction";

const NoResult = () => {
  return (
    <div>
      <Header />
      <PageHeader />
      <section className="tf-no-result tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-10">
                Sorry, We Couldn’t Find That Page.
              </h2>
            </div>
          </div>
        </div>
      </section>
      <LiveAuction />
      <Footer />
    </div>
  );
};
export default NoResult;
