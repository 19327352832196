import { Link, useParams, useSearchParams } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { Field, reduxForm, change } from "redux-form";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { issueWhitelist } from "../../web3/sui";
import { renderFormV2 } from "utils/form";
import { getLaunchpad } from "utils/api";
import { useState } from "react";
import { ipfsConvert, downloadJSONFile } from "utils/formats";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { tryAgain } from "utils/performance";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isArray } from "lodash";
import { ExampleWhitelist } from "assets/fake-data/ExampleWhitelist";

const formName = "batch-create-nft";

const validate = (/* values , dispatch */) => {
  const errors = {};
  // if (!values.collection) {
  //   errors.collection = "Please select a collection";
  // }
  return errors;
};

const BatchWhitelist = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { handleSubmit, pristine, submitting } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const values = form?.values || {};
  const registeredFields = form?.registeredFields || {};
  const [launchpad, setLaunchpad] = useState(false);
  const [json, setJson] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchSize, setBatchSize] = useState(searchParams.get("batchSize") || 50);
  const [next, setNext] = useState(searchParams.get("start") || 0);

  useEffect(() => {
    const oldValues = Object.keys(values).filter(
      (key) => !Object.keys(registeredFields).includes(key)
    );
    oldValues.forEach((field) => {
      dispatch(change(formName, field, undefined));
    });
  }, [registeredFields]);

  const fetchLaunchpad = async () => {
    let data = await getLaunchpad(params.id);
    setLaunchpad(data.data.launchpad);
  };

  useEffect(() => {
    if (user._id) {
      fetchLaunchpad();
    }
  }, [user]);

  const onJsonLoad = (file) => {
    // do stuff with the file.
    let read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = function () {
      let cleanJson = JSON.parse(read.result);
      if (isArray(cleanJson)) {
        setJson(cleanJson);
      } else {
        let keys = Object.keys(cleanJson);
        if (keys.length == 1) {
          setJson(cleanJson[keys[0]]);
        }
      }
    };
  };

  const submit = async () => {
    mintBatch(0, batchSize);
  };

  const mintBatch = async (start, end) => {
    const txn = await issueWhitelist(launchpad, values.tier, json.slice(start, end));
    if (txn.status === "success") {
      await sleep(2000);
      setNext(start + batchSize);
      searchParams.set("start", start + batchSize);
      searchParams.set("batchSize", batchSize);
      setSearchParams(searchParams);
    } else {
      ToastPopup("An unknown error occured", "error");
    }
  };

  useEffect(() => {
    if (next > 0) {
      if (next > json.length) {
        ToastPopup("Tickets minted successfully!", "success");
      } else {
        mintBatch(next, next + batchSize);
      }
    }
  }, [next]);

  const handleCopy = () => {
    ToastPopup("Copied info to clipboard!", "success");
  };

  return (
    <div className="list-item">
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-12 mg-bt-18">
              <Link to={`/edit-mintpad/${params.id}`}>
                <button className="batch-mint-button">Back to mintpad</button>
              </Link>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-9 col-12">
              <h4 className="title-list-item">Batch Whitelist Progress</h4>
              <div className="sc-card-product">
                <div className="meta-info pt-5 pl-2">
                  <p>Up next: #{Math.min(next + 1, json.length + 1)}</p>
                  <p>
                    Minted: {Math.min(next, json.length)}/{json.length}
                  </p>
                </div>
                <div className="card-bottom"></div>
              </div>
              <h4>Example JSON:</h4>
              {/* mintType, tab*/}
              <pre className="pre-code-block">
                {ExampleWhitelist}
                <div
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    display: "flex",
                    gap: "1.5rem",
                    fontSize: "24px",
                  }}
                >
                  <CopyToClipboard text={ExampleWhitelist} onCopy={() => handleCopy()}>
                    <i className="fal fa-clone" />
                  </CopyToClipboard>
                  <i
                    className="far fa-arrow-to-bottom"
                    onClick={() => downloadJSONFile(ExampleWhitelist, "example-nfts")}
                  />
                </div>
              </pre>
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-12">
              <div className="form-list-item">
                <div className="flat-tabs tab-list-item">
                  <form onSubmit={handleSubmit(submit)}>
                    <Field
                      type="file"
                      name="json"
                      component={renderFormV2}
                      onChange={onJsonLoad}
                      className="hideInput"
                      containername="h-100"
                      labelClassName="h-100"
                      hint="JSON file"
                      hidename="true"
                      accept=".json"
                    />
                    {!!launchpad && (
                      <Field
                        type="select"
                        name="tier"
                        required={true}
                        component={renderFormV2}
                      >
                        <option key="placeholder" value="" hidden>
                          Select a tier
                        </option>
                        {launchpad.sales?.map(({ title, whitelisted }, index) => (
                          <option key={index} value={index}>
                            {title} {whitelisted && "(whitelisted)"}
                          </option>
                        ))}
                      </Field>
                    )}

                    <button
                      className="mt-md-4"
                      type="submit"
                      disabled={pristine || submitting}
                    >
                      Send Whitelist Tokens!
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default reduxForm({
  form: formName,
  validate,
})(BatchWhitelist);
