import React, { useState } from "react";
import { useSelector } from "react-redux";
import UnlistModal from "components/layouts/modal/UnlistModal";
import SearchBar from "components/layouts/SearchBar";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import LayoutButtons from "components/layouts/LayoutButtons";
import Sorting from "components/layouts/Sorting";
import RefreshButton from "components/layouts/RefreshButton";
import CardList from "components/layouts/explore/Lists/CardList";
import NFTSkeleton from "components/layouts/NFTSkeleton";
import NFTCard from "components/layouts/NFTCard";
import ListNFTSkeleton from "components/layouts/ListNFTSkeleton";
import ListNFTCard from "components/layouts/ListNFTCard";
import { sortAuctionsDropdown } from "utils/sortDropdowns";
import { useRefreshSearch } from "components/utils/SearchProvider";

const MyAuctionsPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const reduxState = useSelector((state) => state.search.auctions);
  const orderedHeaders = ["Name", "Current Price", "Favorites"];

  const handleHideModal = () => {
    setBuyLoading(false);
    setModalShow(false);
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortAuctionsDropdown} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={NFTSkeleton}
          CardComponent={NFTCard}
          ListSkeletonComponent={ListNFTSkeleton}
          ListCardComponent={ListNFTCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
          checkWon={true}
          buttonText="Edit Listing"
        />
      </PaginationWrapper>
      <UnlistModal item={modalShow} onHide={() => handleHideModal()} />
    </div>
  );
};

export default MyAuctionsPanel;
