import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyKiosksList from "components/layouts/profile/Lists/MyKiosksList";
import SearchBar from "components/layouts/SearchBar";
import SellModal from "components/layouts/modal/SellModal";
import LoadingButton from "components/button/LoadingButton";
import NFTDetailsModal from "components/layouts/modal/NFTDetailsModal";
import styled from "styled-components";
import { getOBKioskItems, getKiosks } from "redux/state/sui";
import { createOBKiosk } from "web3/sui";
import LoanModal from "components/layouts/modal/LoanModal";

const KioskButton = styled.button`
  height: 50px;
  border-radius: var(--border-radius-md);
  padding: 0.5rem 3rem;
  /* background-color: var(--primary-color11); */
`;

const KioskContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const MyOBKiosksPanel = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const sui = useSelector((state) => state.sui);
  const kiosks = sui.ob_kiosks || [];
  const [sellModalShow, setSellModalShow] = useState(false);
  const [rentModalShow, setLoanModalShow] = useState(false);
  const [kioskIndex, setKioskIndex] = useState(0);
  const [nftDetailsModalShow, setNftDetailsModalShow] = useState(false);
  const kiosk_contents = sui.kiosk_contents?.[kiosks[kioskIndex]?.id];
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (kiosk_contents?.hasNextPage) {
      setCount(`${kiosk_contents.data.length || 0}+`);
    } else {
      setCount(kiosk_contents?.data.length || 0);
    }
  }, [kioskIndex, kiosk_contents]);

  let handleCreate = () => {
    createOBKiosk().then((tx) => {
      if (tx.status == "success") {
        dispatch(getKiosks());
      }
    });
  };

  let loadMore = () => {
    dispatch(
      getOBKioskItems(
        kiosks[kioskIndex].id,
        kiosk_contents.nextCursor,
        kiosk_contents.exclusion
      )
    );
  };

  useEffect(() => {
    if (!loading) {
      if (kiosks[kioskIndex]?.id && !kiosk_contents) {
        dispatch(getOBKioskItems(kiosks[kioskIndex].id));
      }
    }
  }, [kiosks.length, kioskIndex, kiosk_contents]);

  return (
    <div>
      <KioskContainer>
        {kiosks?.map((_, index) => (
          <KioskButton
            selected={kioskIndex}
            key={index}
            onClick={() => setKioskIndex(index)}
          >
            Kiosk #{index + 1}
          </KioskButton>
        ))}
        {kiosks.length === 0 && <button onClick={handleCreate}>Create new kiosk</button>}
      </KioskContainer>
      <div className="option" style={{ gap: "2rem", marginBottom: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">{loading ? "..." : count} NFTs</h2>
          <SearchBar />
        </div>
      </div>
      <MyKiosksList
        inventory={kiosk_contents}
        kiosk={kiosks[kioskIndex]}
        loadMore={loadMore}
        loading={loading}
        setLoading={setLoading}
        setSellModalShow={setSellModalShow}
        setLoanModalShow={setLoanModalShow}
        setNftDetailsModalShow={setNftDetailsModalShow}
      />
      <SellModal
        kiosk={kiosks[kioskIndex]}
        item={sellModalShow}
        onHide={() => setSellModalShow(false)}
      />
      <LoanModal
        kiosk={kiosks[kioskIndex]}
        item={rentModalShow}
        onHide={() => setLoanModalShow(false)}
      />
      <NFTDetailsModal
        kiosk={kiosks[kioskIndex]}
        item={nftDetailsModalShow}
        onHide={() => setNftDetailsModalShow(false)}
      />
    </div>
  );
};

export default MyOBKiosksPanel;
