import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CollectionRanking from "./CollectionRanking";
import RankingSkeleton from "./RankingSkeleton";
import { getTopCollections } from "utils/api";
import TimespanButtonGroup from "./TimespanButtonGroup";

const TopCollections = () => {
  const [topCollections, setTopCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoad = async () => {
    setLoading(true);
    const res = await getTopCollections();
    let collectionsData = [];
    if (res.data.data.length < 15) {
      for (let index = res.data.data.length; index < 15; index++) {
        collectionsData.push({});
      }
    }
    collectionsData = [...res.data.data, ...collectionsData];
    setTopCollections(collectionsData);
    setTimeout(() => setLoading(false), 300);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <section className="tf-section top-collections">
      <div className="center-margin fullWidth">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions">
                <div className="inline-field pb-22">
                  <h2 className="tf-title text-left pb-0">Top Collections</h2>
                  <TimespanButtonGroup />
                </div>
                <Link to="/leaderboard">
                  <div className="border-button">
                    <span className="exp">VIEW ALL</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-12 fullWidth">
              <div className="top-collections-column">
                {loading
                  ? Array.from({ length: 9 }, (_, index) => {
                      return (
                        <div key={index} className="fullWidth">
                          <RankingSkeleton rank={index} />
                        </div>
                      );
                    })
                  : topCollections.slice(0, 9).map((item, index) => (
                      <div key={item._id || index} className="fullWidth">
                        <CollectionRanking item={item} rank={index} />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopCollections;
