import styled from "styled-components";
import MyProfileInventory from "components/layouts/MyProfileInventory";

const DisplayName = styled.h2`
  font-size: 38px;
  margin-right: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: 1rem;
`;

const ProfileInventory = () => {
  return (
    <ContentWrapper className={"col-box-83"}>
      <DisplayName>Inventory</DisplayName>
      <MyProfileInventory />
    </ContentWrapper>
  );
};

export default ProfileInventory;
