import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui.js/utils";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import {
  SuiAddress,
  SUI,
  createOBKiosk,
  getUserAddress,
  currentSettings,
  getTransferPolicies,
  getProvider,
} from "web3/sui";
import { addToTransaction } from "web3/txnbuilder";

import { suiToMyst } from "utils/formats";

export const listOB = (nft, collection, realPrice, userKiosk) => {
  let settings = currentSettings();
  let txns = [];
  const marketShare = Math.floor(
    Math.ceil(realPrice / 10_000) * settings.market.fee_bps
  ).toString();

  if (nft.kiosk) {
    txns.push({
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_ask_with_commission`,
      typeArguments: [nft.type, SUI],
      arguments: [
        collection.orderbook,
        nft.kiosk,
        realPrice.toString(),
        nft.id,
        settings.market.owner,
        marketShare,
      ],
      types: ["object", "object", "u64", "id", "address", "u64"],
    });
  } else {
    // first transfer it to a kiosk
    if (!userKiosk) {
      // create a kiosk, then deposit NFT
      txns.push(createOBKiosk(true));
      nft.kiosk = { txIndex: 0, index: 0 };
    } else {
      nft.kiosk = userKiosk;
    }

    txns.push({
      target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
      typeArguments: [nft.type],
      arguments: [nft.kiosk, nft.id],
      types: ["object", "object"],
    });

    txns.push({
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::create_ask_with_commission`,
      typeArguments: [nft.type, SUI],
      arguments: [
        collection.orderbook,
        nft.kiosk,
        realPrice.toString(),
        nft.id,
        settings.market.owner,
        marketShare,
      ],
      types: ["object", "object", "u64", "id", "address", "u64"],
    });
    if (!userKiosk) {
      txns.push({
        target: `${SuiAddress}::transfer::public_share_object`,
        typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
        arguments: [nft.kiosk],
        types: ["object"],
      });
    }
  }
  return txns;
};

export const buyOB = (listing, price, userKiosk) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();
  let royaltyCost = 0;
  let purchaseKiosk = userKiosk;

  listing.nft_collection.ob_royalties.forEach((royalty) => {
    royaltyCost += Math.ceil(Math.ceil(royalty.amount * price) / 10000);
  });

  let external_fee = Math.ceil(Math.ceil(50 * price) / 10000);
  if (listing.external) {
    royaltyCost += external_fee;
  }

  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [price, Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 1 }],
      types: ["object"],
    },
  ];

  let request_index = 2;

  if (!userKiosk) {
    // create a kiosk, then deposit NFT
    txns.push(createOBKiosk(true));
    purchaseKiosk = { txIndex: 2, index: 0 };
    request_index = 3;
  }

  txns = txns.concat([
    {
      target: `${settings.packages.ob_liquidity_layer_v1}::orderbook::buy_nft`,
      typeArguments: [listing.object_type, SUI],
      arguments: [
        listing.orderbook,
        listing.seller_kiosk,
        purchaseKiosk,
        listing.nft_object_id,
        price.toString(),
        { txIndex: 0, index: 0 },
      ],
      types: ["object", "object", "object", "id", "u64", "object"],
    },
    {
      target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
      typeArguments: [listing.object_type],
      arguments: [
        listing.nft_collection.ob_allowlist,
        { txIndex: request_index, index: 0 },
      ],
      types: ["object", "object"],
    },
  ]);
  listing.nft_collection.ob_royalties?.forEach((royalty) => {
    txns.push({
      target: `${royalty.package_id}::${
        royalty.type.split("::")[0]
      }::confirm_transfer_with_balance`,
      typeArguments: royalty.specifyType
        ? [listing.object_type, SUI]
        : [listing.object_type],
      arguments: [
        royalty.id,
        { txIndex: request_index, index: 0 },
        { txIndex: 1, index: 0 },
      ],
      types: ["object", "object", "object"],
    });
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [listing.object_type, SUI],
    arguments: [
      { txIndex: request_index, index: 0 },
      listing.nft_collection.ob_transfer_policy,
    ],
    types: ["object", "object"],
  });

  if (listing.external) {
    txns.push({
      target: `${SuiAddress}::coin::take`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 1, index: 0 }, external_fee.toString()],
      types: ["object", "u64"],
    });
    txns.push({
      type: "transferObjects",
      object: { txIndex: txns.length - 1, index: 0 },
      to: settings.market.owner,
    });
  }
  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });
  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });
  txns.push({
    type: "transferObjects",
    object: { txIndex: 0, index: 0 },
    to: userAddress,
  });

  if (!userKiosk) {
    txns.push({
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
      arguments: [{ txIndex: 2, index: 0 }],
      types: ["object"],
    });
  }
  return txns;
};

export const auctionOB = (
  marketInfo,
  nft,
  collection,
  min_price,
  min_bid_increment,
  starts,
  expires,
  userKiosk
) => {
  let settings = currentSettings();
  let txns = Array.of({
    type: "splitCoins",
    amounts: [marketInfo.data.collateral_fee],
  });
  if (!userKiosk) {
    // create a kiosk, then deposit NFT
    txns.push(createOBKiosk(true));
    nft.kiosk = { txIndex: 1, index: 0 };
  } else {
    nft.kiosk = userKiosk;
  }

  if (!nft.kiosk) {
    // first transfer it to a kiosk
    txns.push({
      target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
      typeArguments: [nft.type],
      arguments: [nft.kiosk, nft.id],
      types: ["object", "object"],
    });
  }

  txns.push({
    target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::auction`,
    typeArguments: [nft.type],
    arguments: [
      settings.market.ob_marketplace,
      collection.ob_transfer_policy,
      nft.id,
      min_price,
      min_bid_increment,
      starts,
      expires,
      { txIndex: 0, index: 0 },
      nft.kiosk,
    ],
    types: [
      "object",
      "object",
      "address",
      "number",
      "number",
      "number",
      "number",
      "object",
      "object",
    ],
  });
  return txns;
};

export const winOBAuction = (listing, userKiosk) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();
  let royaltyCost = 0;
  listing.nft_collection.ob_royalties.forEach((royalty) => {
    royaltyCost += Math.ceil(Math.ceil(royalty.amount * listing.sale_price) / 10000);
  });

  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
  ];
  txns.push({
    target: `${settings.packages.keepsake_ob}::keepsake_ob_marketplace::complete_auction`,
    typeArguments: [listing.object_type],
    arguments: [
      settings.market.ob_marketplace,
      listing.seller_kiosk,
      userKiosk,
      listing.nft_object_id,
      SUI_CLOCK_OBJECT_ID,
    ],
    types: ["object", "object", "object", "address", "object"],
  });
  if (listing.nft_collection.ob_royalties) {
    listing.nft_collection.ob_royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${
          royalty.type.split("::")[0]
        }::confirm_transfer_with_balance`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }
  txns.push({
    target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
    typeArguments: [listing.object_type],
    arguments: [settings.market.auction_allowlist, { txIndex: 2, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [listing.object_type, SUI],
    arguments: [{ txIndex: 2, index: 0 }, listing.nft_collection.ob_transfer_policy],
    types: ["object", "object"],
  });

  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });

  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });
};

export const lendOBNFT = (
  nft,
  collection,
  userKiosk,
  { price: price_per_hour, formattedStarts, formattedEnds, min_duration, max_duration }
) => {
  let settings = currentSettings();
  let tr_index = 0;
  let txns = [];

  // first transfer it to a kiosk
  if (!userKiosk && !nft.kiosk) {
    // create a kiosk, then deposit NFT
    txns.push(createOBKiosk(true));
    nft.kiosk = { txIndex: 0, index: 0 };
    txns.push({
      target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
      typeArguments: [nft.type],
      arguments: [nft.kiosk, nft.id],
      types: ["object", "object"],
    });
  } else {
    if (!nft.kiosk) {
      txns.push({
        target: `${settings.packages.ob_kiosk}::ob_kiosk::deposit`,
        typeArguments: [nft.type],
        arguments: [userKiosk, nft.id],
        types: ["object", "object"],
      });
    }
    nft.kiosk = userKiosk;
  }

  txns.push({
    target: `${settings.packages.keepsake_ob_lending}::keepsake_ob_lending::list`,
    arguments: [
      nft.id,
      nft.kiosk,
      settings.lending.ob_marketplace,
      suiToMyst(price_per_hour).toString(),
      min_duration.toString(),
      max_duration.toString(),
      formattedStarts,
      formattedEnds,
    ],
    typeArguments: [nft.type],
    types: ["id", "object", "object", "u64", "u64", "u64", "u64", "u64"],
  });
  tr_index = txns.length - 1;

  if (collection.ob_royalties) {
    collection.ob_royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${royalty.type.split("::")[0]}::confirm_transfer`,
        typeArguments: royalty.specifyType ? [nft.type, SUI] : [nft.type],
        arguments: [royalty.id, { txIndex: tr_index, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }

  txns.push({
    target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
    typeArguments: [nft.type],
    arguments: [settings.lending.ob_allowlist, { txIndex: tr_index, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [nft.type, SUI],
    arguments: [{ txIndex: tr_index, index: 0 }, collection.ob_transfer_policy],
    types: ["object", "object"],
  });

  return txns;
};

export const borrowOBNFT = (listing, current_kiosk, hours, kiosk) => {
  let settings = currentSettings();
  let price = parseInt((listing.ask_per_day * hours) / 24);

  let royaltyCost = 0;

  let last_kiosk = current_kiosk;
  if (listing.nft_collection.ob_royalties) {
    listing.nft_collection.ob_royalties.forEach((royalty) => {
      royaltyCost += Math.ceil(Math.ceil(royalty.amount * price) / 10000);
    });
  }

  let txns = [
    {
      type: "splitCoins",
      amounts: [Math.ceil(royaltyCost * 1.001).toString()],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
  ];

  if (last_kiosk == kiosk || last_kiosk == settings.lending.ob_kiosk) {
    // prevent errors if the same user rents the same nft again, or nft is in market kiosk
    last_kiosk = listing.seller_kiosk;
  }

  if (!kiosk) {
    txns.push(createOBKiosk(true));
    kiosk = { txIndex: txns.length - 1, index: 0 };
  }

  txns.push({
    target: `${settings.packages.keepsake_ob_lending}::keepsake_ob_lending::borrow`,
    typeArguments: [listing.nft_collection.full_type],
    arguments: [
      listing.nft_object_id,
      last_kiosk,
      settings.lending.ob_marketplace,
      { kind: "GasCoin" },
      hours.toString(),
      kiosk,
      SUI_CLOCK_OBJECT_ID,
    ],
    types: ["id", "object", "object", "object", "u64", "object", "object"],
  });

  let tr_index = txns.length - 1;

  if (listing.nft_collection.ob_royalties) {
    listing.nft_collection.ob_royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${
          royalty.type.split("::")[0]
        }::confirm_transfer_with_balance`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [
          royalty.id,
          { txIndex: tr_index, index: 0 },
          { txIndex: 1, index: 0 },
        ],
        types: ["object", "object", "object"],
      });
    });
  }

  txns.push({
    target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
    typeArguments: [listing.object_type],
    arguments: [settings.lending.ob_allowlist, { txIndex: tr_index, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [listing.object_type, SUI],
    arguments: [
      { txIndex: tr_index, index: 0 },
      listing.nft_collection.ob_transfer_policy,
    ],
    types: ["object", "object"],
  });

  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });
  txns.push({
    target: `${SuiAddress}::coin::join`,
    typeArguments: [SUI],
    arguments: [{ kind: "GasCoin" }, { txIndex: txns.length - 1, index: 0 }],
    types: ["object"],
  });
  return txns;
};

export const returnOBNFT = (listing, kiosk, existing_txns) => {
  let settings = currentSettings();
  const txns = existing_txns.slice();
  const tr_index = txns.length - 1;

  txns.push([
    {
      target: `${settings.packages.keepsake_ob_lending}::keepsake_ob_lending::relinquish`,
      typeArguments: [listing.nft_collection.full_type],
      arguments: [
        listing.nft_object_id,
        kiosk,
        settings.lending.ob_marketplace,
        SUI_CLOCK_OBJECT_ID,
      ],
      types: ["id", "object", "object", "object"],
    },
  ]);

  listing.nft_collection.ob_royalties.forEach((royalty) => {
    txns.push({
      target: `${royalty.package_id}::${royalty.type.split("::")[0]}::confirm_transfer`,
      typeArguments: royalty.specifyType
        ? [listing.object_type, SUI]
        : [listing.object_type],
      arguments: [royalty.id, { txIndex: tr_index, index: 0 }],
      types: ["object", "object", "object"],
    });
  });
  txns.push({
    target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
    typeArguments: [listing.object_type],
    arguments: [settings.lending.ob_allowlist, { txIndex: tr_index, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [listing.object_type, SUI],
    arguments: [
      { txIndex: tr_index, index: 0 },
      listing.nft_collection.ob_transfer_policy,
    ],
    types: ["object", "object"],
  });
  return txns;
};

export const finishLendingOBNFT = (listing, existing_txns) => {
  let settings = currentSettings();
  let txns = existing_txns.slice();
  txns.push({
    target: `${settings.packages.keepsake_ob_lending}::keepsake_ob_lending::complete_lending`,
    typeArguments: [listing.nft_collection.full_type],
    arguments: [
      listing.nft_object_id,
      listing.seller_kiosk,
      settings.lending.ob_marketplace,
    ],
    types: ["id", "object", "object"],
  });

  let complete_req_index = txns.length - 1;

  if (listing.nft_collection.ob_royalties) {
    listing.nft_collection.ob_royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${royalty.type.split("::")[0]}::confirm_transfer`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [royalty.id, { txIndex: complete_req_index, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }

  txns.push({
    target: `${settings.packages.nft_protocol}::transfer_allowlist::confirm_transfer`,
    typeArguments: [listing.object_type],
    arguments: [settings.lending.ob_allowlist, { txIndex: complete_req_index, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${settings.packages.ob_request}::transfer_request::confirm`,
    typeArguments: [listing.object_type, SUI],
    arguments: [
      { txIndex: complete_req_index, index: 0 },
      listing.nft_collection.ob_transfer_policy,
    ],
    types: ["object", "object"],
  });

  return txns;
};

export const burnOBNFT = async (nft, collection, kiosk) => {
  let settings = currentSettings();
  let userAddress = getUserAddress();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  // if it's in a kiosk, list it for 0 and buy it

  let [burn_request, tx_nft, withdraw_request] = addToTransaction(txb, {
    target: `${settings.packages.burn}::burn::withdraw_nft_signed_for_burning`,
    typeArguments: [nft.type],
    arguments: [kiosk.id, nft.id],
    types: ["object", "object"],
  });

  let [uid] = addToTransaction(txb, {
    target: `${collection.object_id}::${collection.module_name}::burn_and_return_uid`,
    arguments: [tx_nft],
    types: ["object"],
  });
  addToTransaction(txb, {
    target: `${settings.packages.burn}::burn::confirm_ob_burn`,
    typeArguments: [nft.type],
    arguments: [uid, burn_request, withdraw_request],
    types: ["object", "object"],
  });
  addToTransaction(txb, {
    target: `${settings.packages.ob_request}::withdraw_request::confirm`,
    typeArguments: [nft.type],
    arguments: [withdraw_request, collection.features.burn],
    types: ["object", "object"],
  });
  return txb;
};
