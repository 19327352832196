import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { getCreator } from "utils/api";
import ToastPopup from "components/utils/ToastPopup";
import { useTitle } from "components/utils/TitleProvider";
import ProfileUpper from "components/layouts/profile/ProfileUpper";
import ProfileBanner from "components/layouts/ProfileBanner";
import CreatorTabs from "components/layouts/CreatorTabs";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { SearchProvider } from "components/utils/SearchProvider";

const Creator = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { setTitle } = useTitle();
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    if (params.id) {
      getCreator(params.id)
        .then((res) => {
          if (!res.data.creator.display_name) {
            ToastPopup("Unable to find creator", "error");
            navigate("/");
          }
          setCreator(res.data.creator);
          setTitle(res.data.creator.display_name + " - Creator");
        })
        .catch(() => {
          ToastPopup("Unable to find creator", "error");
          navigate("/");
        });
    }
  }, []);

  if (!creator) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div className="my-profile">
      <Header />
      <ProfileBanner user={creator} />
      <section className="tf-section tf-my-items">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-12 my-items">
              <SearchProvider>
                <ProfileUpper user={creator} />
                <CreatorTabs creator={creator} />
              </SearchProvider>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Creator;
