import React from "react";
import { Link } from "react-router-dom";
import { mystToSui, sumFieldInArray } from "utils/formats";
import LaunchpadInfoCountdownCubes from "./LaunchpadInfoCountdownCubes";
import styled from "styled-components";

const MintpadTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

const MintpadInfoBoxesWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const MintpadUpperDetailsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

const MintOptionContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
  }
`;

const MintpadDescription = styled.p`
  @media screen and (max-width: 575px) {
    text-align: center;
  }
`;

const SocialButtons = ({ socials }) => {
  const { twitter, discord, instagram, website, whitepaper } = socials;
  if (Object.values(socials).every((el) => el === undefined)) return null;

  return (
    <div className="widget-social style-3">
      <ul className="flex" style={{ gap: "1rem" }}>
        {twitter && (
          <li>
            <a href={twitter} target="_blank" rel="noreferrer">
              <i className="fab fa-twitter" />
            </a>
          </li>
        )}
        {discord && (
          <li>
            <a href={discord} target="_blank" rel="noreferrer">
              <i className="fab fa-discord" />
            </a>
          </li>
        )}
        {instagram && (
          <li>
            <a href={instagram} target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
            </a>
          </li>
        )}
        {website && (
          <li>
            <a href={website} target="_blank" rel="noreferrer">
              <i className="far fa-globe" />
            </a>
          </li>
        )}
        {whitepaper && (
          <li>
            <a href={whitepaper} target="_blank" rel="noreferrer">
              <i className="far fa-book" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

const InfoBox = ({ text, textBold }) => {
  return (
    <div className="flex launchpad-infobox">
      <span style={{ fontSize: "16px" }}>
        {text}: <b>{textBold}</b>
      </span>
    </div>
  );
};

const MintOption = ({ item, index, inventory, saleIndex, setSaleIndex }) => {
  // TODO: check if sale object is live, use it to disable mint button inventory.is_live
  const price = mystToSui(item.price);
  const limit = item.limit >= 1000000 ? "UNLIMITED" : item.limit;
  const isActive = index === saleIndex;
  const isHidden = item.hidden;
  const isSoldOut = inventory?.current == 0;
  const isWhitelisted = inventory?.is_whitelisted ?? item?.whitelisted;

  const handleSelectMint = (index) => {
    setSaleIndex(index);
  };

  return (
    <div
      className={`flex flex-column launchpad-info-container${
        isActive ? " active-mint" : ""
      } ${isHidden && "invisible"}`}
      onClick={() => handleSelectMint(index)}
    >
      <MintOptionContentsWrapper>
        <h3>{item.title || "~"}</h3>
        <div className={`mint-option-pill ${!isWhitelisted && "mint-bg"}`}>
          <span className="mint-option-pill-text">
            {isWhitelisted ? "Whitelist" : "Public"}
          </span>
        </div>
      </MintOptionContentsWrapper>
      <MintOptionContentsWrapper>
        <LaunchpadInfoCountdownCubes
          startDate={item.start_date}
          endDate={item.end_date}
          isSoldOut={isSoldOut}
        />
        <div className="inline-field justify-content-end">
          <InfoBox text="Per Wallet" textBold={`${limit || "~"} `} />
          <InfoBox text="Price" textBold={`${price} SUI`} />
        </div>
      </MintOptionContentsWrapper>
    </div>
  );
};

const LaunchpadInfo = ({ data, saleIndex, inventories, setSaleIndex }) => {
  const { launchpad_collection, sales } = data;
  const sale = sales[saleIndex];
  const { _id, name, description, twitter, discord, instagram, website, whitepaper } =
    launchpad_collection;
  const socials = { twitter, discord, instagram, website, whitepaper };
  let visibleInventories = [];
  if (inventories && inventories.length > 0) {
    let uniqueTiers = [];
    visibleInventories = sales
      .map((sale, index) => {
        const isUnique = !uniqueTiers.includes(sale.object_id);
        uniqueTiers.push(sale.object_id);
        return isUnique && !sale.hidden && inventories[index];
      })
      .filter(Boolean);
  }
  const combinedTotalItems = sumFieldInArray(visibleInventories, "total");

  return (
    <div className="flex flex-column launchpad-details launchpad-info">
      <MintpadTitleWrapper>
        <h1 className="heading">{name}</h1>
        <Link to={`/collection-details/${_id}`} className="exp style2">
          VIEW COLLECTION
        </Link>
      </MintpadTitleWrapper>
      <MintpadUpperDetailsWrapper>
        <SocialButtons socials={socials} />
        <MintpadInfoBoxesWrapper>
          <InfoBox text="Items" textBold={`${combinedTotalItems || "~"}`} />
          <InfoBox text="Price" textBold={`${mystToSui(sale?.price) || "0"} ` + ` SUI`} />
        </MintpadInfoBoxesWrapper>
      </MintpadUpperDetailsWrapper>
      <MintpadDescription>{description}</MintpadDescription>
      {sales.map((item, index) => (
        <MintOption
          inventory={inventories?.[index]}
          key={index}
          item={item}
          index={index}
          saleIndex={saleIndex}
          setSaleIndex={setSaleIndex}
        />
      ))}
    </div>
  );
};

export default LaunchpadInfo;
