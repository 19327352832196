import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { initFormVals } from "redux/state/initialValues";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getLaunchpad, updateLaunchpad } from "utils/api";
import LoadingSpinner from "components/utils/LoadingSpinner";
import ToastPopup from "components/utils/ToastPopup";
import { formatDateForPicker, getLocalTimezone, mystToSui } from "utils/formats";
import { updateLaunchpadListings, getSaleData, sendProceeds } from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import Header from "components/header/Header";
import PageHeader from "components/layouts/PageHeader";
import Footer from "components/footer/Footer";
import EditLaunchpadListings from "./EditLaunchpadListings";
import LoadingButton from "components/button/LoadingButton";
import BatchCreateModal from "components/layouts/modal/BatchCreateModal";
import BatchWhitelistModal from "components/layouts/modal/BatchWhitelistModal";
import BatchTransferModal from "components/layouts/modal/BatchTransferModal";
import BatchWithdrawModal from "components/layouts/modal/BatchWithdrawModal";

const formName = "edit-launchpad-listings";

const validate = (values /*, dispatch */) => {
  const errors = {};

  if (values) {
    if (values.start_date >= values.end_date) {
      errors.start_date = "Start date cannot be after end date";
    }
  }
  return errors;
};

const EditLaunchpadListingsForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const [launchpad, setLaunchpad] = useState(false);
  const [inventories, setInventories] = useState([]);
  const [batchCreateModalShow, setBatchCreateModalShow] = useState(false);
  const [batchTransferModalShow, setBatchTransferModalShow] = useState(false);
  const [batchWithdrawModalShow, setBatchWithdrawModalShow] = useState(false);
  const [whitelistModalShow, setWhitelistModalShow] = useState(false);
  const { id } = params;
  if (!id) navigate("/mintpad");

  const fetchLaunchpad = () => {
    getLaunchpad(id).then((res) => {
      if (!res.data.launchpad.object_id) navigate("/mintpad");
      setLaunchpad(res.data.launchpad);
      const initVals = res.data.launchpad;

      getSaleData(res.data.launchpad, [])
        .then((res) => setInventories(res))
        .catch((e) => console.log("error", e));

      try {
        initVals.sales = initVals.sales.map((sale) => ({
          ...sale,
          ...{ price: mystToSui(sale.price) },
        }));
      } catch {}
      dispatch(initFormVals(formName, initVals));
    });
  };

  useEffect(() => {
    fetchLaunchpad();
    return () => {
      dispatch(initFormVals(formName));
    };
  }, []);

  const onSubmit = async (values) => {
    const updatedSalesTimes = values.sales.map((sale) => ({
      ...sale,
      start_date: new Date(sale.start_date).toISOString(),
      end_date: new Date(sale.end_date).toISOString(),
    }));
    let tx = await updateLaunchpadListings(launchpad, inventories, {
      ...values,
      sales: updatedSalesTimes,
    });
    if (tx?.effects) {
      await sleep();
      values.tx = tx.effects.transactionDigest;
    }

    const updatedLaunchpad = await tryAgain(
      () => updateLaunchpad(id, { ...values, sales: updatedSalesTimes }),
      5
    );
    if (updatedLaunchpad?.data) {
      fetchLaunchpad();
      ToastPopup("Mintpad listings successfully updated.", "success");
      // navigate(`/mintpad/${id}`);
    }
  };

  const onWithdraw = async () => {
    let tx = await sendProceeds(launchpad);
    if (tx.status === "success") {
      ToastPopup("Funds withdrawn successfully", "success");
    } else {
      ToastPopup("Transaction failed", "error");
    }
  };

  const handleHideModal = () => {
    if (!submitting) {
      setBatchCreateModalShow(false);
      setWhitelistModalShow(false);
      setBatchTransferModalShow(false);
      setBatchWithdrawModalShow(false);
    }
  };

  const onBatchCreate = () => {
    fetchLaunchpad();
    handleHideModal();
  };

  if (!launchpad || !form) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div>
      <Header />
      <PageHeader title={`Edit Mintpad Details`}></PageHeader>

      <div className="tf-list-item tf-section">
        {user.role_id === 3 && (
          <div className="text-center">
            <button className="button mb-5" onClick={() => onWithdraw()}>
              Withdraw proceeds
            </button>
          </div>
        )}
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <div className="flat-tabs tab-list-item">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-column align-items-center"
                  >
                    <EditLaunchpadListings
                      title="Listings"
                      launchpad={launchpad}
                      setBatchCreateModalShow={setBatchCreateModalShow}
                      setWhitelistModalShow={setWhitelistModalShow}
                      setBatchTransferModalShow={setBatchTransferModalShow}
                      setBatchWithdrawModalShow={setBatchWithdrawModalShow}
                      inventories={inventories}
                    />
                    <LoadingButton
                      type="submit"
                      loading={submitting}
                      disabled={pristine || submitting}
                      style={{ marginTop: "3rem" }}
                    >
                      Update Mintpad Listings
                    </LoadingButton>
                    <Link to={`/mintpad/${launchpad._id}`}>
                      <span className="fa fa-arrow-right"></span>View Mintpad
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BatchWhitelistModal
          onBatchCreate={() => onBatchCreate()}
          item={whitelistModalShow}
          setModalShow={setWhitelistModalShow}
          onHide={() => handleHideModal()}
        />
        <BatchCreateModal
          onBatchCreate={() => onBatchCreate()}
          item={batchCreateModalShow}
          setModalShow={setBatchCreateModalShow}
          onHide={() => handleHideModal()}
        />
        <BatchTransferModal
          onBatchCreate={() => onBatchCreate()}
          item={batchTransferModalShow}
          setModalShow={setBatchTransferModalShow}
          onHide={() => handleHideModal()}
        />
        <BatchWithdrawModal
          item={batchWithdrawModalShow}
          onBatchCreate={() => onBatchCreate()}
          onHide={() => handleHideModal()}
        />
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
  })(EditLaunchpadListingsForm)
);
