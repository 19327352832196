import styled from "styled-components";
import TransakButton from "./TransakButton";
import { useSelector } from "react-redux";
import { IS_TESTNET } from "utils/environments";

const TransakWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  border-radius: 0.75rem;
  padding: 2rem;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const AddFundsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AddFundsText = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const AddFundsWarning = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const AddFundsTransak = () => {
  const user = useSelector((state) => state.user);

  if (IS_TESTNET) {
    return null;
  }
  return (
    <TransakWrapper>
      <AddFundsWarning>
        <i className="far fa-info-circle" style={{ color: "#f0ad4e" }} /> Need more SUI?
      </AddFundsWarning>
      <AddFundsWrapper>
        <AddFundsText>Add Funds Instantly:</AddFundsText>
        <TransakButton user={user} />
      </AddFundsWrapper>
    </TransakWrapper>
  );
};

export default AddFundsTransak;
