import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { findIndexByValue, mystToSui } from "utils/formats";
import { useTitle } from "components/utils/TitleProvider";
import ReviewGamesPanel from "components/layouts/review/Panels/ReviewGamesPanel";
import ReviewCollectionsPanel from "./review/Panels/ReviewCollectionsPanel";
import ReviewLaunchpadsPanel from "./review/Panels/ReviewLaunchpadsPanel";
import { getMarketFees, withdrawMarketFees } from "web3/sui";
import ToastPopup from "components/utils/ToastPopup";
import { getAllGames, getAllCollections, getAllLaunchpads } from "redux/state/search";
import { useRefreshSearch } from "components/utils/SearchProvider";

const ReviewTabs = () => {
  const dataTabs = [
    {
      title: "Games",
      link: "games",
      panel: <ReviewGamesPanel title="Games" />,
      searchFunction: () => getAllGames,
    },
    {
      title: "Collections",
      link: "collections",
      panel: <ReviewCollectionsPanel title="Collections" />,
      searchFunction: () => getAllCollections,
    },
    {
      title: "Mintpads",
      link: "mintpads",
      panel: <ReviewLaunchpadsPanel title="Mintpads" />,
      searchFunction: () => getAllLaunchpads,
    },
  ];

  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/review/${link}`);
  };

  const withdrawFees = async () => {
    let fees = await getMarketFees();
    ToastPopup(`Withdrawing: ${mystToSui(fees)}`);
    withdrawMarketFees(fees);
  };

  return (
    <div>
      <button className="mb-4 mr-4" onClick={withdrawFees}>
        Withdraw Marketplace Fees
      </button>
      <button onClick={() => navigate("/add-collection")}>Add Collection</button>
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default ReviewTabs;
