import { useRef, useState, useEffect } from "react";
import { EthosConnectStatus, ethos } from "ethos-connect";
import { Link, useLocation } from "react-router-dom";
import logoheader from "../../assets/images/logo/logo.png";
import logoicon from "../../assets/images/logo/logo_icon.png";
import logoheader2x from "../../assets/images/logo/logo@2x.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import { SignInButton } from "ethos-connect";
import AutocompleteSearchBar from "components/layouts/AutocompleteSearchBar";
import HeaderProfile from "./HeaderProfile";
import MainNav from "./MainNav";
import LoadingSpinner from "components/utils/LoadingSpinner";
import DarkMode from "./DarkMode";
import ResetBanner from "components/header/ResetBanner";
import OutageBanner from "./OutageBanner";
import { NotifiModal } from "components/notifi/NotifiModal";
import { useSelector } from "react-redux";
import NotificationCenter from "components/NotificationCenter/NotificationCenter";
import { ellipsifyString } from "utils/formats";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import ToastPopup from "components/utils/ToastPopup";

const WalletAddress = styled.div`
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  background-color: transparent;
  border: 2px solid var(--primary-color3);
  color: var(--primary-color10);
  cursor: pointer;
  width: max-content;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;

  :hover {
    color: #fff;
    background-color: var(--primary-color3);
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

const Header = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const account = useSelector((state) => state.sui?.account);

  const wallet = ethos.useWallet();

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header?.classList?.add("is-fixed")
      : header?.classList?.remove("is-fixed");
    scrollTop >= 400
      ? header?.classList?.add("is-small")
      : header?.classList?.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [profile, toggleProfile] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const CopyWallet = () => {
    return (
      <CopyToClipboard
        style={{
          cursor: "pointer",
        }}
        text={user.account_address}
        onCopy={() => ToastPopup("Copied wallet address to clipboard!", "success")}
      >
        <WalletAddress>
          <i className="fal mr-3 fa-copy"></i>
          {ellipsifyString(account, 10)}
        </WalletAddress>
      </CopyToClipboard>
    );
  };

  return (
    <header
      id="header_main"
      className="header_1 js-header flex align-items-center"
      ref={headerRef}
    >
      <div
        className={`avatar_popup_bg ${profile ? "visible" : ""}`}
        onClick={() => toggleProfile(false)}
      ></div>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="flex justify-content-between align-items-center header_1-inner">
                <div className="min-width-max-content">
                  <Link to="/" rel="home" className="main-logo">
                    <img
                      className="logo-icon"
                      id="logo_icon"
                      src={logoicon}
                      alt="keepsake-panda"
                    />
                    <img
                      className="logo-dark hidden-xs-down"
                      id="logo_header"
                      src={logodark}
                      srcSet={`${logodark2x}`}
                      alt="nft-gaming"
                    />
                    <img
                      className="logo-light hidden-xs-down"
                      id="logo_header"
                      src={logoheader}
                      srcSet={`${logoheader2x}`}
                      alt="nft-gaming"
                    />
                  </Link>
                </div>
                <AutocompleteSearchBar />
                <MainNav
                  menuLeft={menuLeft}
                  handleOnClick={handleOnClick}
                  pathname={pathname}
                  activeIndex={activeIndex}
                />
                <div className="flat-search-btn flex min-width-max-content">
                  {!user._id || wallet.status !== EthosConnectStatus.Connected ? (
                    wallet.status !== EthosConnectStatus.Loading ? (
                      <SignInButton>Connect</SignInButton>
                    ) : (
                      <div className="center-margin">
                        <LoadingSpinner size="medium" />
                      </div>
                    )
                  ) : (
                    <>
                      <HeaderProfile toggleProfile={toggleProfile} profile={profile} />
                      <CopyWallet />
                      {/* {ellipsifyString(account, 10)} */}
                      {/* <NotificationCenter /> */}
                      <NotifiModal />
                    </>
                  )}

                  <DarkMode />
                  <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <OutageBanner /> */}
      <ResetBanner />
    </header>
  );
};

export default Header;
