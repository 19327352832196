import { Modal } from "react-bootstrap";
import LoadingButton from "components/button/LoadingButton";
import { Field, reduxForm } from "redux-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { burnNFT, isValidObjectId, makeNftAuction, makeNftListing } from "web3/sui";
import { renderFormV2 } from "utils/form";
import { ellipsifyString, ipfsConvert, mystToSui } from "utils/formats";
import Tooltip from "components/utils/Tooltip";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToastPopup from "components/utils/ToastPopup";
import { sleep } from "utils/time";
import { announceNFTs, getMyLaunchpads, getCollectionFromType } from "utils/api";
import { useNavigate } from "react-router-dom";
import Fees from "../collections/Fees";
import CopyToClipboard from "react-copy-to-clipboard";
import { tryAgain } from "utils/performance";
import AddFundsTransak from "components/AddFundsTransak";
import { getKiosks } from "redux/state/sui";

const formName = "sell-modal";

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!isValidObjectId(values.object_id)) {
      errors.object_id = "Invalid Sui Object ID";
    }
    if (!values.starts) {
      errors.starts = "Required";
    }
    if (!values.expires) {
      errors.expires = "Required";
    }
    if (values.starts >= values.expires) {
      errors.starts = "Start date cannot be after end date";
    }
    if (!values.price) {
      errors.price = "Required";
    }
    if (!values.min_bid) {
      errors.min_bid = "Required";
    }
    if (!values.min_bid_increment) {
      errors.min_bid_increment = "Required";
    }
  }
  return errors;
};

const SellModal = ({ item: nft, kiosk, onHide, handleSubmit }) => {
  if (!nft) {
    return false;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const ob_kiosks = useSelector((state) => state.sui.ob_kiosks);
  const kiosks = useSelector((state) => state.sui.kiosks);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [nftCollection, setCollection] = useState(false);

  useEffect(() => {
    if (user._id) {
      fetchCollection();
    }
  }, [nft, user]);

  const fetchCollection = async () => {
    try {
      let collection = await getCollectionFromType(nft.type);
      collection = collection.data?.collection;
      setCollection(collection);
    } catch {}
  };

  const handleCopy = () => {
    ToastPopup("Copied wallet address to clipboard!", "success");
  };

  const submit = async (values) => {
    try {
      let promise = false;
      setLoading(true);
      switch (tab) {
        default:
        case 0:
          if (nftCollection.kiosk_enabled) {
            promise = makeNftListing(nft, nftCollection, values.price, kiosks);
          } else {
            promise = makeNftListing(
              nft,
              nftCollection,
              values.price,
              kiosk?.id || ob_kiosks[0]?.id
            );
          }

          break;
        case 1:
          if (nftCollection.kiosk_enabled) {
            promise = makeNftAuction(nft, nftCollection, values, kiosks);
          } else {
            promise = makeNftAuction(nft, nftCollection, values, ob_kiosks?.[0]?.id);
          }
          break;
        case 2:
          ToastPopup("Mintpad successfully updated.", "success");
          break;
      }
      if (promise) {
        let info = await promise;
        await sleep(6000);
        const apiResponse = await tryAgain(
          () =>
            announceNFTs(info.effects.transactionDigest).then((res) => {
              if (!res.data.listings[0]?._id) {
                return Promise.reject();
              } else {
                return Promise.resolve(res);
              }
            }),
          5,
          10_000
        );

        if (!ob_kiosks?.[0]?.id) {
          dispatch(getKiosks(user.account_address));
        }
        // TODO: remove item from inventory, or set disabled in kiosk

        if (apiResponse.data.success) {
          navigate(`/item-details/${apiResponse.data.listings[0]._id}`);
        } else {
          ToastPopup(
            "An error occurred while trying to list this NFT, the listing may take some time to show up.",
            "error"
          );
        }
      }
    } catch (e) {
      console.log("An error occurred while trying to sell this NFT: ", e);
      ToastPopup(
        "An error occurred while trying to sell this NFT, please try again later.",
        "error"
      );
      setLoading(false);
    }
  };

  let hasAuctions = nftCollection.ob_enabled ? !!nftCollection.features?.auction : true;

  return (
    <Modal show={nft} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body pd-40">
        <div className="list-item">
          <div className="flat-tabs tab-list-item space-y-20 ">
            <h3>Sell {nft.display?.data?.name}?</h3>
            <div className="flex justify-content-center">
              <img
                src={ipfsConvert(
                  nft.display?.data?.thumb_url || nft.display?.data?.image_url
                )}
                style={{ width: "50%" }}
              />
            </div>
            <CopyToClipboard
              style={{
                cursor: "pointer",
              }}
              text={nft.id}
              onCopy={() => handleCopy()}
            >
              <div>
                <i className="fal mr-3 fa-copy"></i>
                {ellipsifyString(nft.id, 16)}
              </div>
            </CopyToClipboard>
            <form onSubmit={handleSubmit(submit)}>
              <Tabs onSelect={setTab}>
                <TabList className="react-tabs__tab-list two-tabs">
                  <Tab>
                    <span className="icon-fl-tag" />
                    Fixed Price
                  </Tab>
                  <Tab disabled={!hasAuctions}>
                    <span className="icon-fl-clock" />
                    Timed Auction
                  </Tab>
                </TabList>
                {/* {notOwnerError && <div className="form-error">{notOwnerError}</div>} */}
                {tab < 2 && (
                  <>
                    <Fees
                      price={
                        (tab == 0 ? form?.values?.price : form?.values?.min_bid) || 0
                      }
                    />
                    <hr />
                  </>
                )}
                <TabPanel>
                  <Field
                    type="number"
                    name="price"
                    placeholder="Enter price for one NFT (SUI)"
                    props={{
                      // min: mystToSui(settings.min_bid_increment),
                      step: "any",
                    }}
                    required
                    component={renderFormV2}
                  />
                </TabPanel>
                <TabPanel>
                  <Field
                    type="number"
                    name="min_bid"
                    title="Minimum bid"
                    placeholder="Enter minimum bid"
                    props={{
                      min: mystToSui(settings.min_bid_increment),
                      step: "any",
                    }}
                    required
                    component={renderFormV2}
                  />
                  <Field
                    type="number"
                    name="min_bid_increment"
                    title="Minimum bid Increment"
                    placeholder="Enter minimum bid"
                    props={{
                      step: "any",
                    }}
                    required
                    appendTitle={
                      <Tooltip>
                        <p>
                          e.g. if the current bid is 1 SUI and the bid increment is 0.1,
                          the next bid must be &ge; 1.1 Sui. This is to avoid someone
                          bidding 1.0000000000000001 SUI
                        </p>
                        <i className="fas fa-info-circle" />
                      </Tooltip>
                    }
                    component={renderFormV2}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        type="datetime-local"
                        name="starts"
                        title="Starting date"
                        props={{ min: "2022-01-01 00:00" }}
                        required
                        component={renderFormV2}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        type="datetime-local"
                        name="expires"
                        title="Expiration date"
                        required
                        component={renderFormV2}
                      />
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              <AddFundsTransak />
              {
                /* TODO: Show error when no collection is found*/
                !nftCollection && (
                  <p className="error">Collection is not registered with Keepsake</p>
                )
              }
              {nftCollection && !nftCollection.active && (
                <p className="error">
                  Collection is not active on Keepsake. The NFT can be listed, but will
                  not show up in searches
                </p>
              )}
              <LoadingButton
                loading={loading}
                disabled={loading || !nftCollection}
                type="submit"
                className="fullWidth mt-4"
              >
                Create Listing
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
})(SellModal);
