import styled from "styled-components";

const Total = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const TotalPrice = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color2);
`;

const Royalties = ({ item, price, collection }) => {
  let total = price;

  const renderRoyalty = (royalty, price) => {
    let cut = 0;
    let feeDisplay = "";
    switch (royalty.type) {
      case "keepsake_royalties::BpsRoyaltyStrategy":
      default:
        let percentage = royalty.amount / 100;
        cut = (price * percentage) / 100;
        feeDisplay = `Royalty Fee: ${percentage}%`;
        break;
    }
    total += cut;
    return (
      <div key={royalty.id} className="d-flex justify-content-between">
        <p>{feeDisplay}</p>
        <p className="text-right price color-popup">{cut} SUI</p>
      </div>
    );
  };
  let royalties = item?.seller_kiosk ? collection.ob_royalties : collection.royalties;
  let externalFee = price * 0.005;

  if (item?.external) total += externalFee;

  return (
    <div>
      {royalties?.length > 0
        ? royalties?.map((royalty) => renderRoyalty(royalty, price))
        : renderRoyalty({ amount: collection.royaltyFee }, price)}
      {item?.external && (
        <div className="d-flex justify-content-between">
          <p>Seller Fee: 0.5%</p>
          <p className="text-right price color-popup">{externalFee} SUI</p>
        </div>
      )}
      <br />
      <div className="d-flex justify-content-between dashed-line">
        <Total>Total:</Total>
        <TotalPrice>{Math.ceil(total * 10000) / 10000} SUI</TotalPrice>
      </div>
    </div>
  );
};
export default Royalties;
