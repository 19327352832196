import { createRoot } from "react-dom/client";
import ReduxWrapper from "./ReduxWrapper";
import "./css/style.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <>
    <ReduxWrapper />
  </>
);
