import {
  LOGIN,
  CONTENTS,
  BALANCES,
  CONNECTED,
  DISCONNECTED,
  CONNECTING,
  LOADING,
  LOGOUT,
  KIOSKS,
  OB_KIOSKS,
  KIOSK_CONTENTS,
} from "redux/types";
import {
  getUserObjects,
  setProvider,
  loginSignature,
  getUserCoins,
  getObjectsOwnedByObject,
  getObjectsInfo,
  currentSettings,
  getObjectByType,
  getObjectsByType,
  SuiAddress,
} from "web3/sui";
import {
  signIn,
  getCurrentUser,
  getBearerToken,
  setBearerToken,
  clearBearerToken,
  refreshInstance,
} from "utils/api";
import { getName } from "web3/suins";
import update from "immutability-helper";
import { sleep } from "utils/time";

const attemptSign = (address, signer) => {
  return loginSignature(signer)
    .then((res) => {
      return signIn(res);
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
};

export const connect = (provider, signer) => async (dispatch) => {
  dispatch(setConnecting(true));
  const address = await signer.getAddress();
  if (!address) {
    return;
  }

  dispatch(setLoading(true));
  let bearerToken = getBearerToken();
  let user = false;

  setProvider(provider, signer, address);
  const name = await getName(address);
  if (bearerToken) {
    // TODO: hide error for bad token from the toasts.
    try {
      const res = await getCurrentUser();
      user = res.data.data.user;
      user.suiName = name;
      if (user?.account_address !== address) {
        clearBearerToken();
        refreshInstance();
        bearerToken = false;
      } else {
        dispatch({ type: LOGIN, user });
        dispatch({
          type: CONNECTED,
          account: address,
        });
        return;
      }
    } catch (e) {
      clearBearerToken();
      refreshInstance();
      bearerToken = false;
    }
  }

  if (!bearerToken) {
    let res = await attemptSign(address, signer);
    if (!res) {
      dispatch(setConnecting(false));
    } else {
      user = res.data.data.user;
      user.suiName = name;
      if (user._id) {
        setBearerToken(res.data.token);
        refreshInstance();
        dispatch({
          type: LOGIN,
          user,
        });
        dispatch({
          type: CONNECTED,
          account: address,
        });
      }
    }
  } else if (user) {
    dispatch({ type: LOGIN, user });
    dispatch({
      type: CONNECTED,
      account: address,
    });
  }
};

export const disconnect = () => async (dispatch) => {
  clearBearerToken();
  dispatch({
    type: DISCONNECTED,
  });
  dispatch({
    type: LOGOUT,
  });
};

export const setConnecting = (connecting) => (dispatch) => {
  dispatch({
    type: CONNECTING,
    connecting,
  });
};

export const setLoading = (loading) => async (dispatch) => {
  dispatch({
    type: LOADING,
    loading,
  });
};

export const setBalances = (total) => async (dispatch) => {
  dispatch({
    type: BALANCES,
    total: total,
  });
};

export const getKiosks = (account_address) => async (dispatch) => {
  let settings = currentSettings();

  let filter = {
    MatchAny: [
      { StructType: `${settings.object_ids.ob_kiosk}::ob_kiosk::OwnerToken` },
      { StructType: `${SuiAddress}::kiosk::KioskOwnerCap` },
    ],
  };
  if (settings.object_ids.personal_kiosk) {
    filter.MatchAny.push({
      StructType: `${settings.object_ids.personal_kiosk}::personal_kiosk::PersonalKioskCap`,
    });
  }

  let kiosks = await getUserObjects({ filter, address: account_address });

  let kiosk_data = await getObjectsInfo(
    kiosks.data.map((key) => {
      return key.data.kiosk || key.data.for || key.data.cap.fields.for;
    })
  );

  let ob_kiosk_data = [];
  let kiosks_data = [];

  kiosks.data.forEach((cap) => {
    let kiosk_id = cap.data.kiosk || cap.data.for || cap.data.cap.fields.for;
    let index = kiosk_data.findIndex((a) => a.id === kiosk_id);

    kiosk_data[index].cap = cap.id;
    if (cap.type.includes("ob_kiosk")) {
      ob_kiosk_data.push(kiosk_data[index]);
    } else {
      kiosk_data[index].standard = cap.type.includes("personal_kiosk::PersonalKioskCap")
        ? "personal"
        : false;
      kiosks_data.push(kiosk_data[index]);
    }
  });

  // put the oldest kiosk first, so order never changes when new kiosks are created
  ob_kiosk_data.reverse();
  kiosks_data.reverse();

  // TODO: handle pagination. hasNextPage, nextCursor

  dispatch({
    type: OB_KIOSKS,
    data: ob_kiosk_data,
  });

  dispatch({
    type: KIOSKS,
    data: kiosks_data,
  });
};

export const getObjectsByStructType = async (struct_type, account_address) => {
  let nfts;
  if (struct_type) {
    nfts = await getUserObjects({
      filter: {
        StructType: struct_type,
      },
      address: account_address,
    });
  } else {
    nfts = await getUserObjects({
      address: account_address,
    });
  }
  let nftData = await getObjectsInfo(
    nfts.data.map((key) => key.data.id.id),
    { showDisplay: true, showType: true, showContent: true }
  );

  return nftData;
};

const fetchKioskItems = async (kiosk_id, prevData, cursor, exclusion = [], tries = 0) => {
  let data = await getObjectsOwnedByObject(kiosk_id, cursor);

  let displayData = await getObjectsInfo(
    data.data.map((a) => a.objectId),
    { showDisplay: true, showType: true, showContent: true }
  );

  let refs = false;
  let newExclusion = exclusion;
  refs = getObjectsByType("0x2::kiosk::Listing", displayData);
  newExclusion = exclusion.concat(refs.map((a) => a.data.name.fields.id));

  displayData = displayData.filter((a) => a.display.data);
  let newData = prevData.concat(displayData);

  if (!data.hasNextPage) {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion: newExclusion,
    };
  } else if (tries < 5) {
    return fetchKioskItems(kiosk_id, newData, data.nextCursor, exclusion, tries + 1);
  } else {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion: newExclusion,
    };
  }
};
export const getKioskItems =
  (kiosk_id, cursor, exclusion = []) =>
  async (dispatch) => {
    let kiosk = await fetchKioskItems(kiosk_id, [], cursor, exclusion);

    kiosk.data = kiosk.data.filter((a) => a.display.data);
    kiosk.data.forEach((nft, index) => {
      if (Array.isArray(kiosk.exclusion) && kiosk.exclusion.includes(nft.id)) {
        kiosk.data[index].listed = true;
      }
    });

    dispatch({
      type: KIOSK_CONTENTS,
      kiosk_id: kiosk_id,
      kiosk,
    });
  };

const fetchOBKioskItemsUntilRef = async (
  kiosk_id,
  prevData,
  cursor,
  exclusion,
  tries = 0
) => {
  let settings = currentSettings();
  let data = await getObjectsOwnedByObject(kiosk_id, cursor);

  let displayData = await getObjectsInfo(
    data.data.map((a) => a.objectId),
    { showDisplay: true, showType: true, showContent: true }
  );

  let refs = false;
  if (!exclusion) {
    refs = getObjectByType(
      `${settings.object_ids.ob_kiosk}::ob_kiosk::NftRef`,
      displayData
    );
  }

  displayData = displayData.filter((a) => a.display.data);
  let newData = prevData.concat(displayData);

  if (exclusion) {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion,
    };
  } else if (refs) {
    let refsTable = await getObjectsOwnedByObject(refs.data.value.fields.id.id);
    let refsInfo = await getObjectsInfo(
      refsTable.data.map((a) => a.objectId),
      { showContent: true }
    );
    let skip = refsInfo
      .filter((a) => a.data.value.fields.is_exclusively_listed)
      .map((a) => a.data.name);
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
      exclusion: skip,
    };
  } else if (tries < 5) {
    return fetchOBKioskItemsUntilRef(
      kiosk_id,
      newData,
      data.nextCursor,
      false,
      tries + 1
    );
  } else {
    return {
      data: newData,
      nextCursor: data.nextCursor,
      hasNextPage: data.hasNextPage,
    };
  }
};

export const getOBKioskItems = (kiosk_id, cursor, exclusion) => async (dispatch) => {
  let kiosk = await fetchOBKioskItemsUntilRef(kiosk_id, [], cursor, exclusion);

  kiosk.data = kiosk.data.filter((a) => a.display.data);
  kiosk.data.forEach((nft, index) => {
    if (Array.isArray(kiosk.exclusion) && kiosk.exclusion.includes(nft.id)) {
      kiosk.data[index].listed = true;
    }
  });

  dispatch({
    type: KIOSK_CONTENTS,
    kiosk_id: kiosk_id,
    kiosk,
  });
};

const getNextContents = async (nextCursor) => {
  let realNextCursor = nextCursor;
  let hasNextPage = false;
  let data = [];

  while (data.length < 12) {
    let nextPage = await getUserObjects({ cursor: realNextCursor });
    nextPage.data = nextPage.data
      .filter((a) => a.display.data)
      .filter((a) => !a.type.includes("ob_kiosk::OwnerToken"));
    data = data.concat(nextPage.data);
    hasNextPage = nextPage.hasNextPage;
    realNextCursor = nextPage.nextCursor;
    if (!hasNextPage) {
      break;
    }
  }
  return { nextCursor: realNextCursor, hasNextPage, data };
};

export const getContents =
  ({ data: currentContents, nextCursor: cursor }) =>
  async (dispatch) => {
    let { data, nextCursor, hasNextPage } = await getNextContents(cursor);

    currentContents = currentContents.concat(data.filter((a) => a));

    dispatch({
      type: CONTENTS,
      data: currentContents,
      nextCursor,
      hasNextPage,
    });
  };

const INIT_STATE = {
  connecting: false,
  connected: false,
  total: 0,
  largest: 0,
  loading: true,
  contents: {
    data: [],
    hasNextPage: false,
  },
  kiosks: [],
  ob_kiosks: [],
  kiosk_contents: {},
  suiObjects: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONNECTING:
      return { ...state, connecting: action.connecting };
    case CONNECTED:
      return {
        ...state,
        loading: false,
        connected: !!action.account,
        account: action.account,
        name: action.name,
        total: state.total,
      };
    case DISCONNECTED:
      return {
        INIT_STATE,
        loading: false,
      };
    case LOADING:
      return { ...state, loading: action.loading };
    case BALANCES:
      return {
        ...state,
        total: action.total,
      };
    case CONTENTS:
      if (action.data?.length > 0) {
        return {
          ...state,
          contents: {
            data: action.data,
            nextCursor: action.nextCursor,
            hasNextPage: action.hasNextPage,
          },
        };
      }
      break;
    case OB_KIOSKS:
      return {
        ...state,
        ob_kiosks: action.data,
      };
    case KIOSKS:
      return {
        ...state,
        kiosks: action.data,
      };
    case KIOSK_CONTENTS:
      if (state.kiosk_contents[action.kiosk_id]) {
        let { data, hasNextPage, nextCursor } = action.kiosk;
        return update(state, {
          kiosk_contents: {
            [action.kiosk_id]: {
              data: { $push: data },
              hasNextPage: { $set: hasNextPage },
              nextCursor: { $set: nextCursor },
            },
          },
        });
      } else {
        return update(state, {
          kiosk_contents: {
            $merge: { [action.kiosk_id]: action.kiosk },
          },
        });
      }
    default:
      return state;
  }
};
export default reducer;
