import styled from "styled-components";
import MyProfileListings from "components/layouts/MyProfileListings";
import React from "react";

const DisplayName = styled.h2`
  font-size: 38px;
  margin-right: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: 1rem;
`;

const ProfileListings = () => {
  return (
    <ContentWrapper className={"col-box-83"}>
      <DisplayName>Listings</DisplayName>
      <MyProfileListings />
    </ContentWrapper>
  );
};

export default ProfileListings;
