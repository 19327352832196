import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Countdown from "react-countdown";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import LiveAuction from "components/layouts/LiveAuction";
import LoadingSpinner from "components/utils/LoadingSpinner";
import BuyModal from "components/layouts/modal/BuyModal";
import WinModal from "components/layouts/modal/WinModal";
import WishlistButton from "components/button/WishlistButton";
import Avatar from "components/layouts/Avatar";
import RequireAmountButton from "components/button/RequireAmountButton";
import { useTitle } from "components/utils/TitleProvider";
import LoadingButton from "components/button/LoadingButton";
import BidModal from "components/layouts/modal/BidModal";
import CreatedBy from "components/utils/CreatedBy";
import TimeTracker from "components/NotificationCenter/TimeTracker";
import { listingToken, listingDisplayPrice, mystToSui, ipfsConvert } from "utils/formats";
import { IS_PROD, SuiExplorer } from "utils/environments";
import { getListing, updateListing } from "utils/api";
import { winNFTAuction, getObjectInfo } from "web3/sui";
import { WIN_LISTING } from "redux/types";
import Accordion from "components/layouts/Accordion";
import UnlistModal from "components/layouts/modal/UnlistModal";
import AttributesGrid from "components/layouts/AttributesGrid";
import LazyLoadImage from "components/layouts/LazyLoadImage";
import NFTCongratulationsModal from "components/layouts/modal/NFTCongratulationsModal";

const RefreshTimerText = styled.span`
  color: var(--primary-color9);
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

const PrimaryInfoContainer = styled.div`
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius-md);
  border: 2px solid #353840;
  background: transparent !important;
  text-align: start;
  margin-bottom: 1rem;
`;

const SmallButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  white-space: nowrap;
`;
const Views = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color2);
  background-color: transparent;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;
`;

const AuctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;
`;

const InfoHeading = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: var(--primary-color4);
`;

const ContentRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 1rem;
  gap: 1rem;

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

const ItemName = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 575px) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 2.5rem;
`;

const DetailsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const DetailsName = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

const DetailsValue = styled.div`
  font-size: 14px;
  color: var(--primary-color9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const RefreshButton = ({ loading, refreshHandler }) => {
  return (
    <LoadingButton
      loading={loading}
      className="refresh-button small-refresh"
      onClick={refreshHandler}
    >
      <i className="fas fa-redo-alt" />
    </LoadingButton>
  );
};

const ItemDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { setTitle } = useTitle();
  if (!params.id) navigate("/explore");
  const user = useSelector((state) => state.user);
  const kiosks = useSelector((state) => state.sui.kiosks);

  const [listing, setListing] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [listingLoading, setListingLoading] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(1);
  const [auctionStatus, setAuctionStatus] = useState(0);
  const [buyModal, showBuyModal] = useState(false);
  const [congratulationsModal, showCongratulationsModal] = useState(false);
  const [winModal, showWinModal] = useState(false);
  const [unlistModal, showUnlistModal] = useState(false);
  const [canUnlist, setCanUnlist] = useState(false);
  const nft = listing.nft || {};
  const history = listing.history || [];
  const favorited = useMemo(() => user.favorite_nfts?.includes(nft?._id), [user, nft]);
  const isOwner = user?._id === listing?.seller?._id;
  const isAuction = listing?.sale_type === "auction";
  const isCurrentBidder = isAuction && user?._id === listing.auction?.bidder;

  const renderHistoryText = (sale) => {
    switch (sale.result) {
      case "sold":
        return `Sold it for ${mystToSui(sale.sale_price)} ${listingToken(sale)}`;
      case "cancelled":
        return `Cancelled the listing`;
      default:
        break;
    }
  };

  let buyText = useMemo(() => {
    if (isAuction) {
      const now = Date.now();
      const end = new Date(listing.auction.end_date);
      const unlistDate = new Date(listing.auction.end_date);
      unlistDate.setDate(end.getDate() + 1);

      if (!isOwner) {
        if (user._id === listing.auction?.bidder && now > end) {
          return "Claim";
        } else {
          return "Bid";
        }
      } else {
        if (canUnlist) {
          return "Edit Listing";
        } else {
          return (
            <>
              <span>Unlist in: </span>
              <Countdown onComplete={() => setCanUnlist(true)} date={unlistDate} />
            </>
          );
        }
      }
    } else {
      return "Buy";
    }
  }, [listing?.auction?.end_date, user?._id, canUnlist]);

  // If is loading, bought, or is auction that has ended, but the user can't claim it...
  let disabled =
    buyLoading ||
    !listing.active ||
    (isOwner ? !canUnlist : isAuction && (listing.result || auctionStatus === 0));

  if (!IS_PROD) {
    disabled = false;
  }

  const updateAuction = (status) => {
    if (status > auctionStatus) {
      setAuctionStatus(status);
    }
  };

  const beforeSetListing = (listing) => {
    if (listing.auction) {
      const now = Date.now();
      const start = new Date(listing.auction.start_date);
      const end = new Date(listing.auction.end_date);
      if (now > start) {
        updateAuction(now > end ? 2 : 1);
      }
      if (now < end) {
        setCanUnlist(true);
      }
    }
    setListing(listing);
  };

  const resetTimer = () => {
    setRefreshTimer(Date.now() + 9000);
    setListingLoading(false);
  };

  const fetchListing = () => {
    if (listingLoading === true || (listing && !listing.active)) return null;
    setListingLoading(true);
    getListing(params.id)
      .then(({ data }) => {
        setTitle(data.nft.name + " - NFT");
        if (!data.seller_kiosk) {
          getObjectInfo(data.listing_object_id).then((res) => {
            // check if listing exists on blockchain, then if it doesn't, request listing update.
            if (res) {
              // update auction price, if it's changed.
              if (data.sale_price > res.data.bid) {
                updateListing(data._id);
              }
              beforeSetListing(data);
            } else if (data.active) {
              beforeSetListing({ ...data, ...{ active: false } });
              updateListing(data._id);
            } else {
              data.active = false;
              beforeSetListing(data);
            }
          });
        } else {
          beforeSetListing(data);
        }
        setTimeout(() => resetTimer(), 500);
      })
      .catch(() => {
        navigate("/explore");
      });
  };

  // hacky fix for the favorites button not incrementing immediately.
  const favouriteHandler = () => {
    if (favorited) {
      nft.favorites = nft.favorites - 1;
    } else {
      nft.favorites = nft.favorites + 1;
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchListing();
    }
  }, [params.id]);

  useEffect(() => {
    if (auctionStatus === 2) {
      if (isOwner && isCurrentBidder) {
        setCanUnlist(true);
      } else {
        setCanUnlist(false);
      }
    }
  }, [auctionStatus]);

  const CurrentPrice = () => {
    return (
      <PriceWrapper>
        <InfoHeading>Current {isAuction ? "Bid" : "Price"}</InfoHeading>
        {!listing.active ? (
          <h2>
            {listing ? (listing.result == "cancelled" ? "Cancelled!" : "Sold!") : "???"}
          </h2>
        ) : (
          <h2>{listingDisplayPrice(listing)} SUI</h2>
        )}
        {isAuction &&
          listing.result !== "cancelled" &&
          listing.sale_price < listing.auction.min_bid && (
            <InfoHeading>
              Minimum Bid: {mystToSui(listing.auction.min_bid)} SUI
            </InfoHeading>
          )}
      </PriceWrapper>
    );
  };

  const AuctionTimer = () => {
    return (
      <AuctionWrapper>
        {auctionStatus === 0 && (
          <>
            <InfoHeading>Auction Starts in</InfoHeading>
            <h2>
              <Countdown
                onComplete={() => updateAuction(1)}
                date={listing.auction.start_date}
              />
            </h2>
          </>
        )}

        {auctionStatus === 1 && (
          <>
            <InfoHeading>Auction Ends In:</InfoHeading>
            <h2>
              <Countdown
                date={listing.auction.end_date}
                onComplete={() => {
                  updateAuction(2);
                  setCanUnlist(false);
                }}
              />
            </h2>
          </>
        )}

        {auctionStatus === 2 && <InfoHeading>Auction Has Finished</InfoHeading>}
      </AuctionWrapper>
    );
  };

  const AutoRefreshTimer = () => {
    const renderer = ({ seconds, completed }) => {
      if (listingLoading) return <RefreshTimerText>...</RefreshTimerText>;
      if (completed) {
        return <RefreshTimerText>...</RefreshTimerText>;
      } else {
        return <RefreshTimerText>{seconds}s</RefreshTimerText>;
      }
    };

    return (
      <Countdown date={refreshTimer} renderer={renderer} onComplete={fetchListing} />
    );
  };

  const handleSubmit = async () => {
    // setBuyLoading(true);
    if (isOwner && canUnlist) {
      showUnlistModal(listing);
    } else {
      if (listing.sale_type !== "auction") {
        showBuyModal(true);
      } else {
        if (auctionStatus == 2) {
          if (!winModal) {
            showWinModal(listing);
          }
        } else {
          showBuyModal(true);
        }
      }
      setBuyLoading(false);
    }
  };

  const onBuy = async (item) => {
    showBuyModal(false);
    showCongratulationsModal(item);
  };

  const onBid = (item) => {
    updateListing(item._id);
  };

  const handleHideModal = () => {
    setBuyLoading(false);
    showWinModal(false);
    showUnlistModal(false);
    showBuyModal(false);
    showCongratulationsModal(false);
  };

  return (
    <div className="item-details">
      <Header />
      <PageHeader />
      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  {listing ? (
                    listing?.nft?.image &&
                    ipfsConvert(listing.nft.image).split(".").pop() === "mp4" ? (
                      <video
                        style={{ zIndex: 1, borderRadius: "var(--border-radius-md)" }}
                        autoPlay
                        muted
                        loop
                      >
                        <source src={ipfsConvert(listing.nft.image)} type="video/mp4" />
                      </video>
                    ) : (
                      <LazyLoadImage src={ipfsConvert(listing.nft.image)} />
                    )
                  ) : (
                    <LoadingSpinner size="xxlarge" absolute />
                  )}
                </div>
                {nft?.fields && (
                  <div>
                    <Accordion title="Attributes" defaultOpen>
                      <AttributesGrid
                        collectionID={listing?.nft_collection?._id}
                        nftFields={nft?.fields}
                        saleType={listing?.sale_type}
                      />
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content-right">
                <ContentRightWrapper>
                  <div className="flex-space-between">
                    <Link to={`/collection-details/${listing?.nft_collection?._id}`}>
                      <h4>{listing?.nft_collection?.name || "Collection"}</h4>
                    </Link>
                    <a
                      className="heading"
                      href={`${SuiExplorer}/objects/${nft.object_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h5 className="tf-text fill">View on SuiExplorer</h5>
                    </a>
                  </div>
                  <NameWrapper>
                    <ItemName>{nft?.name || "NFT Name"}</ItemName>
                    <SmallButtonsWrapper>
                      <div className="meta-item">
                        <Views>
                          <span className="far fa-eye" />
                          <span>{nft?.views ?? "0"}</span>
                        </Views>
                      </div>
                      <WishlistButton nft={nft} onClick={() => favouriteHandler()} />
                    </SmallButtonsWrapper>
                  </NameWrapper>
                  <div className="client-info">
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      <span style={{ color: "var(--primary-color9)" }}>Owned By</span>{" "}
                      <CreatedBy creator={listing?.seller} />
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      <span style={{ color: "var(--primary-color9)" }}>Created By</span>{" "}
                      <CreatedBy creator={listing?.creator} />
                    </span>
                  </div>
                  <PrimaryInfoContainer>
                    <div className="meta-item-details">
                      <CurrentPrice />
                      {isAuction && listing.result !== "cancelled" && <AuctionTimer />}
                      <div className="meta-item">
                        <RefreshWrapper>
                          <AutoRefreshTimer />
                          <RefreshButton
                            loading={listingLoading}
                            refreshHandler={() => fetchListing()}
                          />
                        </RefreshWrapper>
                      </div>
                    </div>
                    <RequireAmountButton
                      amount={listing.sale_price}
                      disabled={disabled}
                      className="fullWidth"
                      text={buyText}
                    >
                      <LoadingButton
                        onClick={() => handleSubmit()}
                        disabled={disabled}
                        loading={buyLoading}
                        className="fullWidth"
                      >
                        {buyText}
                      </LoadingButton>
                    </RequireAmountButton>
                  </PrimaryInfoContainer>
                  <div className="flat-tabs themesflat-tabs">
                    {nft?.description && (
                      <Accordion title="Details" defaultOpen>
                        <InnerDetails>
                          <Description>{nft.description}</Description>
                        </InnerDetails>
                      </Accordion>
                    )}
                    {isAuction && (
                      <Accordion title="Bid History">
                        <ul className="bid-history-list">
                          {listing.auction.bids
                            ?.filter((a) => a.listing === listing._id)
                            .sort((a, b) => b.bid - a.bid)
                            .map(({ bidder, bid, _id, updatedAt }) => (
                              <li key={_id}>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar">
                                        <Avatar creator={bidder} size={50} />
                                        <div className="badge"></div>
                                      </div>
                                      <div className="author-info">
                                        <div className="name">
                                          <CreatedBy creator={bidder} />
                                          <span>placed a bid</span>
                                        </div>
                                        <span className="time">
                                          <TimeTracker createdAt={updatedAt} />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="price">
                                    <h5>{mystToSui(bid)}</h5>
                                    {/* <span>
                                      = ${Math.round(mystToSui(bid) * 10000) / 100}
                                    </span> */}
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </Accordion>
                    )}
                    <Accordion title="Sale History">
                      <ul className="bid-history-list">
                        {Object.assign([], history)
                          .reverse()
                          .map((sale) => (
                            <li key={sale._id}>
                              <div className="content">
                                <div className="client">
                                  <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                      <Avatar creator={sale.seller} size={50} />
                                      <div className="badge"></div>
                                    </div>
                                    <div className="author-info">
                                      <div className="name">
                                        <CreatedBy creator={sale.seller} />
                                        <span>{renderHistoryText(sale)}</span>
                                      </div>
                                      <span className="time">
                                        {new Date(sale.updatedAt).toLocaleString("en-US")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Accordion>
                    {listing?.nft_collection && (
                      <Accordion title="Market Info">
                        <InnerDetails>
                          <DetailsFlex key="object_id">
                            <DetailsName>NFT Object ID</DetailsName>
                            <DetailsValue>{nft.object_id}</DetailsValue>
                          </DetailsFlex>
                          <DetailsFlex key="collection_id">
                            <DetailsName>Collection Object ID</DetailsName>
                            <DetailsValue>
                              {listing?.nft_collection?.object_id}
                            </DetailsValue>
                          </DetailsFlex>
                          <DetailsFlex key="seller_fees">
                            <DetailsName>Seller Fees</DetailsName>
                            <DetailsValue>2.5%</DetailsValue>
                          </DetailsFlex>
                          {/* <DetailsFlex key="royalties">
                            <DetailsName>Royalties</DetailsName>
                            <DetailsValue>2.5%</DetailsValue>
                          </DetailsFlex> */}
                        </InnerDetails>
                      </Accordion>
                    )}
                  </div>
                </ContentRightWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveAuction />
      <Footer />
      <BuyModal
        item={!isAuction && buyModal ? listing : false}
        beforeSetListing={beforeSetListing}
        onBuy={onBuy}
        onHide={() => handleHideModal()}
      />
      <BidModal
        item={isAuction && buyModal ? listing : false}
        beforeSetListing={beforeSetListing}
        onBid={onBid}
        onHide={() => handleHideModal()}
      />
      <UnlistModal
        item={unlistModal}
        onHide={() => handleHideModal()}
        customFetch={fetchListing}
      />
      <WinModal
        item={winModal}
        onHide={() => handleHideModal()}
        fetchListing={fetchListing}
      />
      <NFTCongratulationsModal
        item={congratulationsModal}
        onHide={() => handleHideModal()}
      />
    </div>
  );
};

export default ItemDetails;
