import Countdown, { zeroPad } from "react-countdown";
import MintButtons from "./MintButtons";
import LaunchpadInfoCountdownCubes from "./LaunchpadInfoCountdownCubes";

const FeaturedLaunchpadCountdown = ({
  launchpad,
  saleIndex,
  handleBuyNFT,
  buyLoading,
  tickets,
  inventories,
  renderAutoRefreshTimer,
}) => {
  const collectionID = launchpad?.launchpad_collection?._id;
  const { active, start_date } = launchpad;

  const sale = launchpad?.sales[saleIndex] || {};
  const inventory = inventories?.[saleIndex] || { current: 0, total: 0 };
  const inventoryRemaining = inventory.total - inventory.current;
  const isSoldOut = Number(inventoryRemaining) === Number(inventory.total);
  const inventorySoldPercentage =
    inventory.total > 0 ? ((inventoryRemaining / inventory.total) * 100).toFixed(2) : 0;
  const current = new Date().valueOf();
  const formattedStartDate = new Date(sale.start_date).valueOf();

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if (formattedStartDate > current) {
        return (
          <div className="mint-buttons-container">
            <div className="flex justify-content-center">
              <LaunchpadInfoCountdownCubes
                key={sale.start_date}
                startDate={sale.start_date}
                endDate={sale.end_date}
                isSoldOut={isSoldOut}
              />
            </div>
          </div>
        );
      } else {
        return (
          <>
            <div className="fullWidth">
              <div className="flex justify-content-between mb-3">
                <p>Total Minted</p>
                <p>
                  <b>{inventorySoldPercentage}%</b> ({inventoryRemaining || 0}/
                  {inventory.total || 0})
                </p>
              </div>
              <div className="progress" style={{ height: "1.5rem" }}>
                <div
                  className="progress-bar bg-mint"
                  role="progressbar"
                  style={{
                    width: `${inventorySoldPercentage || 0}%`,
                    height: "1.5rem",
                  }}
                />
              </div>
            </div>
            <MintButtons
              handleBuyNFT={handleBuyNFT}
              buyLoading={buyLoading}
              launchpad={launchpad}
              sale={sale}
              tier={saleIndex}
              inventory={inventories?.[saleIndex]}
              tickets={tickets?.[saleIndex]}
              active={active}
              collectionID={collectionID}
              isSoldOut={isSoldOut}
              renderAutoRefreshTimer={renderAutoRefreshTimer}
            />
          </>
        );
      }
    } else {
      // Render a countdown
      return (
        <div className="countdown" style={{ textAlign: "center" }}>
          <div className="launchpad-details-countdown">
            <span>
              {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
            </span>
          </div>
        </div>
      );
    }
  };

  return <Countdown date={new Date(start_date).valueOf()} renderer={renderer} />;
};

export default FeaturedLaunchpadCountdown;
