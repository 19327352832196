import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ListGrid,
  NoResultsTextWrapper,
  PrimaryText,
  SecondaryText,
  FlexColumn,
  RowContainer,
  GridHeader,
  HeaderText,
} from "utils/styled";

const CardList = ({
  layout,
  setModalShow,
  setCount,
  setPages,
  SkeletonComponent,
  ListSkeletonComponent,
  ListCardComponent,
  orderedHeaders,
  CardComponent,
  PrefixCard,
  reduxState,
  onClick = null,
  title,
  noResultsCustomTitle,
  noResultsCustomSubtitle,
  ...props
}) => {
  const { count, results, pageLimit } = reduxState;
  const loading = useSelector((state) => state.search.loading);

  const renderColumnHeaders = () => {
    return orderedHeaders.map((header, index) => {
      return <HeaderText key={`column` + index}>{header}</HeaderText>;
    });
  };

  useEffect(() => {
    setCount(count);
    setPages(Math.max(Math.ceil(count / pageLimit), 1));
  }, [count]);

  return (
    <>
      {!loading && results.length === 0 && (
        <NoResultsTextWrapper>
          <PrimaryText>
            {noResultsCustomTitle ? noResultsCustomTitle : `No ${title} Found`}
          </PrimaryText>
          <SecondaryText>
            {noResultsCustomSubtitle
              ? noResultsCustomSubtitle
              : `Please adjust your search filters or check back later`}
          </SecondaryText>
        </NoResultsTextWrapper>
      )}

      {!layout ? (
        <ListGrid>
          {PrefixCard && <PrefixCard />}
          {loading
            ? Array.from({ length: pageLimit }, (_, index) => (
                <SkeletonComponent key={index} />
              ))
            : results.map((item) => (
                <CardComponent
                  item={item}
                  setModalShow={setModalShow}
                  key={item._id}
                  onClick={onClick && (() => onClick(item))}
                  {...props}
                />
              ))}
        </ListGrid>
      ) : (
        <FlexColumn>
          <div style={{ overflowX: "auto" }}>
            {results.length > 0 && <GridHeader>{renderColumnHeaders()}</GridHeader>}
            <RowContainer>
              {loading
                ? Array.from({ length: pageLimit }, (_, index) => (
                    <ListSkeletonComponent
                      key={index}
                      numberOfSkeletonFields={orderedHeaders.length - 1}
                    />
                  ))
                : results.map((item) => (
                    <ListCardComponent
                      item={item}
                      setModalShow={setModalShow}
                      key={"listRow" + item._id}
                      onClick={onClick && (() => onClick(item))}
                      orderedHeaders={orderedHeaders}
                      {...props}
                    />
                  ))}
            </RowContainer>
          </div>
        </FlexColumn>
      )}
    </>
  );
};

export default CardList;
