import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { issueWhitelist } from "web3/sui";
import { renderFormV2 } from "utils/form";
import { updateLaunchpad } from "utils/api";
import { useState } from "react";
import { useEffect } from "react";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";

import { ExampleWhitelist } from "assets/fake-data/ExampleWhitelist";
import { ipfsConvert, downloadJSONFile } from "utils/formats";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AddFundsTransak from "components/AddFundsTransak";
import { tryAgain } from "utils/performance";

const formName = "batch-create-nft";

const validate = (/* values , dispatch */) => {
  const errors = {};
  return errors;
};

const BatchWhitelistModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item, onHide, onBatchCreate, handleSubmit, pristine, submitting } = props;
  const { index, tier, launchpad } = item;
  const form = useSelector((state) => state.form[formName]);
  const values = form?.values || {};
  const registeredFields = form?.registeredFields || {};
  const [json, setJson] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchSize, setBatchSize] = useState(searchParams.get("batchSize") || 60);
  const [next, setNext] = useState(searchParams.get("start") || 0);
  const tierIndex = launchpad?.sales?.findIndex((salesTier) => {
    return tier.object_id == salesTier.object_id;
  });
  const batchTierIndex = searchParams.get("currentTier") ?? tierIndex;
  const [batchType, setBatchType] = useState(searchParams.get("type") || "whitelist");

  useEffect(() => {
    const oldValues = Object.keys(values).filter(
      (key) => !Object.keys(registeredFields).includes(key)
    );
    oldValues.forEach((field) => {
      dispatch(change(formName, field, undefined));
    });
  }, [registeredFields]);

  const onJsonLoad = (file) => {
    if (batchTierIndex != tierIndex || batchType !== "whitelist") {
      setSearchParams({});
      setNext(0);
    }
    // do stuff with the file.
    let read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = function () {
      let cleanJson = JSON.parse(read.result);
      if (isArray(cleanJson)) {
        setJson(cleanJson);
      } else {
        let keys = Object.keys(cleanJson);
        if (keys.length == 1) {
          setJson(cleanJson[keys[0]]);
        }
      }
    };
  };

  const submit = async () => {
    mintBatch(next, next + batchSize);
  };

  const mintBatch = async (start, end) => {
    if (batchTierIndex != tierIndex || batchType !== "whitelist") setSearchParams({});
    const txn = await issueWhitelist(launchpad, index, json.slice(start, end));
    if (txn.status === "success") {
      const remainder = json.length % batchSize;
      const addedWhitelists = next + batchSize > json.length ? remainder : batchSize;
      const updatedSalesWhitelist = launchpad?.sales?.map((sale) => ({
        ...sale,
        whitelisted_count: sale.whitelisted_count + addedWhitelists,
      }));
      const updatedLaunchpad = await tryAgain(
        () => updateLaunchpad(launchpad._id, { sales: updatedSalesWhitelist }),
        5
      );
      if (updatedLaunchpad?.data) {
        launchpad.sales = updatedSalesWhitelist;
        //ToastPopup("Whitelist count increased!.", "success");
      }

      await sleep(2000);
      setNext(start + batchSize);
      searchParams.set("start", start + batchSize);
      searchParams.set("batchSize", batchSize);
      searchParams.set("currentTier", tierIndex);
      searchParams.set("type", "whitelist");
      setSearchParams(searchParams);
    } else {
      ToastPopup("An unknown error occured", "error");
    }
  };

  useEffect(() => {
    if (next > 0 && batchType === "whitelist") {
      if (!json || json.length == 0) {
        if (batchTierIndex == tierIndex) {
          ToastPopup(
            "Please re-upload your Whitelist JSON file continue where you left off.",
            "warning"
          );
        }
      } else {
        if (next >= json.length) {
          ToastPopup("Tickets minted successfully!", "success");
          setSearchParams({});
          onBatchCreate();
        } else {
          mintBatch(next, next + batchSize);
        }
      }
    }
  }, [next, json]);

  const handleCopy = () => {
    ToastPopup("Copied info to clipboard!", "success");
  };

  const ReloadBatch = () => {
    navigate(0);
  };

  return (
    <Modal size="sm" show={tier} onHide={onHide}>
      <Modal.Header closeButton />
      <div className="modal-body space-y-10 pd-40 align-items-center">
        <h2 style={{ marginBottom: "4rem" }}>
          Whitelist for {tier?.title || `Tier #${index + 1}`}
        </h2>
        <div className="row">
          <div className="col-xl-12 col-lg-6 col-md-9 col-12">
            <h4 className="title-list-item">Batch Whitelist Progress</h4>
            <div className="sc-card-product">
              <div className="meta-info pt-5 pl-2">
                <p>Up next: #{Math.min(next + 1, json.length + 1)}</p>
                <p>
                  Minted: {Math.min(next, json.length)}/{json.length}
                </p>
              </div>
              <div className="card-bottom"></div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-6 col-md-9 col-12">
            <h4>Example JSON:</h4>
            <pre className="pre-code-block">
              {ExampleWhitelist}
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  display: "flex",
                  gap: "1.5rem",
                  fontSize: "24px",
                }}
              >
                <CopyToClipboard text={ExampleWhitelist} onCopy={() => handleCopy()}>
                  <i className="fal fa-clone" />
                </CopyToClipboard>
                <i
                  className="far fa-arrow-to-bottom"
                  onClick={() => downloadJSONFile(ExampleWhitelist, "example-whitelist")}
                />
              </div>
            </pre>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="flat-tabs tab-list-item">
              <form onSubmit={handleSubmit(submit)}>
                <Field
                  type="text"
                  name="tier.title"
                  title="Tier"
                  props={{
                    value: tier?.title || `Tier #${index}`,
                  }}
                  disabled
                  component={renderFormV2}
                />
                <Field
                  type="file"
                  name="json"
                  component={renderFormV2}
                  onChange={onJsonLoad}
                  className="hideInput"
                  containername="h-100"
                  labelClassName="h-100"
                  hint="JSON file"
                  hidename="true"
                  accept=".json"
                />
                <AddFundsTransak />
                <button
                  className="mt-md-4 fullWidth"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Update Whitelist
                </button>
                <button
                  className="mt-md-4 fullWidth"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={ReloadBatch}
                >
                  Refresh stuck batch
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: formName,
  validate,
})(BatchWhitelistModal);
