import React from "react";
import { FieldArray, Field } from "redux-form";
import { Accordion } from "react-bootstrap-accordion";
import { renderSetTags, renderFormV2 } from "utils/form";
import RoyaltiesButtonGroup from "components/layouts/RoyaltiesButtonGroup";
import StickyPreviewCollection from "components/layouts/StickyPreviewCollection";

const CreateGameDetails = () => {
  return (
    <div className="row">
      {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <StickyPreviewCollection />
      </div> */}
      <div className="col-xl-12 col-lg-6 col-md-12 col-12">
        <Field
          name="title"
          title="Game Title"
          type="text"
          placeholder="e.g. Sui 2048"
          component={renderFormV2}
          required
        />
        {/* <Field
          name="media_url"
          title="Media URL"
          type="text"
          component={renderFormV2}
          required
        /> */}
        <Field
          name="description"
          type="textarea"
          placeholder='e.g. "This is my game!"'
          component={renderFormV2}
          required
        />
        {/* <Accordion title="Tags" show={true}>
          <FieldArray
            name="tags"
            title="Tags"
            component={renderSetTags}
            className="pt-24"
          />
        </Accordion> */}
      </div>
    </div>
  );
};

export default CreateGameDetails;
