import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoWrapper = styled.div`
  width: 75%;
  height: 550px;
  border-radius: 2rem;
  border: 2px solid var(--primary-color7);
  overflow: hidden;
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 350px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
`;

const OverviewWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 0;
`;

const PlayButton = styled.button`
  font-size: 16px;
  border-radius: var(--border-radius-md);
  width: 100%;
`;

const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  border-radius: var(--border-radius-md);
  padding: 1.5rem;
  background-color: var(--primary-color);
`;

const DetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListLabel = styled.span`
  text-transform: uppercase !important;
  font-weight: 800;
  font-size: 12px;
  color: var(--primary-color9);
`;

const ListValue = styled.span`
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-color2);
`;

const DetailsCardLogo = styled.img`
  height: 50px;
  width: 50px;
  border-radius: var(--border-radius-md);
`;

const GameOverviewPanel = ({ title, game }) => {
  const {
    name,
    developer,
    platforms,
    genres,
    play_info,
    media_url,
    logo_image,
    game_url = null,
  } = game;
  const sidebarDetails = [
    { key: "Developer", value: developer },
    { key: "Platforms", value: platforms },
    { key: "Genres", value: genres },
    { key: "Play Info", value: play_info },
  ];
  return (
    <OverviewWrapper>
      <VideoWrapper>
        <ReactPlayer
          className="react-player"
          url={media_url}
          width="100%"
          height="100%"
          modestbranding={true}
          loop={true}
          controls={1}
        />
      </VideoWrapper>
      <SidebarWrapper>
        {game_url && (
          <a href={game_url} target="_blank" rel="noreferrer">
            <PlayButton>Play Now!</PlayButton>
          </a>
        )}
        <DetailsCard>
          <FlexRow>
            <DetailsCardLogo src={logo_image} />
            <h4>{name}</h4>
          </FlexRow>
          <DetailsList>
            {sidebarDetails.map((item, index) => (
              <ListItem>
                <ListLabel>{item.key}</ListLabel>
                <ListValue>
                  {typeof item.value === "object"
                    ? item.value.filter((a) => !!a).toString()
                    : item.value}
                </ListValue>
              </ListItem>
            ))}
          </DetailsList>
        </DetailsCard>
      </SidebarWrapper>
    </OverviewWrapper>
  );
};

export default GameOverviewPanel;
