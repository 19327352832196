import { devInspectTransaction } from "./sui";
import { makeTransactionBlock } from "./txnbuilder";
import get from "lodash/get";
import { IS_PROD, IS_TESTNET } from "utils/environments";
const SUI_NS_ADDRESS = IS_TESTNET
  ? "0xedc672fadedee348108618da7555f771d4fec8d3331779a8411ff8184aded726"
  : "0x6e0ddefc0ad98889c04bab9639e512c21766c5e6366f89e696956d9be6952871";
const PACKAGE_ADDRESS = IS_TESTNET
  ? "0x701b8ca1c40f11288a1ed2de0a9a2713e972524fbab748a7e6c137225361653f"
  : "0xd22b24490e0bae52676651b4f56660a5ff8022a2576e0089f79b3c88d44e08f0";
// const toHexString = (byteArray) =>
//   byteArray?.length > 0
//     ? Array.from(byteArray, (byte) => ("0" + (byte & 0xff).toString(16)).slice(-2)).join(
//         ""
//       )
//     : "";

const toString = (byteArray) =>
  byteArray?.length > 0
    ? new TextDecoder().decode(Buffer.from(byteArray.slice(3)).buffer)
    : "";

// const toFullAddress = (trimmedAddress) =>
//   trimmedAddress ? `0x${trimmedAddress.padStart(40, "0")}` : "";

export const getName = async (address) => {
  try {
    const txb = makeTransactionBlock(
      [
        {
          packageObjectId: PACKAGE_ADDRESS,
          module: "suins",
          function: "registry",
          typeArguments: [`${PACKAGE_ADDRESS}::registry::Registry`],
          arguments: [SUI_NS_ADDRESS],
          types: ["object"],
        },
        {
          packageObjectId: PACKAGE_ADDRESS,
          module: "registry",
          function: "reverse_lookup",
          typeArguments: [],
          arguments: [{ txIndex: 0, index: 0 }, address],
          types: ["object", "address"],
        },
      ],
      address
    );
    const name = toString(
      get(await devInspectTransaction(address, txb), "results.[1].returnValues[0][0]")
    );
    let formattedName = name.split("	").reverse().join(".");
    return formattedName;
  } catch (e) {
    return "";
  }
};
