import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OwnedNFTCard from "components/layouts/profile/OwnedNFTCard";
import NFTSkeleton from "components/layouts/NFTSkeleton";
import { getContents } from "redux/state/sui";
import { Link } from "react-router-dom";
import { ListGrid } from "utils/styled";

const MyOwnedNFTsList = ({
  setCount,
  setPages,
  setSellModalShow,
  setLoanModalShow,
  setNftDetailsModalShow,
}) => {
  const loading = useSelector((state) => state.search.loading);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sui = useSelector((state) => state.sui);
  const contents = sui.contents.data.filter((item) => item.display?.data);
  const connected = sui.connected;

  useEffect(() => {
    setPages(0);
  }, [user, connected]);

  useEffect(() => {
    setCount(`${contents.length}${sui.contents.hasNextPage ? "+" : ""}`);
  }, [contents]);

  const sellHandler = (nft) => {
    setSellModalShow(nft);
  };

  const loanHandler = (nft) => {
    setLoanModalShow(nft);
  };

  const nftDetailsHandler = (nft) => {
    setNftDetailsModalShow(nft);
  };

  const showMoreItems = () => {
    dispatch(getContents(sui.contents))
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <ListGrid>
        {loading
          ? Array.from({ length: 15 }, (_, index) => {
              return <NFTSkeleton key={index} />;
            })
          : contents.map((item) => (
              <OwnedNFTCard
                key={item.id}
                item={item}
                sellHandler={sellHandler}
                loanHandler={loanHandler}
                nftDetailsHandler={nftDetailsHandler}
              />
            ))}
      </ListGrid>
      {!loading && sui.contents.hasNextPage && (
        <Link
          to="#"
          id="load-more"
          className="sc-button loadmore fl-button pri-3"
          onClick={showMoreItems}
        >
          <span>Load More</span>
        </Link>
      )}
    </>
  );
};

export default MyOwnedNFTsList;
