import { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { findIndexByValue } from "utils/formats";
import { useTitle } from "components/utils/TitleProvider";
import { useDispatch } from "react-redux";
import { reset } from "redux-form";

import ListingsPanel from "components/layouts/explore/Panels/ListingsPanel";
import AuctionsPanel from "components/layouts/explore/Panels/AuctionsPanel";
import CollectionsPanel from "components/layouts/explore/Panels/CollectionsPanel";
import RentalsPanel from "components/layouts/explore/Panels/RentalsPanel";
import styled from "styled-components";
import { useSidebar } from "components/utils/SidebarProvider";
import {
  searchListings,
  searchAuctions,
  searchCollections,
  searchLoanListings,
} from "redux/state/search";
import { useRefreshSearch } from "components/utils/SearchProvider";

const FilterButton = styled.button`
  & {
    background-color: transparent;
    color: var(--primary-color2);
    border: none;
    border-radius: 0;
    padding: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;

    :focus,
    :hover {
      background: var(--hover-color);
      border: none;
    }
  }
`;

const FilterIcon = styled.i`
  font-size: 14px;
`;

const ExploreTabs = () => {
  const { showFilter, setShowFilter } = useSidebar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();

  const dataTabs = [
    {
      title: "Listings",
      link: "listings",
      panel: <ListingsPanel title="Listings" />,
      searchFunction: () => searchListings,
    },
    {
      title: "Auctions",
      link: "auctions",
      panel: <AuctionsPanel title="Auctions" />,
      searchFunction: () => searchAuctions,
    },
    {
      title: "Collections",
      link: "collections",
      panel: <CollectionsPanel title="Collections" />,
      searchFunction: () => searchCollections,
    },
    {
      title: "Rentals",
      link: "rentals",
      panel: <RentalsPanel title="Rentals" />,
      searchFunction: () => searchLoanListings,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    if (!link) navigate(`/explore/listings`, { replace: true });
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    dispatch(reset("sidebar-search"));
    navigate(`/explore/${link}`);
  };

  const handleFilter = () => {
    setShowFilter(true);
  };

  return (
    <div className={showFilter ? "col-box-83" : "fullWidth"}>
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {!showFilter && (
              <FilterButton key="closed-filter" onClick={() => handleFilter()}>
                <FilterIcon className="fas fa-filter" />
              </FilterButton>
            )}
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default ExploreTabs;
