import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import PageHeader from "components/layouts/PageHeader";
import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import { renderFormV2 } from "utils/form";
import { transferNFT } from "web3/sui";

const PageWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 20px;
`;

const ItemTransfer = ({ handleSubmit, ...props }) => {
  const onsubmit = async (values) => {
    console.log(values);
    await transferNFT(values.item, values.to);
  };

  return (
    <div>
      <Header />
      <PageHeader />
      <PageWrapper>
        <form onSubmit={handleSubmit(onsubmit)}>
          <Field
            name="item"
            type="text"
            required
            placeholder="Object Adddress"
            component={renderFormV2}
          />
          <Field
            name="to"
            required
            placeholder="Recipient"
            type="textarea"
            component={renderFormV2}
          />
          <button type="submit">Send!</button>
        </form>
      </PageWrapper>
      <Footer />
    </div>
  );
};

export default reduxForm({ form: "itemTransfer" })(ItemTransfer);
