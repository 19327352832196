import { useState } from "react";
import styled from "styled-components";
import { NFTMultiSelectBox } from "components/layouts/modal/NFTMultiSelectModal";
import { useSelector } from "react-redux";
import { mystToSui } from "utils/formats";
import SuiIcon from "assets/images/icon/Sui_Droplet_Logo_Blue_Small.png";

const SwapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  /* max-width: min-content; */
  text-align: center;
`;

const SwapWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
`;

const SwapTitle = styled.h2`
  font-size: 48px;
  font-weight: 800;
  position: relative;
  line-height: 44px;
  color: var(--primary-color2);
  text-align: center;
`;

const SubmitButton = styled.button`
  width: min-content;
`;

const NFTSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: var(--border-radius-md);
  padding: 1rem;
  gap: 1rem;
`;

const SuiInput = styled.input`
  width: 100%;
  padding-left: 55px !important;
  border-radius: var(--border-radius-md) !important;
`;

const InputWrapper = styled.div`
  height: min-content;
  width: 100%;
  position: relative;
`;

const InputIcon = styled.img`
  position: absolute;
  left: 15px;
  bottom: 0;
  height: 100%;
  width: 25px;
  object-fit: contain;
`;

const NFTSelectSwap = () => {
  const [offeredNfts, setOfferedNfts] = useState([]);
  const [requestedNfts, setRequestedNfts] = useState([]);
  const [offeredSui, setOfferedSui] = useState("");
  const [requestedSui, setRequestedSui] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const disabled = offeredNfts.length === 0 && requestedNfts.length === 0;
  const max = useSelector((state) => state.sui?.total);

  const parseOfferedSui = (value) => {
    if (!value) {
      setOfferedSui("");
      return;
    }

    if (value > mystToSui(max)) {
      setOfferedSui(mystToSui(max));
    } else {
      setOfferedSui(value);
    }
  };

  const parseRequestedSui = (value) => {
    if (!value) setRequestedSui("");
    setRequestedSui(value);
  };

  return (
    <section className="tf-section category">
      <div className="themesflat-container">
        <div className="justify-content-center fullWidth">
          <SwapContainer>
            <SwapTitle>P2P Swap</SwapTitle>
            <SwapWrapper>
              <NFTSelectBoxWrapper>
                <NFTMultiSelectBox
                  title="Your Offer"
                  titlePosition="top"
                  selectedNfts={offeredNfts}
                  setSelectedNfts={setOfferedNfts}
                  suiAmount={offeredSui}
                  structType={null}
                  searchAddress={null}
                  iconClass="fal fa-egg"
                  height={700}
                  width={700}
                />
                <InputWrapper>
                  <InputIcon src={SuiIcon} />
                  <SuiInput
                    type="text"
                    pattern="^[0-9]*[.]?[0-9]*$"
                    placeholder="Amount of SUI offered"
                    name="offeredSui"
                    value={offeredSui}
                    onChange={(event) => {
                      event.currentTarget.validity.valid &&
                        parseOfferedSui(event.target.value);
                    }}
                  />
                </InputWrapper>
              </NFTSelectBoxWrapper>
              <NFTSelectBoxWrapper>
                <NFTMultiSelectBox
                  title="Requested NFT(s)"
                  titlePosition="top"
                  selectedNfts={requestedNfts}
                  setSelectedNfts={setRequestedNfts}
                  suiAmount={requestedSui}
                  structType={null}
                  searchAddress={searchAddress}
                  setSearchAddress={setSearchAddress}
                  iconClass="fal fa-dryer"
                  height={700}
                  width={700}
                />
                <InputWrapper>
                  <InputIcon src={SuiIcon} />
                  <SuiInput
                    type="text"
                    pattern="^[0-9]*[.]?[0-9]*$"
                    placeholder="Amount of SUI requested"
                    name="requestedSui"
                    value={requestedSui}
                    onChange={(event) => {
                      event.currentTarget.validity.valid &&
                        parseRequestedSui(event.target.value);
                    }}
                  />
                </InputWrapper>
              </NFTSelectBoxWrapper>
            </SwapWrapper>

            <SubmitButton disabled={disabled}>Submit</SubmitButton>
          </SwapContainer>
        </div>
      </div>
    </section>
  );
};

export default NFTSelectSwap;
