const Fees = ({ price, buying = false }) => {
  const fee = 0.025;
  const total = price - price * fee;

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p>Marketplace Fee: {fee * 100}%</p>
        <p className="text-right price color-popup">{price * fee}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p>{buying ? "Seller recieves" : "You recieve:"}</p>
        <p className="text-right price color-popup">{total}</p>
      </div>
    </div>
  );
};

export default Fees;
