import { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import ToastPopup from "components/utils/ToastPopup";
import WizardForm from "components/layouts/WizardForm";
import CreateCollectionImages from "./CreateCollectionImages";
import CreateCollectionDetails from "./CreateCollectionDetails";
import CreateCollectionSocials from "./CreateCollectionSocials";
import {
  createCollection as uploadCollection,
  searchCollections,
  uploadCollectionImage,
  logCollection,
  getCollectionLog,
  updateCollection,
  addOriginByeSupport,
} from "utils/api";
import {
  publish,
  createCollection,
  getTransaction,
  getObjectsInfo,
  getObjectByType,
  getEvents,
  enableOriginByte,
} from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import CreateCollectionMetadata from "./CreateCollectionMetadata";
import { IS_PROD } from "utils/environments";
import { initFormVals } from "redux/state/initialValues";

export const formName = "create-collection";

const validate = (values /*, dispatch */) => {
  const errors = {};
  const MAX_FILE_SIZE = 10 * 1024 * 1024; //10Mb

  if (values) {
    if (!values.logo_img && IS_PROD) {
      errors.logo_img = "Required";
    }
    if (!values.featured_img && IS_PROD) {
      errors.featured_img = "Required";
    }
    if (values.logo_img?.size > MAX_FILE_SIZE) {
      errors.logo_img = "Max file size is 10MB. Please upload a smaller file.";
    }
    if (values.featured_img?.size > MAX_FILE_SIZE) {
      errors.featured_img = "Max file size is 10MB. Please upload a smaller file.";
    }
  }
  return errors;
};

const CreateCollectionForm = (props) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleSubmit, pristine, submitting, invalid } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const values = form?.values || {};
  const [loadingCollection, setLoadingCollection] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [steps, setSteps] = useState([]);
  const [forcePage, setForcePage] = useState(0);
  const settings = useSelector((state) => state.settings);

  const fetchExistingRecord = async () => {
    let existing = searchParams.get("logId");
    if (existing) {
      let record = await getCollectionLog(existing);
      if (record.data.creation_tx) {
        let txInfo = await getTransaction(record.data.creation_tx);
        dispatch(initFormVals(formName, record.data));
        setTxInfo(txInfo);
        setForcePage(3);
      }
    }
  };

  const setTxInfo = async (txInfo) => {
    const created = txInfo.effects.created;
    let packageObjectId = created.find((i) => i.owner == "Immutable").reference.objectId;
    setSteps([
      {
        packageObjectId,
        creation_tx: txInfo.effects.transactionDigest,
      },
    ]);
  };

  useEffect(() => {
    if (settings.packages) {
      fetchExistingRecord();
    }
  }, [settings]);

  const onSubmit = async (values) => {
    setLoadingCollection(true);
    if (steps.length < 1) {
      // check if collection can be created first
      const nameTaken = await searchCollections({ name: values.name }).then(
        (res) => res.data.results[0]
      );
      if (nameTaken) {
        setLoadingCollection(false);
        throw new SubmissionError({
          name: "Name taken",
          _error: "A collection with that name already exists",
        });
      }
      try {
        const res = await publish();
        const created = res.effects.created;
        let packageObjectId = created.find((i) => i.owner == "Immutable").reference
          .objectId;
        let logId = await logCollection({
          packageObjectId,
          creation_tx: res.effects.transactionDigest,
          royalties: values.royalties || "500",
          ...values,
        });

        setSearchParams({ logId: logId.data });
        if (res?.status === "success") {
          await sleep(10000);
          setTxInfo(res);
        } else {
          // transaction failed
          setLoadingCollection(false);
        }
      } catch (e) {
        setLoadingCollection(false);
        console.log(e);
        throw new SubmissionError({
          _error: "Transaction failed, or was cancelled",
        });
      }
    } else {
      createCollectionObject();
    }
  };

  const createCollectionObject = async () => {
    let { packageObjectId, creation_tx } = steps[0];

    let { name, description, royalties, tags, standard } = values;

    const filteredTags = tags
      ?.filter((a) => a)
      .map((tagId) => settings.tags.findIndex((tagObject) => tagId === tagObject._id));
    let events = await getEvents({
      MoveEventType: `${settings.packages.keepsake}::keepsake_royalties::StrategyCreated<${packageObjectId}::keepsake_nft::KEEPSAKE>`,
    });

    let tx = false;
    if (events.data.length > 0) {
      tx = events.data[0].id.txDigest;
    } else {
      let createTx = await createCollection(
        packageObjectId,
        name,
        description,
        filteredTags,
        royalties,
        creation_tx,
        standard
      );
      await sleep();
      tx = createTx.effects.transactionDigest;
    }
    let logId = searchParams.get("logId");
    let newCollection = await tryAgain(
      () =>
        uploadCollection({
          logId,
          tx,
        }),
      3
    );

    if (newCollection) {
      const id = newCollection.data.collection._id;
      const token = newCollection.data.accessToken;
      const images = {};
      if (values.featured_img) {
        await uploadCollectionImage(token, id, values.featured_img, "featured")
          .then((res) => (images.featured_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Collection Banner.", "error");
          });
      }
      if (values.logo_img) {
        await uploadCollectionImage(token, id, values.logo_img, "logo")
          .then((res) => (images.logo_image = res))
          .catch(() => {
            ToastPopup("An error occurred uploading Collection Logo.", "error");
          });
      }
      if (images) await updateCollection(id, images);
      navigate(`/profile/collections`);
    }
    setLoadingCollection(false);
  };

  useEffect(() => {
    if (steps.length >= 1 && Object.keys(values).length > 0) {
      try {
        createCollectionObject();
      } catch (error) {
        setLoadingCollection(false);
      }
    }
  }, [steps, Object.keys(values).length]);

  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <div className="flat-tabs tab-list-item">
                  <WizardForm
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    pristine={false}
                    submitting={loadingCollection}
                    invalid={invalid}
                    forcePage={forcePage}
                    submitText="Create Collection"
                  >
                    <CreateCollectionImages
                      title="Uploads"
                      logoImage={logoImage}
                      setLogoImage={setLogoImage}
                      featuredImage={featuredImage}
                      setFeaturedImage={setFeaturedImage}
                    />
                    <CreateCollectionDetails title="Details" />
                    <CreateCollectionMetadata title="Metadata" />
                    <CreateCollectionSocials title="Socials" />
                  </WizardForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: { ...{ standard: "mysten" }, ...state.initialValues[formName] }, // pull initial values from account reducer
}))(
  reduxForm({ form: formName, enableReinitialize: true, validate })(CreateCollectionForm)
);
