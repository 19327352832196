import GameCollectionsLeaderboard from "pages/games/GameCollectionsLeaderboard";
import React, { useState, useEffect } from "react";

const GameLeaderboardPanel = ({ title, game }) => {
  return (
    <div>
      <GameCollectionsLeaderboard title="Collections Leaderboard" game_id={game?._id} />
    </div>
  );
};

export default GameLeaderboardPanel;
