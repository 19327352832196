const menus = [
  {
    name: "Explore",
    links: "/explore",
  },
  {
    name: "Games",
    links: "/games",
  },
  {
    name: "Leaderboard",
    links: "/leaderboard",
  },
  {
    name: "Mintpads",
    links: "/mintpad",
  },
  {
    name: "Create",
    links: "/create",
  },
];

export default menus;
