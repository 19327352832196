import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui.js/utils";
import { SuiAddress, SUI, getUserAddress, currentSettings } from "web3/sui";

export const listNonstandard = (nft, collection, realPrice) => {
  let settings = currentSettings();
  return {
    target: `${settings.packages.keepsake}::keepsake_marketplace::list`,
    typeArguments: [nft.type],
    arguments: [
      settings.market.marketplace,
      settings.market.kiosk,
      collection.transfer_policy,
      nft.id,
      realPrice.toString(),
    ],
    types: ["object", "object", "object", "object", "number"],
  };
};

export const buyNonstandard = (listing, price) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();
  let royaltyCost = 0;
  listing.nft_collection.royalties.forEach((royalty) => {
    royaltyCost += Math.ceil(Math.ceil(royalty.amount * price) / 10000);
  });

  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [price, Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 1 }],
      types: ["object"],
    },
    {
      target: `${settings.packages.keepsake}::keepsake_marketplace::buy`,
      typeArguments: [listing.object_type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        listing.nft_object_id,
        { txIndex: 0, index: 0 },
      ],
      types: ["object", "object", "address", "object"],
    },
  ];
  listing.nft_collection.royalties?.forEach((royalty) => {
    txns.push({
      target: `${royalty.package_id}::${
        royalty.type.split("::")[0]
      }::confirm_transfer_with_balance`,
      typeArguments: royalty.specifyType
        ? [listing.object_type, SUI]
        : [listing.object_type],
      arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
      types: ["object", "object", "object"],
    });
  });
  txns.push({
    target: `${settings.packages.keepsake}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [listing.nft_collection.transfer_policy, { txIndex: 2, index: 0 }],
    types: ["object", "object"],
  });
  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });
  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });
  return txns;
};

export const auctionNonstandard = (
  marketInfo,
  nft,
  collection,
  min_price,
  min_bid_increment,
  starts,
  expires
) => {
  let settings = currentSettings();
  let txns = [
    {
      type: "splitCoins",
      amounts: [marketInfo.data.collateral_fee],
    },
    {
      target: `${settings.packages.keepsake}::keepsake_marketplace::auction`,
      typeArguments: [nft.type],
      arguments: [
        settings.market.marketplace,
        settings.market.kiosk,
        collection.transfer_policy,
        nft.id,
        min_price,
        min_bid_increment,
        starts,
        expires,
        { txIndex: 0, index: 0 },
      ],
      types: [
        "object",
        "object",
        "object",
        "object",
        "number",
        "number",
        "number",
        "number",
        "object",
      ],
    },
  ];

  return txns;
};

export const winNonstandardAuction = (listing) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();
  let royaltyCost = 0;

  listing.nft_collection.royalties.forEach((royalty) => {
    royaltyCost += Math.ceil(Math.ceil(royalty.amount * listing.sale_price) / 10000);
  });
  let txns = [
    {
      type: "splitCoins",
      // coin: coin.id,
      amounts: [Math.ceil(royaltyCost * 1.001)],
    },
    {
      target: `${SuiAddress}::coin::into_balance`,
      typeArguments: [SUI],
      arguments: [{ txIndex: 0, index: 0 }],
      types: ["object"],
    },
  ];

  txns.push({
    target: `${settings.packages.keepsake}::keepsake_marketplace::complete_auction`,
    typeArguments: [listing.object_type],
    arguments: [
      settings.market.marketplace,
      settings.market.kiosk,
      listing.nft_object_id,
      SUI_CLOCK_OBJECT_ID,
    ],
    types: ["object", "object", "address", "object"],
  });
  if (listing.nft_collection.royalties) {
    listing.nft_collection.royalties.forEach((royalty) => {
      txns.push({
        target: `${royalty.package_id}::${
          royalty.type.split("::")[0]
        }::confirm_transfer_with_balance`,
        typeArguments: royalty.specifyType
          ? [listing.object_type, SUI]
          : [listing.object_type],
        arguments: [royalty.id, { txIndex: 2, index: 0 }, { txIndex: 1, index: 0 }],
        types: ["object", "object", "object"],
      });
    });
  }

  txns.push({
    target: `${settings.packages.keepsake}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [listing.nft_collection.transfer_policy, { txIndex: 2, index: 0 }],
    types: ["object", "object"],
  });

  txns.push({
    target: `${SuiAddress}::coin::from_balance`,
    typeArguments: [SUI],
    arguments: [{ txIndex: 1, index: 0 }],
    types: ["object"],
  });

  txns.push({
    type: "transferObjects",
    object: { txIndex: txns.length - 1, index: 0 },
    to: userAddress,
  });
};
