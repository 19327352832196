import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchLoading, searchGameCollections } from "redux/state/search";
import CardList from "components/layouts/explore/Lists/CardList";
import CollectionCard from "components/layouts/collections/CollectionCard";
import CollectionSkeleton from "components/layouts/CollectionSkeleton";

const GameCollectionsPanel = ({ title, game }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const reduxState = useSelector((state) => state.search.collections);

  useEffect(() => {
    dispatch(searchGameCollections(game._id))
      .then(() => {
        dispatch(searchLoading(false));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div style={{ marginTop: "2rem" }}>
      <CardList
        title={title}
        setCount={setCount}
        setPages={setPages}
        SkeletonComponent={CollectionSkeleton}
        CardComponent={CollectionCard}
        reduxState={reduxState}
        noResultsCustomTitle="Just around the corner..."
        noResultsCustomSubtitle="Stay tuned to discover new collections!"
      />
    </div>
  );
};

export default GameCollectionsPanel;
