import styled from "styled-components";
import { renderToggle } from "utils/form";
import { Field } from "redux-form";

const AlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  border-radius: 0.75rem;
  padding: 2rem;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const AddFundsText = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const EnableAlerts = ({ alertList }) => {
  return (
    <AlertsWrapper>
      {alertList.map((alert) => {
        return (
          <div className="inline-field justify-content-between fullWidth align-items-center">
            <AddFundsText>{alert.title}</AddFundsText>
            <Field
              name={alert.fieldname}
              defaultChecked={true}
              component={renderToggle}
            />
          </div>
        );
      })}
    </AlertsWrapper>
  );
};

export default EnableAlerts;
