import { Modal } from "react-bootstrap";
import KioskRows from "../KioskRows";

const KioskProfitsModal = ({ show, onHide }) => {
  return (
    <Modal show={show !== false} size="lg" onHide={onHide}>
      <Modal.Header closeButton />
      <div className="modal-body space-y-10 pd-40 align-items-center">
        <KioskRows title="Withdraw SUI from Kiosks" />
      </div>
    </Modal>
  );
};

export default KioskProfitsModal;
