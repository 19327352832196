import { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import { NotifiCard } from "components/notifi/NotiifiCard";
import Trigger from "components/NotificationCenter/Trigger";

const variants = {
  container: {
    open: {
      y: 0,
      opacity: 1,
      pointerEvents: "auto",
    },
    closed: {
      y: -10,
      opacity: 0,
      pointerEvents: "none",
    },
  },
};

const OuterWrapper = styled.div`
  position: relative;
`;

const Container = styled(motion.div)`
  width: min(60ch, 100ch);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: -50px;
`;

export const NotifiModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OuterWrapper>
      <Trigger onClick={() => setIsOpen(!isOpen)} />
      <Container
        initial={false}
        variants={variants.container}
        animate={isOpen ? "open" : "closed"}
      >
        <NotifiCard />
      </Container>
    </OuterWrapper>
  );
};
