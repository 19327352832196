import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { findIndexByValue } from "utils/formats";
import { useTitle } from "components/utils/TitleProvider";
import { getContents } from "redux/state/sui";
import MyOwnedNFTsPanel from "components/layouts/profile/Panels/MyOwnedNFTsPanel";
import MyCoinsPanel from "components/layouts/profile/Panels/MyCoinsPanel";
import MyKiosksPanel from "./profile/Panels/MyKiosksPanel";
import MyOBKiosksPanel from "./profile/Panels/MyOBKiosksPanel";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSelector } from "react-redux";
import { useProfileMenu } from "components/utils/ProfileMenuProvider";

const MyProfileInventory = () => {
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const { menuIndex, prevMenuIndex, menuData } = useProfileMenu();
  const sui = useSelector((state) => state.sui);
  const getSuiContents = () => getContents(sui.contents);
  const parentLink = "profile";

  const dataTabs = [
    {
      title: "Owned NFTs",
      link: "owned-nfts",
      panel: <MyOwnedNFTsPanel title="Owned NFTs" />,
      searchFunction: () => getSuiContents,
    },
    {
      title: "Kiosk NFTs",
      link: "kiosks",
      panel: <MyKiosksPanel title="Kiosks" />,
      searchFunction: () => {},
    },
    {
      title: "OB Kiosk NFTs",
      link: "ob-kiosks",
      panel: <MyOBKiosksPanel title="Originbyte Kiosks" />,
      searchFunction: () => {},
    },
    {
      title: "Coins",
      link: "coins",
      panel: <MyCoinsPanel title="Coins" />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    if (menuIndex !== prevMenuIndex && prevMenuIndex !== null) {
      navigate(`/${parentLink}/${menuData[menuIndex].section}/${dataTabs[0].link}`);
    }
  }, [menuIndex]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/${parentLink}/${menuData[menuIndex].section}/${link}`);
  };

  return (
    <section className="tf-section tf-new-profile">
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

export default MyProfileInventory;
