import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { findIndexByValue } from "utils/formats";
import { useTitle } from "components/utils/TitleProvider";
import {
  searchListings,
  searchAuctions,
  searchCollections,
  searchLaunchpads,
  searchRentals,
  searchLendings,
} from "redux/state/search";

import CreatorListingsPanel from "components/layouts/creator/Panels/CreatorListingsPanel";
import CreatorAuctionsPanel from "components/layouts/creator/Panels/CreatorAuctionsPanel";
import CreatorCollectionsPanel from "components/layouts/creator/Panels/CreatorCollectionsPanel";
import CreatorLaunchpadsPanel from "components/layouts/creator/Panels/CreatorLaunchpadsPanel";
import CreatorRentalListingsPanel from "./creator/Panels/CreatorRentalListingsPanel";
import CreatorLoanedListingsPanel from "./creator/Panels/CreatorLoanedListingsPanel";
import { useRefreshSearch } from "components/utils/SearchProvider";

const CreatorTabs = ({ creator }) => {
  const dataTabs = [
    {
      title: "Listings",
      link: "listings",
      panel: <CreatorListingsPanel title="Listings" />,
      searchFunction: () => searchListings,
    },
    {
      title: "Auctions",
      link: "auctions",
      panel: <CreatorAuctionsPanel title="Auctions" />,
      searchFunction: () => searchAuctions,
    },
    {
      title: "Collections",
      link: "collections",
      panel: <CreatorCollectionsPanel title="Collections" />,
      searchFunction: () => searchCollections,
    },
    {
      title: "Mintpads",
      link: "mintpads",
      panel: <CreatorLaunchpadsPanel title="Mintpads" />,
      searchFunction: () => searchLaunchpads,
    },
    {
      title: "Rented NFTs",
      link: "rented",
      panel: <CreatorRentalListingsPanel title="Rented NFTs" />,
      searchFunction: () => searchRentals,
    },
    {
      title: "Loaned NFTs",
      link: "loaned",
      panel: <CreatorLoanedListingsPanel title="Loaned NFTs" />,
      searchFunction: () => searchLendings,
    },
  ];

  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/creators/${creator._id}/${link}`);
  };

  return (
    <div className="flat-tabs items">
      <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
        <TabList>
          {dataTabs.map((tab, index) => (
            <Tab key={index} disabled={tab.disabled}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        {dataTabs.map(({ panel }, index) => (
          <TabPanel key={index}>{panel}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default CreatorTabs;
