import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { getObjectsByStructType } from "redux/state/sui";
import { ipfsConvert, numberShortFormat } from "utils/formats";
import SuiIcon from "assets/images/icon/Sui_Droplet_Logo_Blue_Small.png";
import { isValidSuiAddress, normalizeSuiAddress } from "@mysten/sui.js/utils";

const StatsInner = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: 1rem;
  padding: 2rem;
  gap: 2rem;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const NFTContainerWrapper = styled(StatsInner)`
  padding: 0;
  overflow: hidden;
  width: ${({ width }) => (typeof width == "number" ? `${width}px` : width)};
  height: ${({ height }) => (typeof height == "number" ? `${height}px` : height)};
  padding: 1rem;
  cursor: pointer;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  background: var(--bg-section3);
  transition: all 0.3s;

  &:hover {
    background-color: var(--primary-color7);
  }
`;

const NFTSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  width: 100%;
`;

const NFTOptionContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.3s all;
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
  aspect-ratio: 1;
  border: ${({ selected }) => (selected ? `4px solid var(--primary-color3)` : `none`)};

  img,
  video {
    border-radius: 0px;
    height: 250px;
    width: 250px;
  }

  span {
    font-size: 28px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    z-index: 1;
    transition: 0.2s all;
  }

  ::after {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.2s all;
  }

  &:hover {
    ::after {
      box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0.3);
    }

    span {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

const SuiTokenBox = styled.img`
  height: 75% !important;
  width: 100% !important;
  object-fit: contain;
`;

const SuiAmountInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SuiInput = styled.input`
  width: 100%;
  border-radius: var(--border-radius-md) !important;
`;

const SuiInputButton = styled.button`
  /* background-color: ${({ activeWallet }) =>
    activeWallet ? `red !important` : `green !important`}; */
`;

export const NFTMultiSelectBox = ({
  selectedNfts,
  setSelectedNfts,
  suiAmount,
  title,
  titlePosition = "bottom",
  structType,
  iconClass,
  searchAddress,
  setSearchAddress,
  height = 250,
  width = 250,
}) => {
  const [nfts, setNfts] = useState([]);
  const [nftSelectModalShow, setNftSelectModalShow] = useState(false);

  const refreshSearch = (resetSearch = false) => {
    if (resetSearch) {
      setNfts([]);
      setSelectedNfts([]);
      setSearchAddress("");
      return;
    }
    getObjectsByStructType(structType, searchAddress).then((res) => {
      const filteredRes = res.filter((nft) => !!nft?.display?.data?.image_url);
      setNfts(filteredRes);
    });
  };

  useEffect(() => {
    if (!searchAddress && !setSearchAddress) {
      refreshSearch();
    }
  }, []);

  const NFTOption = ({ nft, disableEvents, highlightSelected }) => {
    const [nftOption, setNftOption] = useState(nft);
    if (typeof nftOption === "string") {
      setNftOption(nfts.find((nftId) => nftId.id === nft));
    }
    const selected = selectedNfts.includes(nftOption.id);

    const handleSelectNft = (selectedNft) => {
      return setSelectedNfts((prevSelectedNfts) => {
        return prevSelectedNfts.includes(selectedNft.id)
          ? prevSelectedNfts.filter((prevSelected) => prevSelected !== selectedNft.id)
          : [...prevSelectedNfts, selectedNft.id];
      });
    };

    const nftImage =
      ipfsConvert(nftOption.display?.data?.image_url) ||
      ipfsConvert(nftOption.display?.data?.thumb_url);

    return (
      <NFTOptionContainer
        onClick={() => {
          !disableEvents && handleSelectNft(nftOption);
        }}
        selected={highlightSelected && selected}
      >
        {nftOption?.data?.name && (
          <span className="text-center">{nftOption.data?.name}</span>
        )}
        {nftImage && nftImage?.split(".").pop() === "mp4" ? (
          <video style={{ zIndex: 1 }} autoPlay muted loop>
            <source src={nftImage} type="video/mp4" />
          </video>
        ) : (
          <img src={nftImage} />
        )}
      </NFTOptionContainer>
    );
  };

  const SuiAmountBox = ({ amount }) => {
    return (
      <NFTOptionContainer>
        <SuiAmountInner>
          <SuiTokenBox src={SuiIcon} />
          <h3>{numberShortFormat(amount)} SUI</h3>
        </SuiAmountInner>
      </NFTOptionContainer>
    );
  };

  return (
    <div className="flex flex-column gap-1">
      {titlePosition == "top" && <h3>{title}</h3>}
      <NFTContainerWrapper
        height={height}
        width={width}
        onClick={() => setNftSelectModalShow(true)}
      >
        <NFTSelectWrapper>
          {suiAmount > 0 && <SuiAmountBox amount={suiAmount} />}
          {selectedNfts.map((nft) => (
            <NFTOption nft={nft} key={`select-box-` + nft} disableEvents />
          ))}
        </NFTSelectWrapper>
      </NFTContainerWrapper>
      {titlePosition == "bottom" && <h3>{title}</h3>}
      <NFTMultiSelectModal
        itemsList={nfts}
        modalShow={nftSelectModalShow}
        onHide={() => setNftSelectModalShow(false)}
        title={title}
        selectedNfts={selectedNfts}
        setSelectedNfts={setSelectedNfts}
        NFTOption={NFTOption}
        searchAddress={searchAddress}
        setSearchAddress={setSearchAddress}
        refreshSearch={refreshSearch}
      />
    </div>
  );
};

const NFTMultiSelectModal = ({
  itemsList,
  modalShow,
  onHide,
  title,
  selectedNfts,
  setSelectedNfts,
  NFTOption,
  setSearchAddress,
  searchAddress,
  refreshSearch,
}) => {
  if (!modalShow) {
    return false;
  }

  const buttonDisabled =
    !searchAddress ||
    searchAddress.length < 32 ||
    !isValidSuiAddress(normalizeSuiAddress(searchAddress));
  const textDisabled = itemsList.length > 0;
  const inputButtonText = textDisabled ? "Clear" : "Search";
  const resetSearch = itemsList.length > 0;

  return (
    <Modal show={modalShow} onHide={onHide} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <h2>{title}</h2>
        {setSearchAddress && (
          <div className="inline-field">
            <SuiInput
              type="text"
              disabled={textDisabled}
              value={searchAddress}
              onChange={(event) => setSearchAddress(event.target.value)}
            />
            <SuiInputButton
              type="button"
              onClick={() => refreshSearch(resetSearch)}
              disabled={buttonDisabled}
              activeWallet={itemsList.length > 0}
            >
              {inputButtonText}
            </SuiInputButton>
          </div>
        )}
        <div className="list-item">
          <NFTSelectWrapper>
            {itemsList.map((nft) => (
              <NFTOption
                nft={nft}
                key={`modal-box-` + nft?.id}
                setSelectedNfts={setSelectedNfts}
                highlightSelected
              />
            ))}
          </NFTSelectWrapper>
        </div>
        <div className="inline-field justify-content-center">
          <button onClick={onHide}>
            Confirm {selectedNfts.length > 0 && `(${selectedNfts.length} NFTs selected)`}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NFTMultiSelectModal;
