import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import NFTSelectSwap from "components/layouts/NFTSelectSwap";
import PageHeader from "components/layouts/PageHeader";

const P2PSwap = () => {
  return (
    <div className="home-6">
      <Header />
      <PageHeader />
      <NFTSelectSwap />
      <Footer />
    </div>
  );
};

export default P2PSwap;
