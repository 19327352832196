import { Link, useSearchParams } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { Field, reduxForm, change } from "redux-form";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { mintNFTs } from "../../web3/sui";
import { renderFormV2 } from "utils/form";
import { getMyCollections, getMyLaunchpads } from "utils/api";
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ipfsConvert, downloadJSONFile } from "utils/formats";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import { tryAgain } from "utils/performance";
import { ExampleNFT } from "assets/fake-data/ExampleNFT";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isArray } from "lodash";

const formName = "batch-create-nft";

const validate = (/* values , dispatch */) => {
  const errors = {};
  // if (!values.collection) {
  //   errors.collection = "Please select a collection";
  // }
  return errors;
};

const BatchCreateItem = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const values = form?.values || {};
  const registeredFields = form?.registeredFields || {};
  const [collections, setCollections] = useState([]);
  const [launchpads, setLaunchpads] = useState([]);
  const collection = collections[values.collection];
  const launchpad = launchpads[values.launchpad];
  const [tab, setTab] = useState(location?.state?.tab ?? 0);
  const [json, setJson] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchSize, setBatchSize] = useState(searchParams.get("batchSize") || 20);
  const [next, setNext] = useState(searchParams.get("start") || 0);
  const collectionLaunchpads = launchpads.filter(
    (pad) => pad.launchpad_collection?._id === collection?._id
  );

  useEffect(() => {
    const oldValues = Object.keys(values).filter(
      (key) => !Object.keys(registeredFields).includes(key)
    );
    oldValues.forEach((field) => {
      dispatch(change(formName, field, undefined));
    });
  }, [registeredFields]);

  useEffect(() => {
    if (location?.state?.collection_id) {
      let selected = collections.findIndex(
        (col) => col._id === location.state.collection_id
      );
      dispatch(change(formName, "collection", selected));
    }
    if (location?.state?.launchpad_id) {
      let selected = launchpads.findIndex(
        (col) => col._id === location.state.launchpad_id
      );
      dispatch(change(formName, "launchpad", selected));
    }
  }, [collections, launchpads]);

  const fetchCollections = async () => {
    const req = await getMyCollections();
    setCollections(req.data.results);
  };

  const fetchLaunchpads = async () => {
    const req = await getMyLaunchpads();
    setLaunchpads(req.data.results);
  };

  useEffect(() => {
    if (user._id) {
      fetchCollections();
      fetchLaunchpads();
    }
  }, [user]);

  const onJsonLoad = (file) => {
    // do stuff with the file.
    let read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = function () {
      let cleanJson = JSON.parse(read.result);
      if (isArray(cleanJson)) {
        setJson(cleanJson);
      } else {
        let keys = Object.keys(cleanJson);
        if (keys.length == 1) {
          setJson(cleanJson[keys[0]]);
        }
      }
    };
  };

  const submit = async () => {
    mintBatch(next, next + batchSize);
  };

  const mintBatch = async (start, end) => {
    const launchpad_info = tab === 0 ? launchpad : false;
    const type = tab === 0 ? "mint_to" : "launchpad";
    const txn = await mintNFTs(
      {
        collection,
        tier: values.tier,
        recipient: values.recipient,
        launchpad: launchpad_info,
      },
      json.slice(start, end),
      type
    );
    if (txn.status === "success") {
      await sleep(2000);
      setNext(start + batchSize);
      searchParams.set("start", start + batchSize);
      searchParams.set("batchSize", batchSize);
      setSearchParams(searchParams);
    } else {
      ToastPopup("An unknown error occured", "error");
    }
  };

  useEffect(() => {
    if (next > 0) {
      if (next > json.length) {
        ToastPopup("NFTs minted successfully!", "success");
      } else {
        mintBatch(next, next + batchSize);
      }
    }
  }, [next]);

  const handleCopy = () => {
    ToastPopup("Copied info to clipboard!", "success");
  };

  return (
    <div className="list-item">
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-6 col-md-9 col-12">
              <h4 className="title-list-item">Batch NFT Progress</h4>
              <div className="sc-card-product">
                <div className="card-media">
                  <img src={ipfsConvert(json[next] ? json[next].url : false)} />
                </div>
                <div className="meta-info">
                  <p>Up next: #{Math.min(next, json.length)}</p>
                  <p>
                    Minted: {Math.min(next, json.length)}/{json.length}
                  </p>
                </div>
                <div className="card-bottom"></div>
              </div>
              <h4>Example JSON:</h4>
              {/* mintType, tab*/}
              <pre className="pre-code-block">
                {ExampleNFT}
                <div
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    display: "flex",
                    gap: "1.5rem",
                    fontSize: "24px",
                  }}
                >
                  <CopyToClipboard text={ExampleNFT} onCopy={() => handleCopy()}>
                    <i className="fal fa-clone" />
                  </CopyToClipboard>
                  <i
                    className="far fa-arrow-to-bottom"
                    onClick={() => downloadJSONFile(ExampleNFT, "example-nfts")}
                  />
                </div>
              </pre>
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-12">
              <div className="form-list-item">
                <div className="flat-tabs tab-list-item">
                  <form onSubmit={handleSubmit(submit)}>
                    {/* <h4 className="title-list-item">Select method</h4> */}
                    <Tabs selectedIndex={tab} onSelect={setTab}>
                      <TabList className="react-tabs__tab-list two-tabs">
                        <Tab>
                          <span className="icon-fl-send"></span>Mint Only
                        </Tab>
                        <Tab>
                          <span className="icon-fl-tag"></span>Add to Mintpad
                        </Tab>
                      </TabList>
                      <Field
                        type="file"
                        name="json"
                        component={renderFormV2}
                        onChange={onJsonLoad}
                        className="hideInput"
                        containername="h-100"
                        labelClassName="h-100"
                        hint="JSON file"
                        hidename="true"
                        accept=".json"
                      />
                      <TabPanel>
                        <Field
                          type="select"
                          name="collection"
                          required={true}
                          component={renderFormV2}
                        >
                          <option key="placeholder" value="" hidden>
                            Select a collection
                          </option>
                          {collections.map((col, index) => (
                            <option key={col._id} value={index}>
                              {col.name}
                            </option>
                          ))}
                        </Field>
                        <Field
                          type="text"
                          name="recipient"
                          component={renderFormV2}
                          placeholder={user.account_address}
                        />
                        <button
                          className="mt-md-4"
                          type="submit"
                          disabled={pristine || submitting}
                        >
                          Mint Only!
                        </button>
                      </TabPanel>
                      <TabPanel>
                        <Field
                          type="select"
                          name="collection"
                          required={true}
                          component={renderFormV2}
                        >
                          <option key="placeholder" value="" hidden>
                            Select a collection
                          </option>
                          {collections.map((col, index) => (
                            <option key={col._id} value={index}>
                              {col.name}
                            </option>
                          ))}
                        </Field>
                        {!!collection && (
                          <Field
                            type="select"
                            name="launchpad"
                            title="Mintpad"
                            required={true}
                            disabled={collectionLaunchpads.length === 0}
                            component={renderFormV2}
                          >
                            <option key="placeholder" value="" hidden>
                              {collectionLaunchpads.length > 0
                                ? "Select a mintpad"
                                : "No mintpads available for collection"}
                            </option>
                            {collectionLaunchpads.map((col, index) => (
                              <option key={col._id} value={index}>
                                {col.launchpad_collection.name}
                              </option>
                            ))}
                          </Field>
                        )}
                        {!!launchpad && (
                          <Field
                            type="select"
                            name="tier"
                            required={true}
                            component={renderFormV2}
                          >
                            <option key="placeholder" value="" hidden>
                              Select a tier
                            </option>
                            {launchpad.sales.map((sale, index) => (
                              <option key={index} value={index}>
                                {sale.title}
                              </option>
                            ))}
                          </Field>
                        )}

                        <button
                          className="mt-md-4"
                          type="submit"
                          disabled={pristine || submitting}
                        >
                          Mint!
                        </button>
                      </TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default reduxForm({
  form: formName,
  initialValues: { tier: 0 },
  validate,
})(BatchCreateItem);
