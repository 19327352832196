import React from "react";
import { Field } from "redux-form";
import { IS_PROD } from "utils/environments";
import { renderFormV2 } from "utils/form";

const EditLaunchpadImages = ({
  launchpadImage,
  setLaunchpadImage,
  collectionLogoImage,
}) => {
  return (
    <div className="form-upload-profile">
      <Field
        type="file"
        name="launchpad_img"
        title="Launchpad Image (Optional)"
        className="hideInput"
        labelClassName="full-image"
        containername={IS_PROD ? "required" : ""}
        featuredImage={launchpadImage ?? collectionLogoImage}
        component={renderFormV2}
        accept=".png,.jpg,.jpeg,.gif,.mp4"
        onChange={(e) => {
          if (e) {
            setLaunchpadImage(e);
          }
        }}
      />
    </div>
  );
};

export default EditLaunchpadImages;
