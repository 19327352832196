import { useState } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";

const AccordionTitle = styled.span`
  color: var(--primary-color2);
  font-size: 28px;
`;

const BagAccordion = ({ defaultOpen, fields, index, children }) => {
  const [open, setOpen] = useState(defaultOpen);
  const title = fields.get(index)?.title || `Bag #${index + 1}`;
  const mintLimit = fields.get(index)?.limit || "UNLIMITED";

  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="fullWidth item-details-accordian"
      >
        <div className="flex justify-content-between align-items-center">
          <div className="flex align-items-center" style={{ gap: "1rem" }}>
            <AccordionTitle>{title}</AccordionTitle>
          </div>
        </div>
        <div className="flex flex-column fullWidth" style={{ gap: "3rem" }}>
          <div className="flex justify-content-between"></div>
          <div className="flex justify-content-between" style={{ height: "2rem" }}>
            <span style={{ fontSize: "18px", fontWeight: 600 }}>
              Per Wallet: {mintLimit}
            </span>

            {open ? (
              <i className="fas fa-caret-up" />
            ) : (
              <i className="fas fa-caret-down" />
            )}
          </div>
        </div>
      </button>
      <Collapse in={open}>
        <div>
          <div className="item-details-accordian-details">{children}</div>
        </div>
      </Collapse>
    </div>
  );
};

export default BagAccordion;
