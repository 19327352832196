import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { mystToSui, statusToEffect } from "utils/formats";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import CollectionWishlistButton from "../CollectionWishlistButton";
import LazyLoadImage from "../LazyLoadImage";
import { createLaunchpad, getLaunchpadsForCollection } from "utils/api";
import {
  collectRoyalties,
  createLaunchpad as setupLaunchpad,
  getRoyalties,
} from "web3/sui";
import { sleep } from "utils/time";
import ToastPopup from "components/utils/ToastPopup";
import EllipsisDropdown from "../EllipsisDropdown";
import VerifiedBadge from "components/utils/VerifiedBadge";

const CollectionCard = ({ item: collection, owned, link, onClick }) => {
  const [launchpad, setLaunchpad] = useState(false);
  const [launchpadLoading, setLaunchpadLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { _id, name, creator, review_status } = collection;
  const to = `${link || "/collection-details"}/${_id}`;
  const linkProps = onClick
    ? {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
        to,
      }
    : { to };
  const isCreator = useMemo(() => {
    return user && creator ? creator._id === user._id : false;
  }, [user, creator]);

  useEffect(() => {
    if (user.permissions?.launchpad_creator) {
      getLaunchpadsForCollection(_id).then((res) => {
        setLaunchpad(res.data[0]);
      });
    }
  }, []);

  const handleEditCollection = () => {
    navigate(`/edit-collection/${_id}`);
  };

  const handleViewCollection = () => {
    navigate(`/collection-details/${_id}`);
  };

  const handleCreateLaunchpad = async () => {
    if (!launchpad?.object_id) {
      setLaunchpadLoading(true);
      try {
        const tx = await setupLaunchpad(collection, [], [], []);
        await sleep();
        let res = await createLaunchpad({
          collection: _id,
          tx: tx.effects.transactionDigest,
        });
        setLaunchpadLoading(false);
        navigate(`/edit-mintpad/${res.data.launchpad._id}`);
      } catch (error) {
        setLaunchpadLoading(false);
      }
    } else {
      navigate(`/edit-mintpad/${launchpad._id}`);
    }
  };

  const handleCollect = async () => {
    let data = await getRoyalties(collection, 0);
    if (data > 0) {
      ToastPopup(`Withdrawing ${mystToSui(data)} SUI...`);
      await collectRoyalties(collection, 0);
    } else {
      ToastPopup(`No royalties to collect`, "warning");
    }
  };

  const optionsDropdownList = [
    {
      label: "View Collection",
      onClick: handleViewCollection,
      icon: <i className="fas fa-eye" />,
    },
  ];

  if (owned && !!user.permissions?.launchpad_creator && !launchpad?.object_id)
    optionsDropdownList.push({
      label: "Create Mintpad",
      onClick: handleCreateLaunchpad,
      icon: <i className="fad fa-rocket-launch" />,
    });

  if (isCreator && collection.keepsake) {
    optionsDropdownList.push({
      label: "Edit Collection",
      onClick: handleEditCollection,
      icon: <i className="fas fa-pencil" />,
    });
    optionsDropdownList.push({
      label: "Claim Royalties",
      onClick: handleCollect,
      icon: <i className="fas fa-money-check-edit-alt" />,
    });
  }

  return (
    <div className="sc-card-collection collection-card">
      <CollectionWishlistButton className="wishlist" collection={collection} />
      <Link {...linkProps}>
        <LazyLoadImage
          height="160px"
          className="collection-img-featured"
          src={collection.featured_image}
          key={`featured-${_id}`}
        />

        <div className="ps-abs-mdl" style={{ height: "72px", width: "72px" }}>
          <div className="collection-img-logo">
            <LazyLoadImage
              src={collection.logo_image}
              className="fullWidth h-100"
              key={`logo-${_id}`}
            />
          </div>
          <VerifiedBadge verified={collection?.verified} />
        </div>
      </Link>
      <div className="card-bottom fullWidth pd-15 justify-content-center gap-1">
        <h4 className="text-truncate">
          <Link {...linkProps}>{name ?? "Unnamed Collection"}</Link>
        </h4>
        <EllipsisDropdown list={optionsDropdownList} item={collection} />
        {user.role_id == 3 && owned && (
          <div className="ps-abs-br pb-3 pr-3">
            <span className={`sc-status ${statusToEffect(review_status)} capitalize`}>
              {review_status}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
