import transakSDK from "@transak/transak-sdk";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IS_PROD, IS_TESTNET } from "utils/environments";

const Button = styled.button`
  border-radius: var(--border-radius-md);
  height: 4rem;
  padding: 0.5rem 2rem;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--primary-color11);
  border-color: var(--primary-color11);

  :hover,
  :focus {
    background-color: var(--primary-color11d);
    border-color: var(--primary-color11d);
  }
`;

export const openTransak = ({ user }) => {
  const settings = {
    apiKey:
      IS_PROD && !IS_TESTNET
        ? "41e0cb90-2ab0-4a3a-a385-4ff01e70a392"
        : "6659a1a9-bc9e-4a77-bcd2-6f8975b9afb8",
    environment: IS_PROD && !IS_TESTNET ? "PRODUCTION" : "STAGING",
    cryptoCurrencyCode: "SUI",
    themeColor: "222333",
    hostURL: window.location.origin,
    widgetHeight: "700px",
    widgetWidth: "500px",
    hideMenu: true,
    walletAddress: user?.account_address || "",
    isFeeCalculationHidden: true,
    exchangeScreenTitle: "Add SUI Instantly",
  };

  const transak = new transakSDK(settings);

  transak.init();

  // To get all the events
  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data);
  });

  // This will trigger when the user closed the widget
  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
    console.log(eventData);
    transak.close();
  });

  // This will trigger when the user marks payment is made.
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    console.log(orderData);
    window.alert("Payment Success");
    transak.close();
  });
};

const TransakButton = () => {
  const user = useSelector((state) => state.user);
  return (
    <Button type="button" onClick={() => openTransak({ user })}>
      Buy SUI with Credit Card
    </Button>
  );
};

export default TransakButton;
