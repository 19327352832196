import { useSelector } from "react-redux";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import ProfileBanner from "components/layouts/ProfileBanner";
import { SearchProvider } from "components/utils/SearchProvider";
import { ProfileMenuProvider } from "components/utils/ProfileMenuProvider";
import ProfileSidebar from "components/layouts/ProfileSidebar";
import ProfileContent from "components/layouts/ProfileContent";
import ToastPopup from "components/utils/ToastPopup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MyProfile = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user._id) {
      navigate("/", "replace");
      ToastPopup("Please connect your wallet to continue", "error");
    }

  }, [user]);

  return (
    <div className="my-profile">
      <Header />
      <ProfileBanner user={user} />
      <div className="themesflat-container">
        <div className="row justify-content-center">
          <SearchProvider>
            <ProfileMenuProvider>
              <ProfileSidebar />
              <ProfileContent />
            </ProfileMenuProvider>
          </SearchProvider>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyProfile;
