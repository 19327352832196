import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, change } from "redux-form";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import WizardForm from "components/layouts/WizardForm";
import CreateItemDetails from "./CreateItemDetails";
import {
  announceNFTs,
  getMyCollections,
  getMyLaunchpads,
  updateCollection,
} from "utils/api";
import CreateItemMintType from "./CreateItemMintType";
import { formatDateForPicker, roundTime, suiToMyst } from "utils/formats";
import { getBags, mintNFT } from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import ToastPopup from "components/utils/ToastPopup";
import { IS_PROD } from "utils/environments";
import { initFormVals } from "redux/state/initialValues";
import { getKiosks } from "redux/state/sui";
import CreateItemMetadata from "./CreateItemMetadata";

const formName = "create-nft";

const validate = (values /*, dispatch */) => {
  const errors = {};

  if (values) {
    if (values.start_date >= values.end_date) {
      errors.start_date = "Start date cannot be after end date";
    }
    if (!values.image && IS_PROD) errors.image = "Required";
  }
  return errors;
};

const CreateItemForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting, invalid } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const values = form?.values;
  const [collections, setCollections] = useState([]);
  const [launchpads, setLaunchpads] = useState([]);
  const [mintType, setMintType] = useState(0);
  const collection = collections[values?.collection];
  const collectionLaunchpads = launchpads.filter(
    (pad) => pad.launchpad_collection?._id === collection?._id
  );
  const launchpad = collectionLaunchpads[values?.launchpad];

  useEffect(() => {
    if (values) {
      if (values?.metadata) {
        dispatch(change(formName, "metadata", undefined));
      }
    }
  }, [collection]);

  const fetchCollections = async () => {
    const req = await getMyCollections();
    setCollections(req.data.results);
  };

  const fetchLaunchpads = async () => {
    const req = await getMyLaunchpads();
    setLaunchpads(req.data.results);
  };

  useEffect(() => {
    if (user._id) {
      fetchCollections();
      fetchLaunchpads();
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      initFormVals(formName, {
        min_duration: 1,
        start_date: formatDateForPicker(roundTime(new Date())),
      })
    );
  }, []);

  const onSubmit = async (values) => {
    // upload iamage
    // let image =
    //   nftImage ||
    //   "https://ipfs.io/ipfs/bafkreibngqhl3gaa7daob4i2vccziay2jjlp435cf66vhono7nrvww53ty";
    values.count = values.count || 1;
    // if (values.image) {
    //   image = await uploadNFTImage(
    //     createReponse.data.accessToken,
    //     collection._id,
    //     createReponse.data.nft._id,
    //     values.image
    //   ).then((res) => res);
    // }

    let mint_name = "mint";
    switch (mintType) {
      case 0:
      default:
        break;
      case 1:
        mint_name = "launchpad";
        break;
      case 2:
        mint_name = "nft_bag";
        break;
    }

    const promise = mintNFT(
      {
        ...values,
        ...{
          launchpad,
          nft_collection: collection,
          price: values?.price,
        },
      },
      mint_name
    );

    let info = await promise;
    await sleep();

    const apiResponse = await tryAgain(
      () => announceNFTs(info.effects.transactionDigest),
      5
    );

    if (mint_name === "launchpad") {
      ToastPopup("Mintpad successfully updated.", "success");
    } else if (mint_name == "nft_bag") {
      ToastPopup("NFT Bag successfully updated.", "success");
      await sleep(5000);
      let bags = await getBags(collection.full_type);
      updateCollection(collection._id, { grab_bags: bags });
    } else {
      if (apiResponse?.data) {
        ToastPopup("Nft(s) minted successfully", "success");
        await sleep(1000);
        navigate(`/profile/owned-nfts`);
      }
    }
  };

  return (
    <div className="list-item">
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <div className="flat-tabs tab-list-item">
                  <WizardForm
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    pristine={pristine}
                    submitting={submitting}
                    invalid={invalid}
                    submitText="Mint"
                  >
                    {/* <CreateItemImages
                      title="Uploads"
                      nftImage={nftImage}
                      setNFTImage={setNFTImage}
                    /> */}
                    <CreateItemDetails
                      title="Details"
                      collections={collections}
                      collection={collection}
                    />
                    {collection && collection?.fields.length > 0 && (
                      <CreateItemMetadata title="Metadata" collection={collection} />
                    )}
                    <CreateItemMintType
                      title="Mint Type"
                      setMintType={setMintType}
                      collection={collection}
                      launchpad={launchpad}
                      collectionLaunchpads={collectionLaunchpads}
                    />
                  </WizardForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(CreateItemForm));
