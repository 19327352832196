import { SUI_CLOCK_OBJECT_ID } from "@mysten/sui.js/utils";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import {
  SuiAddress,
  SUI,
  getTransferPolicies,
  getUserAddress,
  currentSettings,
  signAndExecuteTransactionBlock,
} from "web3/sui";
import { suiToMyst } from "utils/formats";
import { addToTransaction } from "web3/txnbuilder";
import { isArray } from "lodash";

export const enforceTxbPolicies = (
  policy,
  txb,
  transferRequest,
  type,
  { skip_lock = false, kiosk, kioskCap, nft }
) => {
  let settings = currentSettings();
  let placed = skip_lock;
  policy.rules.map((rule) => {
    if (rule.includes("::kiosk_lock_rule::Rule")) {
      if (kiosk) {
        if (!skip_lock && nft) {
          addToTransaction(txb, {
            target: `${SuiAddress}::kiosk::lock`,
            typeArguments: [type],
            arguments: [kiosk, kioskCap, policy.id, nft],
            types: ["object", "object", "object", "object"],
          });
          placed = true;
        }
        addToTransaction(txb, {
          target: `${settings.packages.mysten_kiosk}::kiosk_lock_rule::prove`,
          typeArguments: [type],
          arguments: [transferRequest, kiosk],
          types: ["object", "object"],
        });
      }
    } else if (rule.includes("::royalty_rule::Rule")) {
      let [paid] = addToTransaction(txb, {
        target: `${SuiAddress}::transfer_policy::paid`,
        typeArguments: [type],
        arguments: [transferRequest],
        types: ["object"],
      });
      let [fee] = addToTransaction(txb, {
        target: `${settings.packages.mysten_kiosk}::royalty_rule::fee_amount`,
        typeArguments: [type],
        arguments: [policy.id, paid],
        types: ["object", "u64"],
      });
      let [coin] = addToTransaction(txb, {
        type: "splitCoins",
        amounts: [fee],
      });
      addToTransaction(txb, {
        target: `${settings.packages.mysten_kiosk}::royalty_rule::pay`,
        typeArguments: [type],
        arguments: [policy.id, transferRequest, coin],
        types: ["object", "object", "u64"],
      });
    } else if (rule.includes("::personal_kiosk_rule::Rule")) {
      addToTransaction(txb, {
        target: `${settings.packages.mysten_kiosk}::personal_kiosk_rule::prove`,
        typeArguments: [type],
        arguments: [kiosk, transferRequest],
        types: ["object", "object"],
      });
    }
  });
  if (!placed && kiosk && kioskCap && nft) {
    addToTransaction(txb, {
      target: `${SuiAddress}::kiosk::place`,
      typeArguments: [type],
      arguments: [kiosk, kioskCap, nft],
      types: ["object", "object", "object"],
    });
  }
};

const handleKiosk = (userKiosk, txb) => {
  let settings = currentSettings();
  let kiosk = userKiosk?.id;
  let kioskCap = userKiosk?.cap;
  let newUserKiosk = false;
  let personalCap = userKiosk?.cap;
  let convertToPersonal = false;
  let borrow = false;
  if (!userKiosk) {
    [kiosk, kioskCap] = addToTransaction(txb, {
      target: `${SuiAddress}::kiosk::new`,
    });
    newUserKiosk = true;
  }

  if (settings.object_ids.personal_kiosk) {
    if (userKiosk?.standard != "personal") {
      [personalCap] = addToTransaction(txb, {
        target: `${settings.packages.mysten_kiosk}::personal_kiosk::new`,
        arguments: [kiosk, kioskCap],
        types: ["object", "object"],
      });
      convertToPersonal = true;
    }
    [kioskCap, borrow] = addToTransaction(txb, {
      target: `${settings.packages.mysten_kiosk}::personal_kiosk::borrow_val`,
      arguments: [personalCap],
      types: ["object"],
    });
  }
  return { kiosk, kioskCap, newUserKiosk, personalCap, convertToPersonal, borrow };
};

const handleReturnKiosk = (kioskInfo, txb) => {
  let { kiosk, kioskCap, newUserKiosk, personalCap, convertToPersonal, borrow } =
    kioskInfo;
  let userAddress = getUserAddress();
  let settings = currentSettings();

  if (newUserKiosk) {
    addToTransaction(txb, {
      target: `${SuiAddress}::transfer::public_share_object`,
      typeArguments: [`${SuiAddress}::kiosk::Kiosk`],
      arguments: [kiosk],
      types: ["object"],
    });
  }

  if (settings.object_ids.personal_kiosk) {
    // add extra stuff if kiosk is personal
    addToTransaction(txb, {
      target: `${settings.packages.mysten_kiosk}::personal_kiosk::return_val`,
      arguments: [personalCap, kioskCap, borrow],
      types: ["object", "object", "object"],
    });

    if (convertToPersonal) {
      addToTransaction(txb, {
        target: `${settings.packages.mysten_kiosk}::personal_kiosk::transfer_to_sender`,
        arguments: [personalCap],
        types: ["object"],
      });
    }
  } else if (newUserKiosk) {
    addToTransaction(txb, {
      type: "transferObjects",
      object: kioskCap,
      to: userAddress,
    });
  }
};

export const listKiosk = (nft, realPrice, userKiosks) => {
  let settings = currentSettings();
  let userAddress = getUserAddress();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let userKiosk = userKiosks?.[0];
  if (nft.kiosk) {
    userKiosk = userKiosks.find((a) => a.id === nft.kiosk);
  } else if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  if (!nft.kiosk) {
    addToTransaction(txb, {
      target: `${SuiAddress}::kiosk::place`,
      typeArguments: [nft.type],
      arguments: [kiosk, kioskCap, nft.id],
      types: ["object", "object", "object"],
    });
  }

  addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::list`,
    typeArguments: [nft.type],
    types: ["object", "id", "u64", "object", "object", "Option<id>"],
    arguments: [
      settings.mysten_market.marketplace,
      nft.id,
      realPrice.toString(),
      kiosk,
      kioskCap,
      [],
    ],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const buyKiosk = async (listing, price, userKiosks) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  let userKiosk = userKiosks?.[0];
  if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }

  // For testing
  // userKiosk = false;
  // settings.object_ids.personal_kiosk = false;

  const policies = await getTransferPolicies(listing.object_type);
  let filteredPolicies = policies.filter((policy) =>
    policy.rules.find((a) => a.includes("kiosk_lock_rule"))
  );
  let policy = filteredPolicies[0];

  // TODO: handle old contract vs new market extension

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let [coin] = addToTransaction(txb, {
    type: "splitCoins",
    amounts: [price],
  });

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  let [nft, request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::buy`,
    typeArguments: [listing.object_type],
    arguments: [
      settings.mysten_market.marketplace,
      listing.seller_kiosk,
      listing.nft_object_id,
      coin,
      [],
    ],
    types: ["object", "object", "address", "object", "Option<id>"],
  });

  enforceTxbPolicies(policy, txb, request, listing.object_type, { kiosk, kioskCap, nft });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const auctionKiosk = (
  marketInfo,
  nft,
  min_price,
  min_bid_increment,
  starts,
  expires,
  userKiosks
) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let userKiosk = userKiosks?.[0];
  if (nft.kiosk) {
    userKiosk = userKiosks.find((a) => a.id === nft.kiosk);
  } else if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }

  let [feePayment] = addToTransaction(txb, {
    type: "splitCoins",
    amounts: [marketInfo.data.collateral_fee],
  });

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  if (!nft.kiosk) {
    addToTransaction(txb, {
      target: `${SuiAddress}::kiosk::place`,
      typeArguments: [nft.type],
      arguments: [kiosk, kioskCap, nft.id],
      types: ["object", "object", "object"],
    });
  }

  addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::auction`,
    typeArguments: [nft.type],
    types: [
      "object",
      "id",
      "u64",
      "u64",
      "u64",
      "u64",
      "object",
      "object",
      "object",
      "Option<id>",
    ],
    arguments: [
      settings.mysten_market.marketplace,
      nft.id,
      min_price,
      min_bid_increment,
      starts,
      expires,
      feePayment,
      kiosk,
      kioskCap,
      [],
    ],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const winKioskAuction = async (listing, userKiosks) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let userKiosk = userKiosks?.[0];
  if (nft.kiosk) {
    userKiosk = userKiosks.find((a) => a.id === nft.kiosk);
  } else if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  const policies = await getTransferPolicies(listing.object_type);
  let filteredPolicies = policies.filter((policy) =>
    policy.rules.find((a) => a.includes("kiosk_lock_rule"))
  );
  let policy = filteredPolicies[0];

  let [nft, request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_marketplace}::keepsake_kiosk_marketplace::complete_auction`,
    typeArguments: [listing.object_type],
    arguments: [
      settings.mysten_market.marketplace,
      listing.seller_kiosk,
      listing.nft_object_id,
      SUI_CLOCK_OBJECT_ID,
    ],
    types: ["object", "object", "id", "object"],
  });

  enforceTxbPolicies(policy, txb, request, listing.object_type, { kiosk, kioskCap, nft });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const lendKioskNFT = (
  nft,
  collection,
  { price: price_per_hour, formattedStarts, formattedEnds, min_duration, max_duration },
  policy,
  userKiosks
) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let userKiosk = userKiosks?.[0];
  if (nft.kiosk) {
    userKiosk = userKiosks.find((a) => a.id === nft.kiosk);
  } else if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }
  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  // first transfer it to a kiosk
  if (!nft.kiosk) {
    addToTransaction(txb, {
      target: `${SuiAddress}::kiosk::place`,
      typeArguments: [nft.type],
      arguments: [kiosk, kioskCap, nft.id],
      types: ["object", "object", "object"],
    });
  }

  let lockRule = policy.rules.find((a) => a.includes("::kiosk_lock_rule::Rule"));

  let [request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_lending}::keepsake_kiosk_lending::list`,
    arguments: [
      nft.id,
      kiosk,
      kioskCap,
      settings.lending.marketplace,
      policy.id,
      suiToMyst(price_per_hour).toString(),
      min_duration.toString(),
      max_duration.toString(),
      formattedStarts,
      formattedEnds,
      !!lockRule,
    ],
    typeArguments: [nft.type],
    types: [
      "id",
      "object",
      "object",
      "object",
      "object",
      "u64",
      "u64",
      "u64",
      "u64",
      "u64",
      "bool",
    ],
  });

  // TODO: get kiosk, use it to prove rules... somehow...

  enforceTxbPolicies(policy, txb, request, nft.type, {
    skip_lock: true,
  });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [nft.type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const borrowKioskNFT = (listing, current_kiosk, hours, policy, userKiosks) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let userKiosk = userKiosks?.[0];
  if (settings.object_ids.personal_kiosk && isArray(userKiosks)) {
    userKiosk = userKiosks.find((a) => a.standard === "personal");
  }
  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  let [request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_lending}::keepsake_kiosk_lending::borrow`,
    arguments: [
      listing.nft_object_id,
      settings.lending.marketplace,
      { kind: "GasCoin" },
      listing.ask_per_day.toString(),
      hours.toString(),
      kiosk,
      kioskCap,
      policy.id,
      SUI_CLOCK_OBJECT_ID,
    ],
    typeArguments: [listing.object_type],
    types: [
      "id",
      "object",
      "object",
      "u64",
      "u64",
      "object",
      "object",
      "object",
      "object",
    ],
  });

  enforceTxbPolicies(policy, txb, request, listing.object_type, {
    kiosk,
    kioskCap,
    skip_lock: true,
  });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const returnKioskNFT = (listing, userKiosk, policy) => {
  let userAddress = getUserAddress();
  let settings = currentSettings();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  let kiosk = userKiosk;

  let lockRule = policy.rules.find((a) => a.includes("::kiosk_lock_rule::Rule"));

  let [request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_lending}::keepsake_kiosk_lending::relinquish`,
    arguments: [
      listing.nft_object_id,
      kiosk,
      settings.lending.marketplace,
      policy.id,
      SUI_CLOCK_OBJECT_ID,
      !!lockRule,
    ],
    typeArguments: [listing.object_type],
    types: ["id", "object", "object", "object", "object", "bool"],
  });

  enforceTxbPolicies(policy, txb, request, listing.object_type, {
    skip_lock: true,
  });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  return txb;
};

export const finishLendingKioskNFT = (listing, policy, userKiosk, txb) => {
  let settings = currentSettings();
  let userAddress = getUserAddress();

  if (!txb) {
    txb = new TransactionBlock();
    txb.setSender(userAddress);
  }

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  let [nft, request] = addToTransaction(txb, {
    target: `${settings.packages.keepsake_kiosk_lending}::keepsake_kiosk_lending::complete_lending`,
    typeArguments: [listing.object_type],
    arguments: [listing.nft_object_id, settings.lending.marketplace],
    types: ["id", "object"],
  });

  enforceTxbPolicies(policy, txb, request, listing.object_type, {
    nft,
    kiosk,
    kioskCap,
  });

  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [listing.object_type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });

  handleReturnKiosk(kioskInfo, txb);

  return txb;
};

export const burnKioskNFT = async (nft, collection, userKiosk) => {
  let settings = currentSettings();
  let userAddress = getUserAddress();

  const txb = new TransactionBlock();
  txb.setSender(userAddress);

  // if it's in a kiosk, list it for 0 and buy it
  const policies = await getTransferPolicies(nft.type);
  let policy = policies.find((policy) =>
    policy.rules.find((a) => a.includes("burn::BurnRule"))
  );

  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;

  addToTransaction(txb, {
    target: `${SuiAddress}::kiosk::list`,
    typeArguments: [nft.type],
    arguments: [kiosk, kioskCap, nft.id, 0],
    types: ["object", "object", "id", "u64"],
  });
  let [emptyCoin] = addToTransaction(txb, {
    target: `${SuiAddress}::coin::zero`,
    typeArguments: [SUI],
  });
  let [tx_nft, request] = addToTransaction(txb, {
    target: `${SuiAddress}::kiosk::purchase`,
    typeArguments: [nft.type],
    arguments: [kiosk, nft.id, emptyCoin],
    types: ["object", "id", "u64"],
  });

  let [uid] = addToTransaction(txb, {
    target: `${collection.object_id}::${collection.module_name}::burn_and_return_uid`,
    arguments: [tx_nft],
    types: ["object"],
  });
  addToTransaction(txb, {
    target: `${settings.packages.burn}::burn::confirm_burn`,
    typeArguments: [nft.type],
    arguments: [uid, request],
    types: ["object", "object"],
  });
  addToTransaction(txb, {
    target: `${SuiAddress}::transfer_policy::confirm_request`,
    typeArguments: [nft.type],
    arguments: [policy.id, request],
    types: ["object", "object"],
  });
  handleReturnKiosk(kioskInfo, txb);
  return txb;
};

export const withdrawFromKiosk = (userKiosk, forTx) => {
  let txb = forTx;
  if (!forTx) {
    let userAddress = getUserAddress();
    txb = new TransactionBlock();
    txb.setSender(userAddress);
  }
  console.log(userKiosk);
  let kioskInfo = handleKiosk(userKiosk, txb);
  let { kiosk, kioskCap } = kioskInfo;
  let [coin] = addToTransaction(txb, {
    target: `${SuiAddress}::kiosk::withdraw`,
    arguments: [kiosk, kioskCap, []],
    types: ["object", "object", "Option<u64>"],
  });
  addToTransaction(txb, {
    target: `${SuiAddress}::coin::join`,
    typeArguments: [SUI],
    types: ["object", "object"],
    arguments: [{ kind: "GasCoin" }, coin],
  });
  handleReturnKiosk(kioskInfo, txb);
  if (forTx) {
    return txb;
  }
  return signAndExecuteTransactionBlock(txb);
};

export const withdrawFromKiosks = (userKiosks) => {
  let userAddress = getUserAddress();
  const txb = new TransactionBlock();
  txb.setSender(userAddress);
  userKiosks.map((userKiosk) => {
    if (userKiosk.data.profits > 0) {
      console.log(userKiosk.data);
      withdrawFromKiosk(userKiosk, txb);
    }
  });
  return signAndExecuteTransactionBlock(txb);
};
