import React, { useState } from "react";
import styled from "styled-components";

const GroupButton = styled.button`
  border: 2px solid var(--primary-color);
  border-radius: 0px;
  justify-content: center;
  color: var(--primary-color9);
  padding: 1rem 2rem;
  background: var(--primary-color);

  :hover {
    background: var(--hover-color);
    border: 2px solid var(--primary-color3);
  }

  :disabled {
    background: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
    cursor: default;
  }

  :first-child {
    border-radius: 12px 0px 0px 12px;
  }
  :last-child {
    border-radius: 0px 12px 12px 0px;
  }

  &.button-selected {
    color: #fff;
    background: var(--hover-color);
    border: 2px solid var(--primary-color3);
  }
`;

const TimespanButtonGroup = ({ onChange = () => {} }) => {
  const [timespan, setTimespan] = useState(1); // hours
  return (
    <div>
      <GroupButton
        type="button"
        className={`${timespan == 1 ? "button-selected" : ""}`}
        onClick={() => {
          setTimespan(1);
          onChange(1);
        }}
      >
        1H
      </GroupButton>
      <GroupButton
        type="button"
        disabled
        className={`${timespan === 24 ? "button-selected" : ""}`}
        onClick={() => {
          setTimespan(24);
          onChange(24);
        }}
      >
        1D
      </GroupButton>
      <GroupButton
        type="button"
        disabled
        className={`${timespan === 168 ? "button-selected" : ""}`}
        onClick={() => {
          setTimespan(168);
          onChange(168);
        }}
      >
        7D
      </GroupButton>
    </div>
  );
};

export default TimespanButtonGroup;
