import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { renderFormV2 } from "utils/form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { mystToSui } from "utils/formats";
import Tooltip from "components/utils/Tooltip";

const CreateItemMintType = ({
  setMintType,
  collection,
  launchpad,
  collectionLaunchpads,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Tabs onSelect={setMintType}>
      <TabList className="react-tabs__tab-list two-tabs">
        <Tab>
          <span className="icon-fl-send" />
          Mint Only
        </Tab>
        <Tab>
          <span className="icon-fl-tag" />
          Add to Mintpad
        </Tab>
        {/* <Tab>
          <span className="icon-fl-tag" />
          Add to NFT Bag
        </Tab> */}
      </TabList>
      <TabPanel>
        <Field
          type="text"
          name="recipient"
          component={renderFormV2}
          placeholder={user.account_address}
        />
      </TabPanel>
      <TabPanel>
        {!!collection && (
          <Field
            type="select"
            name="launchpad"
            title="Mintpad"
            required={true}
            disabled={collectionLaunchpads.length === 0}
            component={renderFormV2}
          >
            <option key="placeholder" value="" hidden>
              {collectionLaunchpads.length > 0
                ? "Select a mintpad"
                : "No mintpads available for collection"}
            </option>
            {collectionLaunchpads.map((col, index) => (
              <option key={col._id} value={index}>
                {col.launchpad_collection.name}
              </option>
            ))}
          </Field>
        )}
        {!!launchpad && (
          <Field type="select" name="tier" required={true} component={renderFormV2}>
            <option key="placeholder" value="" hidden>
              Select a tier
            </option>
            {launchpad.sales.map((sale, index) => (
              <option key={index} value={index}>
                {sale.title}
              </option>
            ))}
          </Field>
        )}
      </TabPanel>
      <TabPanel></TabPanel>
    </Tabs>
  );
};

export default CreateItemMintType;
