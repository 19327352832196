import "./notifi.css";

export const FallbackCard = () => {
  const darkTheme = "is_dark";

  const theme = localStorage.getItem("theme" ?? darkTheme);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: "100",
      }}
      className={theme === "is_dark" ? "notifi__dark" : "notifi__light"}
    >
      <div
        style={{
          position: "fixed",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="NotifiSubscriptionCard__container"
      >
        <div className="NotifiAlertBox__content">
          <h2>Something went wrong</h2>
        </div>
      </div>
    </div>
  );
};
