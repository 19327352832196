import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoadImage from "../LazyLoadImage";
import styled from "styled-components";
import { getSaleData } from "web3/sui";
import { mystToSui, sumFieldInArray } from "utils/formats";

const GridRow = styled.div`
  padding: 0 0.5rem;
  display: grid;
  align-items: center;
  height: 88px;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  transition: all 0.4s ease;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  &:hover {
    background: var(--hover-color);
  }
  @media screen and (min-width: 575px) {
    grid-template-columns: minmax(0px, 1.5fr) minmax(0px, 2fr);
    padding: 0 1rem;
  }
  @media screen and (min-width: 960px) {
    padding: 0 2rem;
  }
`;

const NameImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

const RowImage = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50px;
  overflow: hidden;
`;

const RowName = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: -0.5px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RowDetails = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, minmax(0px, 1fr));

  @media screen and (min-width: 575px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
  @media screen and (min-width: 960px) {
    grid-template-columns: minmax(0px, 1.25fr) repeat(3, minmax(0px, 1fr));
  }
`;

const RowText = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

const RowTextSmall = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgb(138, 147, 155);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TextOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

const LaunchpadRow = ({ item, screenWidth }) => {
  const { _id, launchpad_collection, status, start_date } = item;
  const { name, floor, logo_image } = launchpad_collection;
  const to = `/mintpad/${_id}`;
  const date = new Date(start_date);
  const stringDate = date.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });
  const stringTime = date.toLocaleString("default", { timeStyle: "short" });
  const launchDate = `${stringDate}, ${stringTime}`;
  const [inventories, setInventories] = useState([]);
  const sales = item?.sales || [];
  let visibleInventories = [];
  let publicInventories = [];
  if (inventories && inventories.length > 0) {
    visibleInventories = sales
      .map((sale, index) => !sale.hidden && { ...inventories[index], price: sale.price })
      .filter(Boolean);
    publicInventories = sales
      .map(
        (sale, index) =>
          !sale.hidden &&
          !sale.whitelisted && { ...inventories[index], price: sale.price }
      )
      .filter(Boolean);
  }
  const combinedTotalItems = sumFieldInArray(visibleInventories, "total");
  const mintPrice = mystToSui(sumFieldInArray(publicInventories, "price"));
  const totalVolume = mintPrice * combinedTotalItems;

  useEffect(() => {
    getSaleData(item, inventories)
      .then((res) => setInventories(res))
      .catch((e) => console.log("error", e));
  }, []);

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={logo_image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "Unnamed Mintpad"}</RowName>
            {screenWidth <= 960 && <RowTextSmall>{launchDate}</RowTextSmall>}
          </TextOverflow>
        </NameImageWrapper>
        <RowDetails>
          {screenWidth > 960 && <RowText>{launchDate}</RowText>}
          <RowText>{floor ? `${mystToSui(floor)} SUI` : "~"} </RowText>
          {screenWidth > 575 && <RowText>{mintPrice ? `${mintPrice} SUI` : "~"}</RowText>}
          <RowText>{totalVolume ? `${totalVolume} SUI` : "~"}</RowText>
        </RowDetails>
      </GridRow>
    </Link>
  );
};

export default LaunchpadRow;
