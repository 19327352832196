import { ethos } from "ethos-connect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBalances } from "redux/state/sui";

const EthosSync = () => {
  const { wallet } = ethos.useWallet();
  const dispatch = useDispatch();
  const sui = useSelector((state) => state.sui);
  let total = wallet?.contents?.suiBalance?.toNumber();

  useEffect(() => {
    if (sui.total !== total) {
      dispatch(setBalances(total));
    }
  }, [total]);

  return null;
};

export default EthosSync;
