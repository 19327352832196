import { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import ToastPopup from "components/utils/ToastPopup";
import WizardForm from "components/layouts/WizardForm";
import {
  createCollection as uploadCollection,
  searchCollections,
  uploadCollectionImage,
  logCollection,
  getCollectionLog,
  updateCollection,
} from "utils/api";
import {
  publish,
  createCollection,
  getTransaction,
  getObjectsInfo,
  getObjectByType,
  getEvents,
} from "web3/sui";
import { sleep } from "utils/time";
import { tryAgain } from "utils/performance";
import { IS_PROD } from "utils/environments";
import { initFormVals } from "redux/state/initialValues";
import CreateGameDetails from "./CreateGameDetails";
import CreateGameImages from "./CreateGameImages";

const formName = "create-game";

const validate = (values /*, dispatch */) => {
  const errors = {};
  const MAX_FILE_SIZE = 10 * 1024 * 1024; //10Mb

  if (values) {
    if (!values.logo_img && IS_PROD) {
      errors.logo_img = "Required";
    }
    if (!values.featured_img && IS_PROD) {
      errors.featured_img = "Required";
    }
    if (values.logo_img?.size > MAX_FILE_SIZE) {
      errors.logo_img = "Max file size is 10MB. Please upload a smaller file.";
    }
    if (values.featured_img?.size > MAX_FILE_SIZE) {
      errors.featured_img = "Max file size is 10MB. Please upload a smaller file.";
    }
  }
  return errors;
};

const CreateGameForm = (props) => {
  let dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleSubmit, pristine, submitting, invalid } = props;
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const values = form?.values || {};
  const [loadingGame, setLoadingGame] = useState(false);
  const [steps, setSteps] = useState([]);
  const [forcePage, setForcePage] = useState(0);
  const settings = useSelector((state) => state.settings);

  const onSubmit = async (values) => {
    setLoadingGame(true);
    // check if game can be created first
    const nameTaken = await searchGames({ name: values.title }).then(
      (res) => res.data.results[0]
    );
    if (nameTaken) {
      setLoadingGame(false);
      throw new SubmissionError({
        name: "Name taken",
        _error: "A game with that title already exists",
      });
    }
    try {
      let logId = await logCollection({
        packageObjectId,
        creation_tx: res.effects.transactionDigest,
        ...values,
      });
    } catch (e) {
      setLoadingGame(false);
      console.log(e);
      throw new SubmissionError({
        _error: "Transaction failed, or was cancelled",
      });
    }
  };

  useEffect(() => {
    if (steps.length >= 1 && Object.keys(values).length > 0) {
      try {
        createCollectionObject();
      } catch (error) {
        setLoadingGame(false);
      }
    }
  }, [steps, Object.keys(values).length]);

  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form flat-form-wide">
                <div className="flat-tabs tab-list-item">
                  <WizardForm
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    pristine={false}
                    submitting={loadingGame}
                    invalid={invalid}
                    forcePage={forcePage}
                    submitText="Create Game"
                  >
                    {/* <CreateGameImages
                      title="Uploads"
                      logoImage={logoImage}
                      setLogoImage={setLogoImage}
                      featuredImage={featuredImage}
                      setFeaturedImage={setFeaturedImage}
                    /> */}
                    <CreateGameDetails title="Details" />
                  </WizardForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(CreateGameForm));
