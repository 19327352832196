import { NotifiContext, NotifiSubscriptionCard } from "@notifi-network/notifi-react-card";
import PropTypes from "prop-types";
import { ethos } from "ethos-connect";
import "./notifi.css";
import { ErrorBoundary } from "./ErrorBoundaryComponent";
import { FallbackCard } from "./FallbackCard";

export const NotifiCard = () => {
  const { wallet } = ethos.useWallet();
  const darkTheme = "is_dark";

  const theme = localStorage.getItem("theme" ?? darkTheme);

  const signMessage = async (message) => {
    if (!wallet) {
      throw new Error("Wallet not connected");
    }
    const response = await wallet.signPersonalMessage({
      message,
    });

    const signatureBuffer = Buffer.from(response.signature);
    return signatureBuffer;
  };

  const inputLabels = {
    placeholderText: {
      email: "Email Address",
      telegram: "Telegram ID",
    },
  };

  const inputSeparators = {
    emailSeparator: {
      content: "OR",
    },
  };

  return (
    <ErrorBoundary fallback={<FallbackCard />}>
      <NotifiContext
        env="Production"
        dappAddress="keepsake"
        walletBlockchain="SUI"
        accountAddress={wallet?.address}
        walletPublicKey={wallet?.address}
        signMessage={signMessage}
      >
        <NotifiSubscriptionCard
          darkMode={theme === "is_dark"}
          classNames={{
            FetchedStateCard: {
              SubscriptionCardV1: {
                NotifiAlertBox: { btnRight: "notifi-toggle-icon" },
              },
            },
          }}
          copy={{
            LoadingStateCard: {
              header: "",
            },
            FetchedStateCard: {
              SubscriptionCardV1: {
                EditCard: {
                  AlertListPreview: {
                    description:
                      "Get real-time alerts to the destinations of your choice",
                  },
                },
              },
            },
          }}
          inputLabels={inputLabels}
          inputSeparators={inputSeparators}
          cardId="b9c834bcc4b84743b5f58bd7bba8ed63"
        />
      </NotifiContext>
    </ErrorBoundary>
  );
};

NotifiCard.propTypes = {
  children: PropTypes.element,
};
