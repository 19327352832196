import { Modal } from "react-bootstrap";
import { ipfsConvert, listingToken, mystToSui } from "utils/formats";
import LoadingButton from "components/button/LoadingButton";
import { winNFTAuction } from "web3/sui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCollection, updateListing } from "utils/api";
import { WIN_LISTING } from "redux/types";
import { useEffect } from "react";

const WinModal = ({ item, onHide, fetchListing }) => {
  if (!item) {
    return false;
  }

  let dispatch = useDispatch();

  const [buyLoading, setBuyLoading] = useState(false);
  const [nftCollection, setNftCollection] = useState({});
  const ob_kiosks = useSelector((state) => state.sui.ob_kiosks);
  const kiosks = useSelector((state) => state.sui.kiosks);

  const handleSubmit = async () => {
    setBuyLoading(true);
    let res = false;
    if (nftCollection.kiosk_enabled) {
      res = await winNFTAuction(
        { ...item, nft_collection: nftCollection },
        kiosks?.[0]?.id,
        kiosks?.[0]?.cap
      );
    } else {
      res = await winNFTAuction(
        { ...item, nft_collection: nftCollection },
        ob_kiosks?.[0]?.id
      );
    }

    if (res?.status === "success") {
      await updateListing(item._id);
      dispatch({ type: WIN_LISTING, _id: item._id });
      if (fetchListing) {
        fetchListing();
      }
      onHide();
    }
  };
  const onLoad = async () => {
    if (item && !buyLoading) {
      if (typeof item.nft_collection == "object") {
        setNftCollection(item.nft_collection);
      } else {
        const nftCollection = await getCollection(item.nft_collection);
        setNftCollection(nftCollection.data.collection);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  return (
    <Modal show={item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Claim this listing?</h3>
        <h5 className="text-center">{item.nft.name}</h5>
        <img src={ipfsConvert(item.nft?.image)} />
        <div className={"field-container"}>
          <input
            name="price"
            type="text"
            disabled
            value={`${mystToSui(item.sale_price)} ${listingToken(item)}`}
          />
        </div>
        <LoadingButton
          loading={buyLoading}
          disabled={buyLoading}
          onClick={() => handleSubmit(item)}
        >
          Claim listing
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default WinModal;
