import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FieldArray } from "redux-form";
import { initFormVals } from "redux/state/initialValues";
import { renderInventories } from "utils/form";

const formName = "edit-launchpad-listings";
const EditLaunchpadListings = ({
  launchpad,
  inventories,
  setBatchCreateModalShow,
  setBatchTransferModalShow,
  setWhitelistModalShow,
  setBatchWithdrawModalShow,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTier = searchParams.get("currentTier");
  const type = searchParams.get("type");
  const collection = launchpad.launchpad_collection;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFormVals(formName, launchpad));

    if (currentTier) {
      switch (type) {
        case "create":
          setBatchCreateModalShow({
            launchpad,
            collection,
            tier: launchpad.sales[currentTier],
          });
          break;
        case "whitelist":
          setWhitelistModalShow({
            launchpad,
            tier: launchpad.sales[currentTier],
            index: currentTier,
          });
          break;
        case "import":
          setBatchTransferModalShow({
            launchpad,
            tier: launchpad.sales[currentTier],
            index: currentTier,
          });
          break;

        default:
          break;
      }
    }
  }, []);

  return (
    <FieldArray
      name="sales"
      component={renderInventories}
      props={{
        launchpad,
        inventories,
        sales: launchpad.sales,
        setBatchCreateModalShow,
        setBatchTransferModalShow,
        setWhitelistModalShow,
        setBatchWithdrawModalShow,
      }}
      rerenderOnEveryChange
    />
  );
};

export default EditLaunchpadListings;
