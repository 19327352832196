import React, { useState } from "react";
import { useSelector } from "react-redux";
import SearchBar from "components/layouts/SearchBar";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import LayoutButtons from "components/layouts/LayoutButtons";
import RefreshButton from "components/layouts/RefreshButton";
import Sorting from "components/layouts/Sorting";
import CardList from "components/layouts/explore/Lists/CardList";
import NFTSkeleton from "components/layouts/NFTSkeleton";
import RentalCard from "components/cards/grid/RentalCard";
import ListRentalSkeleton from "components/layouts/ListRentalSkeleton";
import ListRentalCard from "components/layouts/ListRentalCard";
import { sortRentalsDropdown } from "utils/sortDropdowns";
import { useRefreshSearch } from "components/utils/SearchProvider";

const MyRentalListingsPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const reduxState = useSelector((state) => {
    return {
      ...state.search.lendings,
      ...{
        results: state.search.lendings.results?.map((loan) => {
          return { ...loan.listing, current_loan: loan };
        }),
      },
    };
  });
  const orderedHeaders = [
    "Name",
    "Rent Expires",
    "Min Rent Duration",
    "Max Rent Duration",
    "Rental Ends",
    "Price Per Day",
    "Favorites",
  ];

  const handleHideModal = () => {
    setBuyLoading(false);
    setModalShow(false);
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortRentalsDropdown} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={NFTSkeleton}
          CardComponent={RentalCard}
          ListSkeletonComponent={ListRentalSkeleton}
          ListCardComponent={ListRentalCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
        />
      </PaginationWrapper>
    </div>
  );
};

export default MyRentalListingsPanel;
