import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LazyLoadImage from "../LazyLoadImage";
import EditButtons from "./details/EditButtons";
import LaunchpadCardCountdown from "./details/LaunchpadCardCountdown";
import { getSaleData } from "web3/sui";
import { mystToSui, sumFieldInArray } from "utils/formats";

const SocialButtons = ({ socials }) => {
  const { twitter, discord, instagram } = socials;
  if (Object.values(socials).every((el) => el === undefined)) return null;

  return (
    <div className="widget-social style-2 justify-content-center">
      <ul className="flex" style={{ gap: "1rem" }}>
        {twitter && (
          <li>
            <a href={twitter} target="_blank" rel="noreferrer">
              <i className="fab fa-twitter" />
            </a>
          </li>
        )}
        {discord && (
          <li>
            <a href={discord} target="_blank" rel="noreferrer">
              <i className="fab fa-discord" />
            </a>
          </li>
        )}
        {instagram && (
          <li>
            <a href={instagram} target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

const InfoBox = ({ text, textBold }) => {
  return (
    <div className="flex launchpad-infobox">
      <span style={{ fontSize: "18px" }}>
        {text}: <b>{textBold}</b>
      </span>
    </div>
  );
};

const LaunchpadCard = ({ item, owned, onClick }) => {
  const {
    _id,
    launchpad_collection,
    launchpad_image,
    status,
    start_date: startDate,
    sales,
  } = item;
  const {
    _id: collection_id,
    name,
    nft_count: items,
    twitter,
    discord,
    instagram,
    featured_image,
  } = launchpad_collection;
  const socials = { twitter, discord, instagram };
  const to = `/mintpad/${_id}`;
  const linkProps = onClick
    ? {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
        to,
      }
    : { to };

  const [inventories, setInventories] = useState([]);
  const [saleIndex, setSaleIndex] = useState(null);

  useEffect(() => {
    const firstPublicIndex = sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted != true
    );
    const firstPrivateIndex = sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted == true
    );
    const defaultIndex = firstPublicIndex > -1 ? firstPublicIndex : firstPrivateIndex;
    setSaleIndex(Math.max(defaultIndex, 0));
  }, [sales]);

  let visibleInventories = [];
  if (inventories && inventories.length > 0) {
    let uniqueTiers = [];
    visibleInventories = sales
      .map((sale, index) => {
        const isUnique = !uniqueTiers.includes(sale.object_id);
        uniqueTiers.push(sale.object_id);
        return isUnique && !sale.hidden && inventories[index];
      })
      .filter(Boolean);
  }
  const combinedTotalItems = sumFieldInArray(visibleInventories, "total");
  const combinedCurrentItems = sumFieldInArray(visibleInventories, "current");
  const sale = sales[saleIndex] || {};
  const price = mystToSui(sale?.price);

  useEffect(() => {
    getSaleData(item, inventories, saleIndex)
      .then((res) => setInventories(res))
      .catch((e) => console.log("error", e));
  }, [item, saleIndex]);

  return (
    <div className="sc-card-collection collection-card">
      <Link {...linkProps}>
        <LazyLoadImage
          height="200px"
          className="launchpad-img-featured"
          src={launchpad_image || featured_image}
          key={`featured-${_id}`}
        />
      </Link>
      <div className="card-bottom" style={{ height: "225px", margin: "1.5rem" }}>
        <div className="launchpad-card-details flex-column">
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              style={{
                fontSize: "30px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              {...linkProps}
            >
              {name ?? "Unnamed Mintpad"}
            </Link>
          </h3>
          <SocialButtons socials={socials} />
          <div className="flex justify-content-center fullWidth" style={{ gap: "1rem" }}>
            <InfoBox text="Items" textBold={`${combinedTotalItems || "~"}`} />
            <InfoBox text="Price" textBold={price ? `${price} SUI` : "FREE"} />
          </div>
          {owned ? (
            <EditButtons collection_id={collection_id} launchpad_id={_id} />
          ) : (
            <LaunchpadCardCountdown launchpad={item} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LaunchpadCard;
