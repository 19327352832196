import OwnedNFTCard from "components/layouts/profile/OwnedNFTCard";
import NFTSkeleton from "components/layouts/NFTSkeleton";
import { Link } from "react-router-dom";
import { ListGrid } from "utils/styled";

const MyKiosksList = ({
  kiosk,
  loading,
  inventory,
  loadMore,
  setSellModalShow,
  setLoanModalShow,
  setNftDetailsModalShow,
}) => {
  const sellHandler = (nft) => {
    setSellModalShow({ ...nft, ...{ kiosk: kiosk.id } });
  };

  const loanHandler = (nft) => {
    setLoanModalShow({ ...nft, ...{ kiosk: kiosk.id } });
  };

  const nftDetailsHandler = (nft) => {
    setNftDetailsModalShow(nft);
  };

  return (
    <ListGrid>
      {loading && !inventory?.data
        ? Array.from({ length: 15 }, (_, index) => {
            return <NFTSkeleton key={index} />;
          })
        : inventory?.data.map((item) => (
            <OwnedNFTCard
              key={item.id}
              item={item}
              sellHandler={sellHandler}
              loanHandler={loanHandler}
              nftDetailsHandler={nftDetailsHandler}
            />
          ))}
      {!loading && inventory?.hasNextPage && (
        <Link
          to="#"
          id="load-more"
          className="sc-button loadmore fl-button pri-3"
          onClick={loadMore}
        >
          <span>Load More</span>
        </Link>
      )}
    </ListGrid>
  );
};

export default MyKiosksList;
