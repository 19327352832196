import React from "react";
import { FieldArray } from "redux-form";
import { renderCreateNFTFields } from "utils/form";

const CreateItemMetadata = ({ collection }) => {
  return (
    <div>
      <FieldArray
        name="fields"
        title="Metadata Information"
        component={renderCreateNFTFields}
        props={{ fields: collection.fields }}
        rerenderOnEveryChange
      />
    </div>
  );
};

export default CreateItemMetadata;
