import React from "react";
import { FieldArray, Field } from "redux-form";
import { Accordion } from "react-bootstrap-accordion";
import { renderSetTags, renderFormV2, renderSetTagsV2 } from "utils/form";
import RoyaltiesButtonGroup from "components/layouts/RoyaltiesButtonGroup";
import StickyPreviewCollection from "components/layouts/StickyPreviewCollection";
import MultiSelectAccordion from "components/layouts/MultiSelectAccordion";
import { formName } from "./CreateCollectionForm";
import { useSelector } from "react-redux";

const CreateCollectionDetails = () => {
  const tags = useSelector((state) => state.settings?.tags);
  const tagsData = {
    title: "Tags",
    name: "tags",
    show: true,
    values: tags,
  };

  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <StickyPreviewCollection />
      </div>
      <div className="col-xl-8 col-lg-6 col-md-12 col-12">
        <Field
          name="name"
          title="Collection Name"
          type="text"
          placeholder="e.g. Sui Capys"
          component={renderFormV2}
          required
        />
        <Field
          name="description"
          type="textarea"
          placeholder='e.g. "This is my collection!"'
          component={renderFormV2}
          required
        />
        <Field name="royalties" label="Royalties" component={RoyaltiesButtonGroup} />
        <Field
          type="select"
          name="standard"
          defaultValue="mysten"
          component={renderFormV2}
        >
          <option value="mysten">Mysten standard (Kiosk, the recommended option)</option>
          <option value="originbyte">
            OriginByte (Kiosk, listings can easily be shared with other marketplaces)
          </option>
          <option value="open">
            open standard (lax royalty enforcement, Easiest user experience)
          </option>
        </Field>
        <Field type="text" name="whitepaper" component={renderFormV2} />
        <Field type="text" name="website" component={renderFormV2} />
        <FieldArray
          name="tags"
          title="Tags"
          component={renderSetTagsV2}
          formName={formName}
          className="pt-24"
        />
      </div>
    </div>
  );
};

export default CreateCollectionDetails;
