import React from "react";
import LazyLoadImage from "components/layouts/LazyLoadImage";
import LaunchpadCountdown from "./FeaturedLaunchpadCountdown";

const LaunchpadImageAndMint = ({
  launchpad,
  inventories,
  saleIndex,
  tickets,
  handleBuyNFT,
  buyLoading,
  renderAutoRefreshTimer,
}) => {
  const collectionID = launchpad?.launchpad_collection?._id;

  return (
    <div className="flex flex-column launchpad-details launchpad-image-mint">
      <div className="launchpad-details-img-wrapper">
        <LazyLoadImage
          src={launchpad?.launchpad_image || launchpad?.launchpad_collection?.logo_image}
          key={`featured-${collectionID}-blur`}
          className="launchpad-img-blur"
          height="675px"
        />
        <LazyLoadImage
          src={
            launchpad?.launchpad_image || launchpad?.launchpad_collection?.featured_image
          }
          key={`featured-${collectionID}`}
          className="launchpad-img-main"
          height="675px"
        />
      </div>
      {launchpad && (
        <LaunchpadCountdown
          buyLoading={buyLoading}
          handleBuyNFT={handleBuyNFT}
          launchpad={launchpad}
          tickets={tickets}
          inventories={inventories}
          saleIndex={saleIndex}
          renderAutoRefreshTimer={renderAutoRefreshTimer}
        />
      )}
    </div>
  );
};

export default LaunchpadImageAndMint;
