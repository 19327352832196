import React, { Children, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ellipsifyString, mystToSui } from "utils/formats";
import { withdrawFromKiosk, withdrawFromKiosks } from "web3/standards/mysten";
import { getKiosks } from "redux/state/sui";
import ToastPopup from "components/utils/ToastPopup";
import LoadingButton from "components/button/LoadingButton";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.h2`
  padding-bottom: 22px;
  line-height: 44px;
  color: var(--primary-color2);
  font-size: 24px;

  @media screen and (min-width: 575px) {
    font-size: 36px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RowText = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

const GridHeader = styled.div`
  display: grid;
  align-items: center;
  width: max-content;
  min-width: 100%;
  grid-template-columns: minmax(0px, 2fr) ${(props) =>
      `repeat(${Children.toArray(props.children).length - 1}, minmax(100px, 0.75fr))`};
  padding: 0 2rem;
  margin-bottom: 1rem;
`;

const HeaderText = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 10px;
  text-transform: uppercase;
  color: rgb(138, 147, 155);

  @media screen and (min-width: 575px) {
    font-size: 12px;
    font-weight: 600;
  }
`;

const GridRow = styled.div`
  padding: 0 2rem;
  display: grid;
  align-items: center;
  height: 44px;
  width: max-content;
  min-width: 100%;
  border-radius: var(--border-radius-md);
  background: var(--primary-color);
  transition: all 0.4s ease;
  grid-template-columns: minmax(0px, 2fr) ${(props) =>
      `repeat(${Children.toArray(props.children).length - 1}, minmax(100px, 0.75fr))`};
`;

const KioskRows = ({ title }) => {
  const dispatch = useDispatch();
  const kiosks = useSelector((state) => state.sui.kiosks || []);
  const perPage = 10;
  const [visible, setVisible] = useState(perPage);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const withdrawSUI = async ({ selectedKiosk }) => {
    setWithdrawLoading(true);
    try {
      const txn =
        selectedKiosk == "all" || selectedKiosk == undefined
          ? await withdrawFromKiosks(kiosks)
          : await withdrawFromKiosk(kiosks[selectedKiosk]);
      if (txn.status !== "success") {
        ToastPopup("An unknown error occured", "error");
      } else {
        ToastPopup("SUI withdrawn successfully", "success");
      }
    } catch {}
    setWithdrawLoading(false);
    dispatch(getKiosks());
  };

  const renderColumnHeaders = () => {
    return (
      <GridHeader>
        <HeaderText>Kiosk ID</HeaderText>
        <HeaderText>Amount</HeaderText>
        <HeaderText>Action</HeaderText>
      </GridHeader>
    );
  };

  const renderColumnFields = (kiosk, index) => {
    const { id, data } = kiosk;
    const amount = data.amount;

    return (
      <GridRow>
        <FlexRow>
          <RowText key={`kiosk-${id}`}>{ellipsifyString(id, 24)}</RowText>
        </FlexRow>
        <FlexRow>
          <RowText key={`kiosk-${id}-amount`}>{`${mystToSui(amount) || 0} SUI`}</RowText>
        </FlexRow>
        <FlexRow>
          <LoadingButton
            loading={withdrawLoading}
            disabled={withdrawLoading}
            onClick={() => withdrawSUI(index)}
          >
            Withdraw
          </LoadingButton>
        </FlexRow>
      </GridRow>
    );
  };

  return (
    <div className="themesflat-container">
      <FlexColumn>
        <SectionHeader>{title}</SectionHeader>
        {kiosks?.length === 0 ? (
          <div>
            <h4 style={{ color: "#8a939b" }}>No kiosks found</h4>
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            {renderColumnHeaders()}
            <RowContainer>
              {kiosks
                ?.slice(0, visible)
                .map((item, index) => renderColumnFields(item, index))}
            </RowContainer>
          </div>
        )}
        <LoadingButton
          className="mt-5"
          loading={withdrawLoading}
          disabled={withdrawLoading}
          onClick={() => withdrawSUI("all")}
        >
          Withdraw from All Kiosks
        </LoadingButton>
      </FlexColumn>
    </div>
  );
};

export default KioskRows;
