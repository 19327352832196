import React from "react";
import GameInfoCard from "components/cards/GameInfoCard";
import icon1 from "assets/images/icon/Wallet.png";
import icon2 from "assets/images/icon/Category.png";
import icon3 from "assets/images/icon/Image2.png";

const GamesInfoCards = () => {
  const gamesInfo = [
    {
      title: "Seamless Integration",
      description:
        "Keepsake Integrated allows gamers to buy, sell, rent, and list on-chain assets directly from their game without leaving the game.",
      link: "/link1",
      icon: icon1,
      colorbg: "icon-color1",
    },
    {
      title: "Multi Compatibility",
      description:
        "Keepsake Integrated is compatible with Mysten Labs NFT standard & Origin Byte NFT standards!",
      link: "/link2",
      icon: icon2,
      colorbg: "icon-color2",
    },
    {
      title: "Directly In-Game",
      description:
        "Keepsake Integrated also includes a fully seamless integration of the Shinami sponsored gasless transaction and in-game wallet. This will allow all listed digital assets to be tradeable and usable directly in-game!",
      link: "/link3",
      icon: icon3,
      colorbg: "icon-color3",
    },
  ];

  return (
    <section className="tf-section popular-collections">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {gamesInfo.map((gameInfo) => (
                <div className="fl-item col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <GameInfoCard gameInfo={gameInfo} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GamesInfoCards;
