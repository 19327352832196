import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FieldArray } from "redux-form";
import { initFormVals } from "redux/state/initialValues";
import { renderBags } from "utils/form";

const formName = "edit-bags";
const EditBags = ({
  collection,
  setBatchCreateModalShow,
  setBatchTransferModalShow,
  setBatchWithdrawModalShow,
  setNftDetailsModalShow,
  handleCreate,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentBag = searchParams.get("currentBag");
  const type = searchParams.get("type");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFormVals(formName, collection));

    if (currentBag) {
      switch (type) {
        case "create":
          setBatchCreateModalShow({
            collection,
            bag: collection.grab_bags[currentBag],
          });
          break;
        case "import":
          setBatchTransferModalShow({
            collection,
            bag: collection.grab_bags[currentBag],
            index: currentBag,
          });
          break;

        default:
          break;
      }
    }
  }, []);

  return (
    <FieldArray
      name="grab_bags"
      component={renderBags}
      props={{
        grab_bags: collection.grab_bags,
        collection,
        setBatchCreateModalShow,
        setBatchTransferModalShow,
        setBatchWithdrawModalShow,
        setNftDetailsModalShow,
        handleCreate,
      }}
      rerenderOnEveryChange
    />
  );
};

export default EditBags;
