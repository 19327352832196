import styled from "styled-components";
import MyProfileOther from "components/layouts/MyProfileOther";

const DisplayName = styled.h2`
  font-size: 38px;
  margin-right: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: 1rem;
`;

const ProfileOther = () => {
  return (
    <ContentWrapper className={"col-box-83"}>
      <DisplayName>Other</DisplayName>
      <MyProfileOther />
    </ContentWrapper>
  );
};

export default ProfileOther;
