import React from "react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import img1 from "../../assets/images/box-item/img_slide1.png";
import img2 from "../../assets/images/box-item/img_slide2.png";
import img3 from "../../assets/images/box-item/img_slide3.png";
import img4 from "../../assets/images/box-item/img_slide4.png";
import img5 from "../../assets/images/box-item/img_slide5.png";
import img6 from "../../assets/images/box-item/img_slide6.png";
import img7 from "../../assets/images/box-item/img_slide7.png";
import img8 from "../../assets/images/box-item/img_slide8.png";
import img9 from "../../assets/images/box-item/img_slide9.png";
import img10 from "../../assets/images/box-item/img_slide10.png";
import img11 from "../../assets/images/box-item/img_slide11.png";
import img12 from "../../assets/images/box-item/img_slide12.png";
import img13 from "../../assets/images/box-item/img_slide13.png";
import img14 from "../../assets/images/box-item/img_slide14.png";
import img15 from "../../assets/images/box-item/img_slide15.png";
import img16 from "../../assets/images/box-item/img_slide16.png";

import img17 from "../../assets/images/box-item/img_slide17.png";
import img18 from "../../assets/images/box-item/img_slide18.png";
import img19 from "../../assets/images/box-item/img_slide19.png";
import img20 from "../../assets/images/box-item/img_slide20.png";
import img21 from "../../assets/images/box-item/img_slide21.png";
import img22 from "../../assets/images/box-item/img_slide22.png";
import img23 from "../../assets/images/box-item/img_slide23.png";

import img24 from "../../assets/images/box-item/img_slide24.jpg";
import img25 from "../../assets/images/box-item/img_slide25.jpg";
import img26 from "../../assets/images/box-item/img_slide26.jpg";
import img27 from "../../assets/images/box-item/img_slide27.jpg";
import img28 from "../../assets/images/box-item/img_slide28.jpg";

const TripleVerticalSlider = () => {
  return (
    <div className="home-7">
      <section className="flat-title-page style3 mainslider">
        <div className="overlay"></div>
        <div className="themesflat-container ">
          <div className="wrap-heading flat-slider flex">
            <div className="content">
              <h2 className="heading mt-15">A Reimagined</h2>
              <h1 className="heading mb-style">
                <span className="tf-text s1">NFT Gaming</span>
              </h1>
              <h1 className="heading">Marketplace</h1>
              <p className="sub-heading mt-19 mb-40">
                The Marketplace For Utility Based Collections And All Gaming Digital
                Collectibles
              </p>
              <div className="flat-bt-slider flex style2">
                <Link
                  to="/explore"
                  className="sc-button primary header-slider style style-1 fl-button"
                >
                  <span>Explore</span>
                </Link>
                <Link to="/mintpad" className="sc-button secondary header-slider style">
                  <span>Mintpads</span>
                </Link>
              </div>
            </div>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={4200}
            >
              <SwiperSlide>
                <img src={img21} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img27} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img4} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img17} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img7} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img10} />
              </SwiperSlide>
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={3600}
            >
              <SwiperSlide>
                <SwiperSlide>
                  <img src={img22} />
                </SwiperSlide>
                <img src={img24} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img16} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img5} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img18} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img14} />
              </SwiperSlide>
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={4800}
            >
              <SwiperSlide>
                <img src={img6} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img25} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img20} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img26} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img28} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img23} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TripleVerticalSlider;
