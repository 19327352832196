import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";

const TimeButton = ({ start_date, end_date, children, className }) => {
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    setFinished(end_date < new Date().getTime());
  }, [end_date]);

  const finishRenderer = ({ completed }) => {
    if (completed) {
      return <button className={className}>Finished</button>;
    } else {
      return children;
    }
  };

  const startRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (!completed && formatted.seconds !== "NaN") {
      return <div>{`${days}:${hours}:${minutes}:${seconds}`}</div>;
    }
  };

  return (
    <>
      <Countdown className="start" date={new Date(start_date)} renderer={startRenderer}>
        {!finished ? children : undefined}
      </Countdown>
      <Countdown
        className="finish"
        date={new Date(end_date)}
        renderer={finishRenderer}
        onComplete={() => setFinished(true)}
      >
        {finished ? children : undefined}
      </Countdown>
    </>
  );
};

export default TimeButton;
