import { getSettings } from "utils/api";
import { updateSettings, getObjectInfo } from "web3/sui";

export const SETTINGS = "SETTINGS";

export const fetchSettings = () => async (dispatch) => {
  let newSettings = await getSettings().then((res) => res.data);
  try {
    let marketInfo = await getObjectInfo(newSettings.market.marketplace);
    newSettings.reset = !marketInfo;
    updateSettings(newSettings, marketInfo);
  } catch (e) {
    updateSettings(newSettings);
  }

  dispatch({
    type: SETTINGS,
    data: newSettings,
  });
};

const INIT_STATE = {
  nft_contract: { dependencies: [], modules: [] },
  package_id: "",
  module_name: "",
  market: {},
  lending: {},
  admin_address: "",
  tags: [],
  ready: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTINGS:
      state.ready = true;
      return { ...state, ...action.data };
    default:
      return state;
  }
};
export default reducer;
